import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { SelectItemModel } from '../../../common/models/select-item.model'
import { CareerModel } from '../../../common/models/careerModel'
import { ContactService } from '../../../services/contact.service'
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service'
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import * as FormData from 'form-data';

/**
 * This comopnent contains form for submittin request for career. Form contains fields; name, email, contact, work-experience, current-organization, department, current/ expected CTC,resume etc.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  careerForm : UntypedFormGroup  
  deptOptions : Array<SelectItemModel> = new Array<SelectItemModel>()
  selectedFile : File
  formData = new FormData();  
  
  /**
   * @constructor
   * @param formBuilder 
   * @param contactService 
   * @param dialogService 
   */
  constructor(
    private formBuilder : UntypedFormBuilder,  
    private contactService: ContactService,
    public dialogService: LsDialogService<OkayMessageComponent>,      
  ) { }

  /**
   * Prepares form-group for career-form.
   */
  ngOnInit() {
    let selectItem: SelectItemModel = new SelectItemModel()
    selectItem.SelectItem(-1, '--Job Categories--')
    this.deptOptions.push(selectItem)
    this.careerForm = this.formBuilder.group({
      name : ["", [Validators.required]],
      email : ["", [Validators.email, Validators.required]],
      phoneNumber : ["", [Validators.required]],
      totalWorkExp : ["", [Validators.required]],
      currOrg : ["", [Validators.required]],
      currDest : ["", [Validators.required]],
      department: [-1, [Validators.required]],
      currCtc : ["", [Validators.required]],
      expCtc : ["", [Validators.required]],
      file : [""]
    })
    this.populateDepartmentCombo()
  }

  /**
   * Fetches departments to populate in departments combo.
   */
  populateDepartmentCombo(){
    this.contactService.getCareerDepartments(data=>{
      if(data != null && !data.error && data.data != null){
        let depts = data.data
        depts.forEach(element => {
          let selectItem: SelectItemModel = new SelectItemModel()
          selectItem.SelectItem(element, element)
          this.deptOptions.push(selectItem)
        });
      }
    })
  }

  /**
   * This method gets triggered when a file is selected.
   * File is appneded in formData (for multi-part request).
   * @param event - file selection event
   */
  onFileChange(event){
    this.selectedFile = event.target.files[0]
    if(event.target.files.length > 0){
      let fileToUpload = event.target.files[0]
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
    }
  }

  /**
   * allows to enter numbers only.
   * @param event - keypress event
   */
  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * allows to enter characters only.
   * @param event - keypress event
   */
  keyPressOnlyChars(event: any) {
    const pattern = /[a-z\A-Z\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * Prepares CareerModel by assigning form-values and appends selected file as well.
   * Check file-type and size. If not appropriate, then display error else send request.
   * On request's successful response, display success message and reset the form.
   * @param errorLabel - element reference for displaying error-label.
   */
  submitCareer(errorLabel){
    let careerModel : CareerModel = new CareerModel()
    careerModel.fullName = this.careerForm.value.name
    careerModel.emailId = this.careerForm.value.email
    careerModel.contactNo = this.careerForm.value.phoneNumber
    careerModel.workExperience = this.careerForm.value.totalWorkExp
    careerModel.organisation = this.careerForm.value.currOrg
    careerModel.designation = this.careerForm.value.currDest
    careerModel.department = this.careerForm.value.department
    careerModel.currentCTC = this.careerForm.value.currCtc
    careerModel.expectedCTC = this.careerForm.value.expCtc
    this.formData.append("file",this.selectedFile)
    this.formData.append("data", JSON.stringify(careerModel))    
    if(this.selectedFile != undefined){
      errorLabel.className = "errorLabel hide";
      if(this.selectedFile.size > 5242880){
        this.dialogService.open(OkayMessageComponent,{},"File size should be less than 5 MB!!").subscribe({
        })
      }else if(!this.isDocType(this.selectedFile.name)){
        this.dialogService.open(OkayMessageComponent,{},"Please select File of required Type only!!").subscribe({
        })
      }else{
        this.contactService.addCareerDetail(this.formData, (data)=>{
          if(data != null && !data.error && data.msgList != null){
            this.careerForm.reset()
            this.dialogService.open(OkayMessageComponent,{},data.msgList[0]).subscribe({
            })
          }
        })
      }
    }else{
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please Upload Resume !!";
    }
  }

  /**
   * Checks file-types for resume file-upload.
   * @param fileName 
   */
  isDocType(fileName):boolean{
    let extn = fileName.split('.')[1]
    if(extn == 'doc' || extn == 'html' || extn == 'xml'|| extn == 'odt' || extn == 'pdf' || extn == 'docx')
      return true
    else
      return false
  }

}