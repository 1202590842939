import { Component, OnInit, AfterViewInit, ViewChildren, ElementRef, QueryList, AfterContentChecked, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { Constants } from '../../../common/constants/lsnetx.constants';
import { ProductDetailsService } from '../../../services/product-details.service';
import { GetReviewListService } from '../../../services/get-review-list.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { HomePageService } from '../../../services/home-page.service';
import { SeoService } from '../../../services/seo.service';
import { LoginService } from '../../../services/login.service';
import { LoginRequestModel } from '../../../common/models/loginRequestModel';
import { ProductModel } from '../../../common/models/productModel';
import { UserModel } from '../../../common/models/user-model';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { GeneralSettingsModel } from '../../../common/models/generalSettingsModel';
import { ReviewAvgModel } from '../../../common/models/reviewAvgModel';
import { SocialSettingsModel } from '../../../common/models/social-settings-model';
import { ProductIdentifier } from '../../../common/models/productIdentifier';
import { ProductReviewComponent } from '../../components/product-review/product-review.component';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { ProductImgDialogComponent } from './product-img-dialog/product-img-dialog.component';
import { CartComponent } from '../../components/cart/cart.component';
import { CartCountService } from '../../../services/cart-count.service';
import { CategoryService } from '../../../services/category.service';
import { CategoryModel } from '../../../common/models/category-model';
import { OfferDTO } from '../../../common/models/offerDto';
import { ActionDTO } from '../../../common/models/actionDto';
import { ConditionDTO } from '../../../common/models/conditionDto';
import { OfferItemDTO } from '../../../common/models/offerItemDto';
import { UserService } from '../../../services/user.service';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { TemplateConstants } from '../../commonComponent/template-constants';

export class dims {
  height: number
  width: number
}
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/services/http.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  tabHeaders: Array<{ tabTitle: string, showTab: boolean }> = [
    { tabTitle: 'Features', showTab: true },
    { tabTitle: 'Specifications', showTab: true },
    { tabTitle: 'Description', showTab: true },
    { tabTitle: 'Download/Demo', showTab: true }
  ]
  selectedTab: number = 0
  defaultPath = TemplateConstants.defaultImgPath;
  currVariantId
  currType: string = "INR"
  imageUrl: string
  imagesList
  imageThumbnailsList = new Array<string>()
  image
  imageIndex: number = 0
  productName: string
  categoryName: string
  mrp
  salePrice
  leftUpdatedValue: number = 0
  leftcheck: number = 0
  giveRating = 0
  getRating = 0
  productQuantity: number = 1
  discount
  shippingDays: number = Constants.DEFAULT_SHIPPING_DAYS
  featureArray: Array<{ name: string, specialData: string, details: Array<{ key: string, value: string }> }> = []
  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  selectedOptionIdx = 0
  selectedVariantTypeIdx = 0
  selectedVariantId
  currentPageUrl = ''
  socialShareFbUrl = ''
  socialShareGoogleUrl = ''
  socialShareLinkedInUrl = ''
  socialShareTwitterUrl = ''
  pageNum = 1
  numRecords = 28
  ratingPnls = []
  totalReviews = []
  defaultVariant
  selectionArray = []
  defaultSelectedArray = []
  returnLinkPath
  youtubeEmbedLinkUrl
  descContent: string
  attachments: Array<{ url: string, isYoutube: boolean, docName: string }> = []

  playVideo: boolean = false
  showReturnFlag: boolean = false
  isProNotAvailable: boolean = false
  buyMode: boolean = false
  enquiryMode: boolean = false
  buyAndEnquiryMode: boolean = false
  filterSelected: boolean = false
  isoutOfStock
  showGst
  gst
  showSaving
  isFbHash: boolean = false
  isGoogleHash: boolean = false
  isLinkedInHash: boolean = false
  isTwitterHash: boolean = false
  youtubeEmbedLink
  allowWishlist
  allowCart;
  showError: boolean = false;
  disableLeft: boolean = true;
  disableRight: boolean = false;
  showZoomImg: boolean = false;
  disableZoomLeft: boolean = true;
  disableZoomRight: boolean = false;
  zoomFlag = 0;
  superZoomFlag = 0;
  showSuperZoom: boolean = false;
  priceRoundOffInteger: string = '1.2';
  discountRoundOffInteger: string = '1.2';

  productDetails: ProductModel;
  offerProducts: Array<ProductModel> = [];
  comboOfferDetails: Array<OfferDTO> = [];

  firstProduct;
  ratingAndReviewData: ReviewAvgModel;
  generalSettings: GeneralSettingsModel;
  socialSettings: SocialSettingsModel;
  quickLinks: Array<StaticPageModel>;
  titleIdList: Array<number> = new Array<number>();
  variantMap = new Map<number, {
    index: number,
    mapped: Array<number>
  }>();
  currUser: UserModel;
  addReviewLbl = "Add Your Review";
  priceTag = "Price";
  quantityTag = "Quantity";
  statusTag = "Status";
  availableTag = "Available";
  notAvailableTag = "Not Available";
  outOfStockTag = "Out of Stock";
  shippingChargesTag = "Shipping Charges";
  codChargesTag = "COD Charges";
  gstChargesTag = "GST Charges";
  handlingChargesTag = "Handling Charges";
  aboutProduct = "About Product";
  buyNowLbl = "Buy Now";
  addToCartLbl = "Add to Cart";
  freeShippingLbl = "Free Shipping";
  callForPriceTag = "Call For Price";
  lblCustomerReviews = "Reviews";
  rateLbl = "Rating";
  rateNowLabel = "Share your thoughts with other customers";
  rateNowBtnLabel = "Write a product review";
  ratingSummaryLbl = "Rating Summary";
  lblFunfacts = "Fun Facts About";
  lblBamboo = "BAMBOO";
  lblFactsRead = "Click to Read";

  cartProductMdel = new CartProductModel();
  currUserEmail;
  titleId;
  variantId;
  errorPageUrl = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 : TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg'

  // cartCount: number
  // wishlistCount: number
  currCart: CartModel;
  wishlistCart: CartModel;
  isMobile: boolean = false;
  isDesktop: boolean = true;
  isTab: boolean = false;
  Math: Math = Math;
  proAvgRating: number;
  ratingBlocks: Array<{ lbl: string, value: number }> = [];
  custRating: Array<{ lbl: string, value: number }> = [];
  blocks: Array<number> = [1, 2, 3, 4, 5];
  ratingCountList: Array<number> = [];
  ratingAvgList: Array<number> = [];
  isBuy: boolean = false;
  _catSubscription;
  _catSubscription2;
  _routeSubscription;
  shareIconPath ="/assets/templates/template_0014/images/Share-icon.svg"

  @ViewChildren("barRatingPnlsValue") barRatingPnlsValue: QueryList<ElementRef>
  @ViewChildren("barRatingPnlsBar") barRatingPnlsBar: QueryList<ElementRef>
  @ViewChildren("productMainImage") productMainImage
  @ViewChildren("VariantList") VariantList: QueryList<ElementRef>
  @ViewChildren("avgFeatureRating") avgFeatureRating: QueryList<ElementRef>
  @ViewChildren("avgCustRating") avgCustRating: QueryList<ElementRef>
  @ViewChildren("userRating") userRating: QueryList<ElementRef>

  constructor(
    public httpService: HttpService,
    public seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public productDetailsService: ProductDetailsService,
    public homePageService: HomePageService,
    public getReviewListService: GetReviewListService,
    // public addToCartWishlistService: AddToCartWishlistService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public addReviewDialogService: LsDialogService<ProductReviewComponent>,
    public loginDialogService: LsDialogService<LoginComponent>,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public cartDialog: LsDialogService<CartComponent>,
    public productImgDialog: LsDialogService<ProductImgDialogComponent>,
    public location: Location,
    private cartCountService: CartCountService,
    private loginService: LoginService,
    private categoryService: CategoryService,
    private userService: UserService,
    private cartService: CartService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentPageUrl = window.location.href;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
        this.isMobile = true
        this.isTab = false
        this.isDesktop = false
      } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
        this.isMobile = false
        this.isTab = true
        this.isDesktop = false
      } else {
        this.isMobile = false
        this.isTab = false
        this.isDesktop = true
      }
      if (Constants.COLOR_MAP.length <= 0) {
        this.getColors()
      }
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
    }
    if (Constants.CART_SETTINGS != undefined) {
      if (Constants.CART_SETTINGS.allowCart != undefined) {
        if (Constants.CART_SETTINGS.allowCart == 1)
          this.allowCart = true;
        else if (Constants.CART_SETTINGS.allowCart == 0)
          this.allowCart = false;
      }
      if (Constants.CART_SETTINGS.allowWishList != undefined) {
        if (Constants.CART_SETTINGS.allowWishList == 1)
          this.allowWishlist = true;
        else if (Constants.CART_SETTINGS.allowWishList == 0)
          this.allowWishlist = false;
      }
    }
    if (this.activatedRoute.snapshot.queryParamMap && this.activatedRoute.snapshot.queryParamMap.get("t")) {
      this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
    }
    this.getGeneralSettings();
    this.getSocialSettings();
    var routerNav = false;
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        var newUrl = e.url;
        var newTitleId;
        if (this.activatedRoute.snapshot.queryParamMap && this.activatedRoute.snapshot.queryParamMap.get("t")) {
          newTitleId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString());
        }
        if (newUrl.includes('details/') && this.titleIdList[0] != newTitleId) { //if titleId is not changed, then no new product
          routerNav = true;
          this.currentPageUrl = newUrl;
          this.initializeNewProd();
        }
      }
    });
    if (!routerNav) {
      this.getPorductDetailsUtility();
    }
    if (isPlatformBrowser(this.platformId) && window.localStorage.getItem('currentUser') != null) {
      this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
      this.currUserEmail = this.currUser.email;
    }
  }


  initializeNewProd() {
    this.titleIdList = [];
    this.currVariantId;
    this.imageUrl;
    this.imagesList;
    this.imageThumbnailsList = new Array<string>();
    this.image;
    this.productName;
    this.mrp;
    this.gst;
    this.salePrice;
    this.leftUpdatedValue = 0;
    this.leftcheck = 0;
    this.giveRating = 0;
    this.getRating = 0;
    this.productQuantity = 1;
    this.discount;
    // this.discountPercent;
    this.featureArray = [];
    this.variantTypes = [];
    this.selectedOptionIdx = 0;
    this.selectedVariantTypeIdx = 0;
    this.selectedVariantId;
    this.socialShareFbUrl = '';
    this.socialShareGoogleUrl = '';
    this.socialShareLinkedInUrl = '';
    this.socialShareTwitterUrl = '';
    this.ratingPnls = [];
    this.totalReviews = [];
    this.defaultVariant;
    this.selectionArray = [];
    this.defaultSelectedArray = [];
    this.returnLinkPath;
    this.youtubeEmbedLinkUrl;
    // this._readAll = false;
    // this._showReadAll = false;
    this.showReturnFlag = false;
    this.isProNotAvailable = false;
    this.buyMode = false;
    this.enquiryMode = false;
    this.buyAndEnquiryMode = false;
    this.filterSelected = false;
    this.isoutOfStock;
    this.getPorductDetailsUtility();
  }

  getPorductDetailsUtility() {
    this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
    if (this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum")) {
      this.currVariantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum");
    }
    this.getProductDetails();
    this.setSocialSharePageUrls();
    this.getReviewsList();
  }

  ngAfterViewInit() {
    this.barRatingPnlsValue.changes.subscribe(() => {
      this.barRatingPnlsValue.toArray().forEach((element, index) => {
        element.nativeElement.innerHTML = (Math.ceil(this.ratingPnls[index] * 100)) + '%'
      })
    })
    this.barRatingPnlsBar.changes.subscribe(() => {
      this.barRatingPnlsBar.toArray().forEach((element, index) => {
        element.nativeElement.style.height = (this.ratingPnls[index] * 85) + 'px'
      })
    })
    this.VariantList.changes.subscribe(() => {
      this.VariantList.toArray().forEach((element, index) => {
        element.nativeElement.children[0].style.backgroundColor = '#' + this.getColorCode(element.nativeElement.innerText.trim().toUpperCase())
      })
    })
  }

  ngOnDestroy() {
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
    if (this._catSubscription2) {
      this._catSubscription2.unsubscribe();
    }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  getColors() {
    this.homePageService.getColorCodes(resp => {
      if (resp != null && !resp.error) {
        if (resp.data != undefined && resp.data != null) {
          let colorMap = resp.data
          Object.keys(colorMap).forEach(ele => {
            Constants.COLOR_MAP.push({
              colorName: ele,
              colorCode: colorMap[ele]
            })
          })
        }
      }
    })
  }

  getColorCode(colorName): string {
    let colorCode = ''
    if (Constants.COLOR_MAP.length > 0) {
      for (var index = 0; index < Constants.COLOR_MAP.length; index++) {
        var element = Constants.COLOR_MAP[index];
        if (element.colorName == colorName) {
          return element.colorCode
        }
      }
    }
  }

  getGeneralSettings() {
    this.generalSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO
    if (this.generalSettings) {
      if (this.generalSettings.priceRoundOffInteger == 1) {
        this.priceRoundOffInteger = '1.0'
      } else if (this.generalSettings.priceRoundOffInteger == 0) {
        this.priceRoundOffInteger = '1.2'
      }
      if (this.generalSettings.discountRoundOffInteger == 1) {
        this.discountRoundOffInteger = '1.0'
      } else if (this.generalSettings.discountRoundOffInteger == 0) {
        this.discountRoundOffInteger = '1.2'
      }
      // if (this.generalSettings.showSaving == 1) {
      //   this.showSaving = true;
      // }
      // else {
      //   this.showSaving = false;
      // }
      if (this.generalSettings.mrpInclusiveOfTax == 1) {
        this.showGst = false;
      } else if (this.generalSettings.mrpInclusiveOfTax == 0) {
        this.showGst = true;
      }
    } else {
      this.productDetailsService.getGeneralSettings((data) => {
        if (data) {
          this.generalSettings = data.data;
          if (this.generalSettings.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0'
          } else if (this.generalSettings.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2'
          }
          if (this.generalSettings.discountRoundOffInteger == 1) {
            this.discountRoundOffInteger = '1.0'
          } else if (this.generalSettings.discountRoundOffInteger == 0) {
            this.discountRoundOffInteger = '1.2'
          }
          // if (this.generalSettings.showSaving == 1) {
          //   this.showSaving = true;
          // }
          // else {
          //   this.showSaving = false;
          // }
          if (this.generalSettings.mrpInclusiveOfTax == 1) {
            this.showGst = false;
          } else if (this.generalSettings.mrpInclusiveOfTax == 0) {
            this.showGst = true;
          }
        }
      });
    }
  }

  getSocialSettings() {
    this.socialSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO
    if (this.socialSettings) {
      let hash = this.socialSettings.allowSharingOnDtlPage;
      if (hash && hash.length > 0) {
        for (let i = 0; i < hash.length; i++) {
          if (hash.split('#')[i] != "#") {
            switch (hash.split('#')[i]) {
              case '1': this.isFbHash = true; break;
              case '2': this.isGoogleHash = true; break;
              case '3': this.isLinkedInHash = true; break;
              case '4': this.isTwitterHash = true; break;
            }
          }
        }
      }
    } else {
      this.homePageService.getStoreSettings("social", (data) => {
        if (data) {
          this.socialSettings = data.data;
          let hash = this.socialSettings.allowSharingOnDtlPage;
          if (hash && hash.length > 0) {
            for (let i = 0; i < hash.length; i++) {
              if (hash.split('#')[i] != "#") {
                switch (hash.split('#')[i]) {
                  case '1': this.isFbHash = true; break;
                  case '2': this.isGoogleHash = true; break;
                  case '3': this.isLinkedInHash = true; break;
                  case '4': this.isTwitterHash = true; break;
                }
              }
            }
          }
        }
      })
    }
  }

  getReviewsList() {
    if (this.titleIdList && this.titleIdList.length > 0) {
      this.getReviewListService.getReviewList(this.titleIdList[0], this.pageNum, this.numRecords, (data) => {
        if (data != undefined) {
          this.ratingAndReviewData = data.data;
          if (this.ratingAndReviewData) {
            this.ratingCountList = this.ratingAndReviewData.ratingCountList.reverse()
            this.ratingAvgList = this.ratingAndReviewData.ratingAvgList.reverse()
            this.proAvgRating = this.Math.round(this.ratingAndReviewData.productRatingAvg)
            this.ratingPnls = this.ratingAndReviewData.ratingAvgList;
            this.totalReviews = this.ratingAndReviewData.reviewModels;
            this.ratingBlocks = [
              { lbl: 'Design', value: this.fround(this.ratingAndReviewData.rating1Avg) },
              { lbl: 'Innovation', value: this.fround(this.ratingAndReviewData.rating2Avg) },
              { lbl: 'Ease of Use', value: this.fround(this.ratingAndReviewData.rating3Avg) },
              { lbl: 'Features', value: this.fround(this.ratingAndReviewData.rating4Avg) },
              { lbl: 'Performance', value: this.fround(this.ratingAndReviewData.rating5Avg) },
              { lbl: 'Quality', value: this.fround(this.ratingAndReviewData.rating6Avg) },
            ]
            this.avgCustRating.changes.subscribe(() => {
              this.avgCustRating.toArray().forEach((element, index) => {
                let ratingVal = this.ratingAvgList[index]
                if (ratingVal > 0) {
                  let loopCount = this.fround(ratingVal / 0.25)
                  let i = 0
                  if (loopCount - this.Math.floor(loopCount) == 0.5) {
                    while (loopCount > 0) {
                      element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                      element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                      loopCount = loopCount - 1.5
                      i++;
                    }
                  } else {
                    while (loopCount > 0) {
                      element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                      element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                      loopCount = loopCount - 1
                      i++;
                    }
                  }
                }
              })
            })

            this.avgFeatureRating.changes.subscribe(() => {
              this.avgFeatureRating.toArray().forEach((element, index) => {
                let loopCount = this.ratingBlocks[index].value
                if (loopCount - this.Math.floor(loopCount) == 0.5) {
                  let i = 0
                  while (loopCount > 0) {
                    element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                    element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                    loopCount = loopCount - 1.5
                    i++;
                  }
                } else {
                  let i = 0
                  while (loopCount > 0) {
                    element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                    element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                    loopCount = loopCount - 1
                    i++;
                  }
                }
              })
            })

            this.userRating.changes.subscribe(() => {
              this.userRating.toArray().forEach((element, index) => {
                let review = this.ratingAndReviewData.reviewModels[index]
                this.custRating = [
                  { lbl: 'Design', value: this.fround(review.rating1) },
                  { lbl: 'Innovation', value: this.fround(review.rating2) },
                  { lbl: 'Ease of Use', value: this.fround(review.rating3) },
                  { lbl: 'Features', value: this.fround(review.rating4) },
                  { lbl: 'Performance', value: this.fround(review.rating5) },
                  { lbl: 'Quality', value: this.fround(review.rating6) },
                ]
                for (var j = 0; j < this.ratingBlocks.length; j++) {
                  let loopCount = this.custRating[j].value
                  let i = 5 * j
                  if (loopCount - this.Math.floor(loopCount) == 0.5) {
                    while (loopCount > 0) {
                      element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                      element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                      loopCount = loopCount - 1.5
                      i++;
                    }
                  } else {
                    while (loopCount > 0) {
                      element.nativeElement.getElementsByTagName('span')[i].style.backgroundColor = '#e64c41'
                      element.nativeElement.getElementsByTagName('span')[i].style.width = (loopCount) * 100 + '%'
                      loopCount = loopCount - 1
                      i++;
                    }
                  }
                }
              })
            })
          }
        }
      })
    }
  }

  fround(num: number): number {
    let result: number = 0
    let roundedNum = this.Math.round(num * 10) / 10
    let digitAfterDec = roundedNum - this.Math.floor(roundedNum)
    if (digitAfterDec < 0.5) {
      return this.Math.floor(roundedNum)
    } else if (digitAfterDec > 0.5) {
      return this.Math.ceil(roundedNum)
    } else if (digitAfterDec == 0.5) {
      return roundedNum
    }
  }

  setSocialSharePageUrls() {
    this.socialShareFbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareGoogleUrl = "https://plus.google.com/share?url=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareLinkedInUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(this.currentPageUrl) + "&source=" + encodeURIComponent(this.currentPageUrl);
    this.socialShareTwitterUrl = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.currentPageUrl) + "&via=TWITTER-HANDLE";
  }

  getCategoryName(categoryId: string) {
    // this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.CHILD_ONLY, resp => {
    //   if (resp.data != undefined) {
    //     let catList: Array<CategoryModel> = resp.data
    //     catList.forEach(element => {
    //       if (element.categoryId == categoryId)
    //         this.categoryName = element.categoryName
    //     });
    //   }
    // })
    // this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.PARENT_ONLY, resp => {
    //   if (resp.data != undefined) {
    //     let parentCatList: Array<CategoryModel> = resp.data
    //     this.updateParentCategoryIndex(categoryId, parentCatList)
    //   }
    // })
    this._catSubscription = this.homePageService.childCategoriesObservable.subscribe((data) => {
      if (data != undefined) {
        let catList: Array<CategoryModel> = data;
        if (catList.length > 0) {
          catList.forEach(element => {
            if (element.categoryId == categoryId)
              this.categoryName = element.categoryName;
          });
        }
      }
    });
    this._catSubscription2 = this.homePageService.parentCategoriesObservable.subscribe((data) => {
      if (data != undefined) {
        let parentCatList: Array<CategoryModel> = data;
        this.updateParentCategoryIndex(categoryId, parentCatList);
      }
    });
  }

  updateParentCategoryIndex(catId: string, catMap: Array<CategoryModel>) {
    let id = catId[0]
    let updateIndex
    for (var index = 0; index < catMap.length; index++) {
      var element = catMap[index];
      if (element.categoryId == id) {
        updateIndex = index
        if (updateIndex >= 0) {
          this.userService.updateCarousel(updateIndex)
        }
      }
    }
  }

  updateUrlText() {
    let url = this.router.url
    let updatedUrl = ''
    let prodUrlText = url.split("details/")[1].split("?")[0]
    if (this.productDetails.productURLText && prodUrlText != this.productDetails.productURLText) {
      url.replace(prodUrlText, this.productDetails.productURLText)
    }
  }

  getProductDetails() {
    let offerBookCall = true;
    this.productDetailsService.getProductDetails(true, this.titleIdList, 2, offerBookCall, (data) => {
      if (!data.error) {
        if (data != undefined && data != null) {
          if (data.otherInfo != undefined) {
            if (data.otherInfo.offerProducts != undefined && data.otherInfo.offerProducts != null) {
              this.offerProducts = data.otherInfo.offerProducts
            }
          }
          if (data.data != undefined && data.data.length != 0) {
            this.productDetails = data.data[0];
            this.updateUrlText()
            this.setSpecTabs(null)
            this.getCategoryName(this.productDetails.categoryId)
            if (this.productDetails.youtubeEmbedLink == undefined || this.productDetails.youtubeEmbedLink == "") {
              this.youtubeEmbedLink = false;
            } else {
              this.youtubeEmbedLink = true;
              this.youtubeEmbedLinkUrl = this.productDetails.youtubeEmbedLink;
            }
            if (this.productDetails.offer == true && this.productDetails.offerDetails != null) {
              this.comboOfferDetails = this.productDetails.offerDetails
              this.comboOfferDetails.forEach(ele => {
                let offerVisibilityOnOfferConditionType: boolean = true;
                let todayDate = new Date();
                if (todayDate >= ele.releaseDate) {
                  ele.releaseDateValid = true;
                } else {
                  ele.releaseDateValid = false;
                }
                if (ele.validityDate >= todayDate) {
                  ele.validityDateValid = true;
                } else {
                  ele.validityDateValid = false;
                }

                let offerActions: Array<ActionDTO> = [];
                let offerConditions: Array<ConditionDTO> = [];
                offerActions = ele.actions;
                offerConditions = ele.conditions;
                offerActions.forEach(offerActionEle => {
                  if (offerActionEle.actionType == Constants.ACTION_TYPES.FREE_PRODUCTS) {
                    offerVisibilityOnOfferConditionType = true
                  } else {
                    offerVisibilityOnOfferConditionType = false
                  }
                  let offerProds: Array<OfferItemDTO> = [];
                  offerProds = offerActionEle.actionItems;
                  let isSet: boolean;
                  for (var i = 0; i < offerProds.length; i++) {
                    if (offerProds[i]) {
                      var prod = offerProds[i];
                      let tid = prod.id;
                      if (this.offerProducts != null && this.offerProducts != undefined) {
                        for (var j = 0; j < this.offerProducts.length; j++) {
                          var element = this.offerProducts[j];
                          if (element.titleId == tid + '') {
                            isSet = true;
                            offerActionEle.offerProductsDetails = [];
                            offerActionEle.offerProductsDetails[i] = JSON.parse(JSON.stringify(element));
                            offerActionEle.offerProductsDetails[i].offerDetails = undefined;
                            offerActionEle.offerProductsDetails[i].qty = prod.qty;
                            break;
                          }
                        }
                      }
                      if (!isSet) {
                        if (this.productDetails.titleId == tid + '') {
                          offerActionEle.offerProductsDetails = [];
                          offerActionEle.offerProductsDetails[i] = JSON.parse(JSON.stringify(this.productDetails));
                          offerActionEle.offerProductsDetails[i].offerDetails = undefined;
                          offerActionEle.offerProductsDetails[i].qty = prod.qty;
                        }
                      }
                    }
                  }
                })
                offerConditions.forEach(offerConditionEle => {
                  if (offerConditionEle.conditionType == Constants.CONDITION_TYPES.PRODUCT) {
                    offerVisibilityOnOfferConditionType = true;
                  } else {
                    offerVisibilityOnOfferConditionType = false;
                  }
                  let offerProds: Array<OfferItemDTO> = [];
                  offerProds = offerConditionEle.conditionItems;
                  let isSet: boolean;
                  for (var i = 0; i < offerProds.length; i++) {
                    if (offerProds[i]) {
                      var prod = offerProds[i];
                      let tid = prod.id;
                      if (this.offerProducts != null && this.offerProducts != undefined) {
                        for (var j = 0; j < this.offerProducts.length; j++) {
                          var element = this.offerProducts[j];
                          if (element.titleId == tid + '') {
                            isSet = true;
                            offerConditionEle.offerProductsDetails = [];
                            offerConditionEle.offerProductsDetails[i] = JSON.parse(JSON.stringify(element));
                            offerConditionEle.offerProductsDetails[i].offerDetails = undefined;
                            offerConditionEle.offerProductsDetails[i].qty = prod.qty;
                            break;
                          }
                        }
                      }
                      if (!isSet) {
                        if (this.productDetails.titleId == tid + '') {
                          offerConditionEle.offerProductsDetails = [];
                          offerConditionEle.offerProductsDetails[i] = JSON.parse(JSON.stringify(this.productDetails));
                          offerConditionEle.offerProductsDetails[i].offerDetails = undefined;
                          offerConditionEle.offerProductsDetails[i].qty = prod.qty;
                        }
                      }
                    }
                  }
                })
                if (offerVisibilityOnOfferConditionType) {
                  ele.offerVisibilityOnOfferConditionType = true
                } else {
                  ele.offerVisibilityOnOfferConditionType = false
                }
              })
            }
            if (this.productDetails.variantList == null || (this.productDetails.variantList && this.productDetails.variantList.length == 0)) {
              this.productName = this.productDetails.productName
              this.imageUrl = this.productDetails.imageUrl
              this.imagesList = this.productDetails.images;
              this.image = undefined;
              let i = 0;
              if (this.imagesList != undefined) {
                this.imagesList.forEach(imgSrc => {
                  if (imgSrc != undefined) {
                    this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
                  }
                });
                this.imageIndex = 0
                this.image = this.imageUrl + 'l/' + this.imagesList[0];
              }
              this.isoutOfStock = this.productDetails.outOfStock;
              this.giveRating = this.productDetails.productRatingAvg;
              this.mrp = this.productDetails.mrp;
              this.salePrice = this.productDetails.salePrice;
              this.discount = this.productDetails.discount;
              // this.showSaving = this.productDetails.showSaving;
              if (this.productDetails.shippingDays > 0) {
                this.shippingDays = this.productDetails.shippingDays + Constants.PRODUCT_SHIPPING_DAYS_ADDITION;
              }
              if (this.productDetails.buyEnquiryMode == Constants.BUY_MODE) {
                this.buyMode = true;
              } else if (this.productDetails.buyEnquiryMode == Constants.ENQUIRY_MODE) {
                this.enquiryMode = true;
              } else if (this.productDetails.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
                this.buyAndEnquiryMode = true;
              }
              if (this.productDetails.attachments != null && this.productDetails.attachments != undefined && this.productDetails.attachments.length != 0) {
                let attachments = this.productDetails.attachments
                attachments.forEach(ele => {
                  if (ele != '') {
                    if (ele.includes('youtube')) {
                      this.attachments.push({
                        url: ele,
                        isYoutube: true,
                        docName: ''
                      })
                    } else {
                      let i = ele.split("/").length - 1
                      this.attachments.push({
                        url: ele,
                        isYoutube: false,
                        docName: ele.split('/')[i]
                      })
                    }
                  }
                })
              }

              if (this.productDetails.descPage != undefined && this.productDetails.descPage != null && this.productDetails.descPage != '') {
                let cdnPath = this.productDetails.descPage
                if (cdnPath != undefined && cdnPath != "") {
                  this.httpService.getCDNContent(cdnPath, (data) => {
                    if (data != undefined && data != "")
                      this.descContent = data;
                  });
                }
              }

              let tempArray: Object = this.productDetails.attributes;
              Object.keys(tempArray).forEach(key => {
                if (typeof (tempArray[key]) == 'object') {
                  let value = tempArray[key]
                  let featArray: Array<{ key: string, value: string }> = []
                  Object.keys(value).forEach(element => {
                    featArray.push({
                      key: element,
                      value: value[element]
                    })
                  })
                  if (featArray.length != 0) {
                    this.featureArray.push({
                      name: key,
                      details: featArray,
                      specialData: null
                    })
                  }
                } else if (typeof (tempArray[key]) == 'string') {
                  this.featureArray.push({
                    name: key,
                    specialData: tempArray[key],
                    details: null
                  })
                }
              });
            } else {
              if (!this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
                this.firstProduct = this.productDetails.variantList[0];
                this.currVariantId = this.firstProduct.id;
              } else {
                this.productDetails.variantList.forEach(variant => {
                  if (variant.id == parseInt(this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum"))) {
                    this.firstProduct = variant;
                  }
                })
              }
              this.imageUrl = this.productDetails.imageUrl;
              this.productName = this.productDetails.productName;
              this.imagesList = [];
              this.imageThumbnailsList = [];
              this.image = undefined;
              this.imagesList = this.firstProduct.images;
              let i = 0;
              if (this.imagesList != undefined) {
                this.imagesList.forEach(imgSrc => {
                  if (imgSrc != undefined) {
                    this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
                  }
                });
                this.imageIndex = 0
                this.image = this.imageUrl + 'l/' + this.imagesList[0];
              }
              this.isoutOfStock = this.firstProduct.outOfStock;
              this.giveRating = this.firstProduct.productRatingAvg;
              this.mrp = this.firstProduct.mrp;
              this.salePrice = this.firstProduct.salePrice;
              this.discount = this.firstProduct.discount;
              // this.showSaving = this.firstProduct.showSaving;
              if (this.productDetails.buyEnquiryMode == Constants.BUY_MODE) {
                this.buyMode = true;
              } else if (this.productDetails.buyEnquiryMode == Constants.ENQUIRY_MODE) {
                this.enquiryMode = true;
              } else if (this.productDetails.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
                this.buyAndEnquiryMode = true;
              }
              if (this.productDetails.attachments != null && this.productDetails.attachments != undefined && this.productDetails.attachments.length != 0) {
                let attachments = this.productDetails.attachments
                attachments.forEach(ele => {
                  if (ele != '') {
                    if (ele.includes('youtube')) {
                      this.attachments.push({
                        url: ele,
                        isYoutube: true,
                        docName: ''
                      })
                    } else {
                      let i = ele.split("/").length - 1
                      this.attachments.push({
                        url: ele,
                        isYoutube: false,
                        docName: ele.split('/')[i]
                      })
                    }
                  }
                })
              }
              let cdnPath = this.productDetails.descPage
              if (cdnPath != undefined && cdnPath != "") {
                this.httpService.getCDNContent(cdnPath, (data) => {
                  if (data != undefined && data != "")
                    this.descContent = data;
                });
              }
              this.featureArray = [];
              if (this.firstProduct.attributes) {
                let tempArray: Object = this.firstProduct.attributes;
                Object.keys(tempArray).forEach(key => {
                  if (typeof (tempArray[key]) == 'object') {
                    let value = tempArray[key]
                    let featArray: Array<{ key: string, value: string }> = []
                    Object.keys(value).forEach(element => {
                      featArray.push({
                        key: element,
                        value: value[element]
                      })
                    })
                    if (featArray.length != 0) {
                      this.featureArray.push({
                        name: key,
                        details: featArray,
                        specialData: null
                      })
                    }
                  } else if (typeof (tempArray[key]) == 'string') {
                    this.featureArray.push({
                      name: key,
                      specialData: tempArray[key],
                      details: null
                    })
                  }
                });
              }
              this.getVariants();
            }
            this.setSeoData();
          }
        }
      } else {
        this.showError = true;
      }
    });
  }

  getQuickLinks() {
    this.homePageService.quicLinksObservable.subscribe((quickLinks) => {
      this.quickLinks = quickLinks
      if (this.quickLinks && this.quickLinks.length > 0) {
        this.quickLinks.forEach(link => {
          if (link.linkName == "Return & Refund") {
            this.showReturnFlag = true;
            this.returnLinkPath = link.linkPath;
          }
        })
      }
    })
  }

  setSeoData() {
    if (this.productDetails && this.image) {
      this.seoService.setMetaDataForDetailsPage(this.currType, this.image, this.productDetails, this.productDetails.metaTagTitle, this.productDetails.metaTagKeyword, this.productDetails.metaTagDesc);
    }
  }

  getVariants() {
    if (this.productDetails.variantAttrMap != undefined) {
      let tempVariantArr = this.productDetails.variantAttrMap;
      Object.keys(tempVariantArr).forEach(key => {
        let filters = tempVariantArr[key]
        let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
        Object.keys(filters).forEach(element => {
          let idxArr: Array<number> = []
          filters[element].forEach(idxValue => {
            idxArr.push(idxValue);
          });
          filtersArr.push({
            option: element,
            mappedUniqueIds: idxArr
          })
        })
        this.variantTypes.push({
          variantHeading: key,
          options: filtersArr
        })
      })
      this.setDefaultSelectedVariants();
    }
  }

  setDefaultSelectedVariants() {
    if (this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
      this.productDetails.variantList.forEach(variant => {
        if (variant.id == parseInt(this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString()))
          this.defaultVariant = variant.variantAttributes;
      })
    } else {
      this.defaultVariant = this.productDetails.variantList[0].variantAttributes;
    }
    let variantAttrMap: Array<{ type: string, option: any }> = []
    Object.keys(this.defaultVariant).forEach(key => {
      variantAttrMap.push({
        type: key,
        option: this.defaultVariant[key]
      })
    })
    for (let type = 0; type < this.variantTypes.length; type++) {
      for (let i = 0; i < variantAttrMap.length; i++) {
        if (this.variantTypes[type].variantHeading == variantAttrMap[i].type) {
          for (let j = 0; j < this.variantTypes[type].options.length; j++) {
            if (this.variantTypes[type].options[j].option == variantAttrMap[i].option) {
              this.selectionArray[type] = j;
            }
          }
        }
      }
    }
    this.defaultSelectedArray = this.selectionArray;
  }

  showZoomImgDialog(imageThumbnailsList, productName, image) {
    if (this.isTab || this.isMobile) {
      let images = {
        imageUrl: this.imageUrl,
        imagesList: this.imagesList,
        imageThumbnailsList: imageThumbnailsList,
        productName: productName,
        selectedImg: image,
        isImages: true,
        isSharingDialog: false,
        selectedImgIdx: this.imageIndex
      }
      this.productImgDialog.open(ProductImgDialogComponent, { panelClass: 'cdk-overlay-full-width-class' }, images).subscribe(response => {
      });
    } else {
      if (this.zoomFlag == 0) {
        this.showZoomImg = true
        this.zoomFlag += 1
      } else {
        if (this.superZoomFlag == 0) {
          this.showSuperZoom = true
          this.superZoomFlag += 1
        } else {
          this.showSuperZoom = false
          this.superZoomFlag = 0
        }
      }
      this.setZoomSlideIcons()
    }
  }

  setZoomSlideIcons() {
    if (this.imageIndex < this.imagesList.length - 1 && this.imageIndex > 0) {
      this.disableZoomRight = false
      this.disableZoomLeft = false
    } else if (this.imageIndex == 0) {
      this.disableZoomLeft = true
      this.disableZoomRight = false
    } else if (this.imageIndex == this.imagesList.length - 1) {
      this.disableZoomLeft = false
      this.disableZoomRight = true
    }
  }

  exitImgZoom() {
    this.showSuperZoom = false
    this.showZoomImg = false;
    this.zoomFlag = 0
  }

  zoomSlideRight() {
    if (this.imageIndex < this.imagesList.length - 1) {
      this.disableZoomLeft = false
      this.imageIndex += 1
      this.image = this.imageUrl + 'l/' + this.imagesList[this.imageIndex]
      if (this.imageIndex == this.imagesList.length - 1) {
        this.disableZoomRight = true
      } else {
        this.disableZoomRight = false
      }
    }
  }

  zoomSlideLeft() {
    if (this.imageIndex > 0) {
      this.disableZoomRight = false
      this.imageIndex -= 1
      this.image = this.imageUrl + 'l/' + this.imagesList[this.imageIndex]
      if (this.imageIndex == 0) {
        this.disableZoomLeft = true
      } else {
        this.disableZoomLeft = false
      }
    }
  }

  slideLeft() {
    this.leftcheck = (this.imageThumbnailsList.length - 3) * -34.2;
    if (this.leftUpdatedValue > this.leftcheck) {
      this.leftUpdatedValue = this.leftUpdatedValue - 34.2;
    }
    this.setThumbnailSlideIcons()
  }

  slideRight() {
    if (this.leftUpdatedValue < 0) {
      this.leftUpdatedValue = this.leftUpdatedValue + 34.2;
    }
    this.setThumbnailSlideIcons()
  }

  setThumbnailSlideIcons() {
    if (this.leftUpdatedValue < 0 && this.leftUpdatedValue > this.leftcheck) {
      this.disableLeft = false
      this.disableRight = false
    } else if (this.leftUpdatedValue == 0) {
      this.disableRight = false
      this.disableLeft = true
    } else if (this.leftUpdatedValue == this.leftcheck) {
      this.disableLeft = false
      this.disableRight = true
    }
  }

  updateImageSrc(thumbnailIndex) {
    this.imageIndex = thumbnailIndex
    this.image = this.imageUrl + 'l/' + this.imagesList[thumbnailIndex];
    this.setZoomSlideIcons()
  }

  goToReturnAndRefund() {
    this.router.navigate(['return-and-refund']);
  }

  moveIt(xOffset: number, yOffset: number): dims {
    let smallerImageDims: dims = {
      height: 450,
      width: 450
    }

    let largerImageDims: dims = {
      height: 560,
      width: 600
    }

    let scale: dims = {
      height: smallerImageDims.height / largerImageDims.height,
      width: smallerImageDims.width / largerImageDims.width
    }

    let position: dims = {
      height: largerImageDims.height / 2 - (yOffset / scale.height),
      width: largerImageDims.width / 2 - (xOffset / scale.width)
    }

    return position
  }

  decreaseProQuantity() {
    if (this.productQuantity > this.productDetails.minOrderQty && this.productQuantity > 1)
      this.productQuantity = this.productQuantity - 1;
  }

  increaseProQuantity() {
    if (this.productQuantity < this.productDetails.maxOrderQty && this.productQuantity > 0)
      this.productQuantity = this.productQuantity + 1;
  }

  playProVideo() {
    if (this.playVideo == false)
      this.playVideo = true;
  }

  showProImages() {
    if (this.playVideo == true)
      this.playVideo = false;
  }

  openSharingDialog() {
    let data = {
      fbUrl: this.socialShareFbUrl,
      googleUrl: this.socialShareGoogleUrl,
      linkedInUrl: this.socialShareLinkedInUrl,
      twitterUrl: this.socialShareTwitterUrl,
      isFb: this.isFbHash,
      isGoogle: this.isGoogleHash,
      isLinkedIn: this.isLinkedInHash,
      istwitter: this.isTwitterHash,
      isImages: false,
      isSharingDialog: true
    }
    this.productImgDialog.open(ProductImgDialogComponent, {}, data).subscribe(response => {
    });
  }

  buyProduct() {
    this.isBuy = true;
    this.addToCartProduct();
  }

  addToCartProduct() {
    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel;
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(this.productDetails.titleId);
      newProd.variantId = this.currVariantId;
      newProd.numCopies = this.productQuantity;
      if (localStorage.getItem('localCart')) {
        localCart = JSON.parse(localStorage.getItem('localCart'));
      }
      if (window.localStorage.getItem('currentUser') !== null) {
        //logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          //update localCart
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        //compute cart
        if (!this.checkProductAlreadyPresent(newProd, false)) {
          this.cartService.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              this.setLocalCart(resp.data[0], false);
              this.cartCountService.updateCart(resp.data[0]);
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
              if (this.isBuy) {
                if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CART) {
                  this.router.navigate(['/shopping-cart']);
                } else if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CHECKOUT) {
                  this.router.navigate(['checkout']);
                }
                this.isBuy = false;
              }
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        //not logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(newProd, false)) {
          this.setLocalCart(newCart, false);
          this.cartCountService.updateCart(newCart);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
          if (this.isBuy) {
            if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CART) {
              this.router.navigate(['/shopping-cart']);
            } else if (Constants.CART_SETTINGS.displayPageAfterBuy == Constants.DISPLAY_PAGE_AFTER_CHECKOUT.CHECKOUT) {
              this.router.navigate(['checkout']);
            }
            this.isBuy = false;
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }
  checkProductAlreadyPresent(cartProduct: CartProductModel, isWishlist: boolean): boolean {
  if(!isPlatformBrowser(this.platformId)) {       return     }
    let localCart;
    if (isPlatformBrowser(this.platformId)) {
      if (isWishlist) {
        localCart = JSON.parse(localStorage.getItem('wishlist'));
      } else {
        localCart = JSON.parse(localStorage.getItem('localCart'));
      }
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == cartProduct.titleId) {
          present = true;
          if (element.variantId && cartProduct.variantId) {
            present = false;
            if (element.variantId == cartProduct.variantId) {
              present = true;
            }
          }
        }
      });
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  setProductVariant(event, variantTypeIdx, optionIdx, mappedUniqueIds) {
    this.selectionArray[variantTypeIdx] = optionIdx
    this.filterSelected = true
    this.selectedVariantTypeIdx = variantTypeIdx
    this.selectedOptionIdx = optionIdx
    if (!this.variantMap.has(variantTypeIdx)) {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    } else {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    }
    if (this.variantMap.size == this.variantTypes.length) {
      this.getVariantId(this.variantMap);
    } else {
      this.selectionArray = this.defaultSelectedArray;
    }
  }

  getVariantId(map: Map<number, { index: number, mapped: Array<number> }>) {
    let resId;
    if (map.size == 1) {
      resId = map.get(0).mapped;
    } else {
      let temp = []
      let inited = false
      map.forEach(value => {
        if (!inited) {
          inited = true
          temp = value.mapped
        } else {
          temp = this.intersectArray(temp, value.mapped);
        }
      })
      resId = temp
    }
    this.currVariantId = resId[0];
    this.selectedVariantId = resId[0];
    this.updateProductByVariantId(this.selectedVariantId);
    this.setSpecTabs(this.selectedVariantId)
  }

  intersectArray(arr1, arr2) {
    return arr1.filter(function (n) {
      return arr2.indexOf(n) !== -1
    })
  }

  updateProductByVariantId(id) {
    let t = this.activatedRoute.snapshot.queryParamMap.get("t").toString();
    let urlText = ""
    if (this.productDetails.productURLText) {
      urlText = this.productDetails.productURLText;
    }
    let params = {
      t: t,
      sizeRecNum: id
    }
    let qParams = Object.assign({}, params)
    this.router.navigate(['/details', urlText], { queryParams: qParams })
    this.isProNotAvailable = false;
    let aFlag = 0;
    this.productDetails.variantList.forEach(variant => {
      if (variant.id == id) {
        aFlag = 1;
        this.productName = this.productDetails.productName;
        this.imagesList = [];
        this.imageThumbnailsList = [];
        this.image = undefined;
        this.imagesList = variant.images;
        let i = 0;
        if (this.imagesList != undefined) {
          this.imagesList.forEach(imgSrc => {
            if (imgSrc != undefined) {
              this.imageThumbnailsList[i++] = this.imageUrl + 's/' + imgSrc;
            }
          });
          this.image = [];
          this.imageIndex = 0
          this.image = this.imageUrl + 'l/' + this.imagesList[0];
        }
        this.isoutOfStock = variant.outOfStock;
        this.giveRating = variant.productRatingAvg;
        this.mrp = variant.mrp;
        this.salePrice = variant.salePrice;
        this.discount = variant.discount;
        // this.showSaving = variant.showSaving;
        if (variant.buyEnquiryMode == Constants.BUY_MODE) {
          this.buyMode = true;
        } else if (variant.buyEnquiryMode == Constants.ENQUIRY_MODE) {
          this.enquiryMode = true;
        } else if (variant.buyEnquiryMode == Constants.BUY_AND_ENQUIRY_MODE) {
          this.buyAndEnquiryMode = true;
        }
        if (this.productDetails.attachments != null && this.productDetails.attachments != undefined && this.productDetails.attachments.length != 0) {
          let attachments = this.productDetails.attachments
          this.attachments = []
          attachments.forEach(ele => {
            if (ele != '') {
              if (ele.includes('youtube')) {
                this.attachments.push({
                  url: ele,
                  isYoutube: true,
                  docName: ''
                })
              } else {
                let i = ele.split("/").length - 1
                this.attachments.push({
                  url: ele,
                  isYoutube: false,
                  docName: ele.split('/')[i]
                })
              }
            }
          })
        }
        let cdnPath = this.productDetails.descPage
        if (cdnPath != undefined && cdnPath != "") {
          this.httpService.getCDNContent(cdnPath, (data) => {
            if (data != undefined && data != "")
              this.descContent = data;
          });
        }
        this.featureArray = [];
        if (variant.attributes != undefined) {
          let tempArray: Object = variant.attributes;
          Object.keys(tempArray).forEach(key => {
            if (typeof (tempArray[key]) == 'object') {
              let value = tempArray[key]
              let featArray: Array<{ key: string, value: string }> = []
              Object.keys(value).forEach(element => {
                featArray.push({
                  key: element,
                  value: value[element]
                })
              })
              if (featArray.length != 0) {
                this.featureArray.push({
                  name: key,
                  details: featArray,
                  specialData: null
                })
              }
            } else if (typeof (tempArray[key]) == 'string') {
              this.featureArray.push({
                name: key,
                specialData: tempArray[key],
                details: null
              })
            }
          });
        }
      }
    })
    if (aFlag == 1) {
      this.isProNotAvailable = false;
    } else {
      this.isProNotAvailable = true;
    }
  }

  addReview() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') !== null) {
        this.addReviewDialogService.open(ProductReviewComponent, {}, null).subscribe(response => {
        });
      } else {
        this.goToLogin(Constants.LOGIN_AFTER_ACTION.WRITE_REVIEW, null)
      }
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (isPlatformBrowser(this.platformId)) {
      if (!isWishlist) {
        localStorage.setItem('localCart', JSON.stringify(newLocalCart));
      } else {
        localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
      }
    }
  }

 addToCartWishList() {       if(!isPlatformBrowser(this.platformId)) {         return       }
    let newProd = new CartProductModel();
    newProd.titleId = Number.parseInt(this.productDetails.titleId);
    newProd.variantId = this.currVariantId;
    newProd.numCopies = 1;
    if (isPlatformBrowser(this.platformId) && window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(newProd, false);
    } else {
      this.goToLogin(Constants.LOGIN_AFTER_ACTION.ADD_TO_WISHLIST, newProd);
    }
  }

  addToCartWishlistUtility(newProd: CartProductModel, isLoginAction: boolean) {       if(!isPlatformBrowser(this.platformId)) {         return       }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(newProd);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(newProd, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
          if (isLoginAction) {
            this.loginService.setLoginRequestModel(new LoginRequestModel());
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
          if (isLoginAction) {
            this.loginService.setLoginRequestModel(new LoginRequestModel());
          }
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  //updated
  performAfterLoginAction(actn: number, cartProductModel: CartProductModel) {
    let isLoginAction = true;
    if (actn == Constants.LOGIN_AFTER_ACTION.WRITE_REVIEW) {
      this.addReviewDialogService.open(ProductReviewComponent, {}, null).subscribe(response => {
      });
    } else if (actn == Constants.LOGIN_AFTER_ACTION.ADD_TO_WISHLIST) {
      this.addToCartWishlistUtility(cartProductModel, isLoginAction);
    }
  }

  goToLogin(actn: number, cartProductModel: CartProductModel) {
    let loginRequestModel: LoginRequestModel = new LoginRequestModel()
    let i = this.currentPageUrl.split("/").length - 2
    loginRequestModel.moveTo = '/' + this.currentPageUrl.split("/")[i] + '/' + this.currentPageUrl.split("/")[i + 1].split("?")[0]
    loginRequestModel.actn = actn
    loginRequestModel.titleId = this.activatedRoute.snapshot.queryParamMap.get("t").toString()
    loginRequestModel.isDetailPage = true
    if (cartProductModel != undefined) {
      loginRequestModel.cartProductMdel = cartProductModel
    }
    if (this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum") != null) {
      loginRequestModel.variantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString()
    }
    this.loginService.setLoginRequestModel(loginRequestModel)
    this.router.navigate(['/login'])
  }

  setSpecTabs(varinatId) {
    if (this.productDetails != undefined && !varinatId) {
      if (this.productDetails.desc != undefined && this.productDetails.desc != null) {
        this.tabHeaders[0].showTab = true
      } else {
        this.tabHeaders[0].showTab = false
      }
      // if (this.productDetails.attributes != null && this.productDetails.attributes != undefined) {
      //   this.tabHeaders[1].showTab = true
      // } else {
      //   this.tabHeaders[1].showTab = false
      // }
      if (this.productDetails.descPage != null && this.productDetails.descPage != undefined && this.productDetails.descPage != '' && this.descContent != undefined && this.descContent != '') {
        this.tabHeaders[2].showTab = true
      } else {
        this.tabHeaders[2].showTab = false
      }
      if (this.productDetails.attachments != null && this.productDetails.attachments != undefined && this.productDetails.attachments.length > 0) {
        this.tabHeaders[3].showTab = true
      } else {
        this.tabHeaders[3].showTab = false
      }
    }

    if (varinatId && this.productDetails) {
      let prodData
      for (var index = 0; index < this.productDetails.variantList.length; index++) {
        var element = this.productDetails.variantList[index];
        if (varinatId == element.id) {
          prodData = element
        }
      }
      if (prodData.attributes != null && prodData.attributes != undefined) {
        this.tabHeaders[1].showTab = true
      } else {
        this.tabHeaders[1].showTab = false
      }
    } else {
      if (this.productDetails.variantList && this.productDetails.variantList.length > 0) {
        let prodData = this.productDetails.variantList[0]
        if (prodData.attributes != null && prodData.attributes != undefined) {
          this.tabHeaders[1].showTab = true
        } else {
          this.tabHeaders[1].showTab = false
        }
      } else {
        if (this.productDetails.attributes != null && this.productDetails.attributes != undefined) {
          this.tabHeaders[1].showTab = true
        } else {
          this.tabHeaders[1].showTab = false
        }
      }
    }

  }

  tabClicked(index) {
    this.selectedTab = index
  }

  specPnlClicked: Array<boolean> = [false, false, false, false]

  toggleSpecPnl(index) {
    if (!this.specPnlClicked[index]) {
      this.specPnlClicked[index] = true
    } else {
      this.specPnlClicked[index] = false
    }
    for (var i = 0; i < this.specPnlClicked.length; i++) {
      if (i != index) {
        this.specPnlClicked[i] = false
      }
    }
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }
  openStores(){
    this.router.navigate(['/premium-dealer'])
  }
  openPage(){
    this.router.navigate(['/online-store'])
  }

  shareProduct(){
    console.log("product shared");
    let navigator:any = window.navigator;
   
        navigator.share({
        title: this.productDetails.metaTagTitle,
        url: window.location.href,
        text: this.productDetails.productName
      });
     
    
  
  }
}

