import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service';
import { BannerModel } from '../../../../../common/models/bannerModel';
import { Router } from '@angular/router';
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})

export class OfferComponent implements OnInit, OnDestroy {

  ngOnDestroy() {

  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public homePageService: HomePageService,
    private router: Router
  ) { }

  public video: any
  public player: any
  public YT: any
  offers: Array<BannerModel> = new Array()
  demoOffer: BannerModel = new BannerModel();
  defaultPath = TemplateConstants.templateAssetsPath + '/images/offer/offer1.jpg';
  isMobile: boolean = false
  init() {
    if (isPlatformBrowser(this.platformId)) {
      var tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
        this.isMobile = true
      } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
        this.isMobile = false
      } else {
        this.isMobile = false
      }
    }
    this.init()
    this.getBanners()
  }

  getBanners() {
    this.homePageService.getBannerOffer(false, (data) => {
      if (data != null && data != '' && data.error == false) {
        this.offers = data.data
        if (this.offers != null && this.offers[0].videoURL != undefined && this.offers[0].videoURL != '' && this.offers[0].videoURL != null) {
          let url = this.offers[0].videoURL
          let vidIdIndx = url.split("?")[0].split("/").length - 1
          let vidId = url.split("?")[0].split("/")[vidIdIndx]
          this.setVideo(vidId)
        }
      } else {
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer1.jpg";
        this.demoOffer.altText = 'defaultOffer1';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
        this.demoOffer = new BannerModel();
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer2.jpg";
        this.demoOffer.altText = 'defaultOffer2';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
        this.demoOffer = new BannerModel();
        this.demoOffer.imageName = TemplateConstants.templateAssetsPath + "/images/offer/offer3.jpg";
        this.demoOffer.altText = 'defaultOffer3';
        this.demoOffer.videoURL = "";
        this.offers.push(this.demoOffer);
      }
    })
  }

  setVideo(vid) {
    this.video = vid
    if (isPlatformBrowser(this.platformId)) {
      window['onYouTubeIframeAPIReady'] = (e) => {
        this.YT = window['YT'];
        this.player = new window['YT'].Player('player', {
          videoId: this.video,
          playerVars: { 'autoplay': 1, 'controls': 1, host: 'https://www.youtube.com', enablejsapi: 1, rel: 0 },
          events: {
            'onReady': (e) => {
              window.addEventListener('scroll', this.onScroll, false)
            }
          }
        })
      }
    }
  }

  onScroll(event) {
    if (isPlatformBrowser(this.platformId)) {
      let fraction = 0
      let videos = document.getElementsByTagName('iframe')
      if (videos != undefined && videos != null) {
        for (let i = 0; i < videos.length; i++) {
          let video = videos[i]
          let x = video.offsetLeft
          let y = video.offsetTop
          let w = video.offsetWidth
          let h = video.offsetHeight
          let r = x + w
          let b = y + h
          let visX, visY, vis
          visX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset))
          visY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset))
          vis = visX * visY / (w * h)
          if (vis > fraction && this.player) {
            this.player.contentWindow.postMessage(
              '{"event":"command","func":"pauseVideo","args":""}',
              '*');
          } else {
            this.player.contentWindow.postMessage(
              '{"event":"command","func":"playVideo","args":""}',
              '*');
          }
        }
      }
    }
  }

  offerImgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
