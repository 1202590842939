<div class="mask">
    <div class="imagePopUpWrapper">
        <span class="left-arrow fa fa-caret-left" [ngClass]="{'nonVisible': !leftArrowVisibility}" (click)="leftArrowClicked()"></span>
        <div class="maskBodyDiv">
            <div class="imgContainer">
                <img [src]="resourceName" alt="{{resourceTitle}}" (error)="imgErrorHandler($event)" class="img">
            </div>
        </div>
        <span class="right-arrow fa fa-caret-right" [ngClass]="{'nonVisible': !rightArrowVisibility}" (click)="rightArrowClicked()"></span>
    </div>
</div>