import { Component, OnInit, OnDestroy } from '@angular/core';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { RouterEvent, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { HomePageService } from '../../../services/home-page.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { HttpService } from 'src/app/services/http.service';

/**
 * This component displays a static-page for channel-partners only. Only users of type channel-partners can access the route 'channel-partner' and see this component.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-channel-partner',
  templateUrl: './channel-partner.component.html',
  styleUrls: ['./channel-partner.component.scss']
})
export class ChannelPartnerComponent implements OnInit, OnDestroy {

  quickLinks: Array<StaticPageModel>
  cdnPath: string
  errorFlag: boolean = true;
  heading: string
  content: string
  img404Src: string
  defaultPath: string = TemplateConstants.defaultImgPath;
  routerNavFlag: boolean;
  _routeSubscription;

  /**
   * @constructor
   * @param router 
   * @param httpService 
   * @param homePageService 
   * @param location 
   */
  constructor(
    private router: Router,
    private httpService: HttpService,
    private homePageService: HomePageService,
    public location: Location,
  ) { }

  /**
   * Subscribe to routerEvent and on every route-change, fetch static-pages for the current store. 
   */
  ngOnInit() {
    this.routerNavFlag = false;
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.getStaticPageData();
      }
    });
    if (!this.routerNavFlag) {
      this.getStaticPageData();
    }
  }

  /**
   * unsubscribe all subscription when component destroys.
   */
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  /**
   * Fetch static-pages for the current store and filter-out static page for 'channel-partner'.
   * Fetch its content from CDN using static-page url and 
   * the display the content.
   */
  getStaticPageData() {
    this.homePageService.getQuickLinks((resp) => {
      if (resp.data) {
        this.quickLinks = resp.data;
        this.quickLinks.forEach(item => {
          if (this.location.path() === ("/" + item.pageURL)) {
            this.cdnPath = item.linkPath;
            this.heading = item.pageTitle;
            this.errorFlag = false;
            if (this.cdnPath != undefined && this.cdnPath != "") {
              this.httpService.getCDNContent(this.cdnPath, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
          }
        })
        if (this.errorFlag) {
          this.img404Src = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400;
        }
      }
    });
  }

  /**
   * set default image on image error event.
   * @param event - image error event
   */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
