<div fxLayout="column" class="login-component">
  <div>
    <p class="heading">{{heading}}</p>
    <p class="error-lbl" #errorLabel></p>
    <div class="login-form" [ngClass]="{'hide': !isLoginForm}">
    <p class="error-lbl" #loginErrorLabel></p>
    
      <form role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit($event, loginErrorLabel)" class="login-desk-view">
        <input formControlName="email" type="email" placeholder="Email address*" tabindex="1" class="textFields" maxlength="255">
        <input formControlName="password" type="password" placeholder="Password*" tabindex="2" class="textFields" maxlength="100">
        <div class="sub-links">
          <div class="chckbx">
            <input formControlName="remember" type="checkbox" tabindex="3">
            <label class="chckbx-label">Remember Me</label>
          </div>
          <a [routerLink]="'/forgot-password'" tabindex="5" class="lnkForgotPwd">Forgot Password?</a>
        </div>
        <div class="btnPnl">
            <button class="button" tabindex="4">Login</button>
        </div>
      </form>
    </div>
    <div class="signup-form" [ngClass]="{'hide': !isRegisterForm}">
    <p class="error-lbl" #regErrorLabel></p>
    
      <form [formGroup]="registerForm" (ngSubmit)="signUpSubmit($event, regErrorLabel)" class="sign-up-view">
        <div class="signUpPnl">
          <div class="signup-name">
            <input type="text" formControlName="name" placeholder="Name*" tabindex="1" class="textFields" maxlength="60">
          </div>
          <div class="signup-email">
            <input type="email" formControlName="emailSignUp" placeholder="Email address*" tabindex="2" class="textFields" maxlength="255">
          </div>
          <div class="signup-phone">
            <input type="text" formControlName="mobileSignUp" placeholder="Mobile Number" minlength="10" maxlength="10" tabindex="3" class="textFields" (keypress)="keyPressOnlyNumbers($event)">
          </div>
          <div class="signup-password">
            <input type="password" tabindex="4" formControlName="passwordSignUp" placeholder="Password*" class="textFields" (keyup)="manageStrength($event, pwdStrength)" maxlength="100">
            <div class="pnlStrength">
              <span class="pwdStrength" #pwdStrength></span>
            </div>
          </div>
          <div class="signup--cnfrm-password">
            <input type="password" tabindex="5" formControlName="cnfrmPwSignUp" placeholder="Confirm Password*" class="textFields" maxlength="100">  
          </div>
          <div class="signup-chckbx">
            <input formControlName="subscribeToNewsSignUp" type="checkbox" tabindex="6">
            <label class="signup-chckbx-label">Subscribe to Newsletter</label>
          </div>
          <a [routerLink]="'/login'" tabindex="8" class="lnkAlrdyRegistered">Already registered? Login</a>          
          <div class="btnPnl">
              <button class="button" tabindex="7">Register</button>
          </div>
        </div>
      </form>
    </div>
    <div fxLayoutAlign="center start" fxLayout="column" [ngClass]="{'signup-loginVia':isRegisterForm, 'loginVia': !isRegisterForm , 'hide' : isChannelPartnerLogin || (!fbOauth && !googleOauth)}"> <!-- [ngClass]="{'signup-loginVia':isRegisterForm, 'loginVia': !isRegisterForm , 'hide' : isChannelPartnerLogin || (!fbOauth && !googleOauth)}" -->
      <div *ngIf="fbOauth" class="fbPnl fbPnlPresent" tabindex="10" (click)="socialSignIn('facebook', errorLabel)">
        <a class="lnkFB">
          <span></span>
          <p>Login With Facebook</p>
        </a>
      </div>
      <div *ngIf="googleOauth" class="gPlusPnl" tabindex="11" (click)="socialSignIn('google', errorLabel)">
        <a class="gPlusLnk">
          <span></span>
          <p>Login With Google</p>
        </a>
      </div>
    </div>
  </div>
</div>