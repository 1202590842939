<div fxLayout="column" class="popUpMsgInfoDiv" *ngIf="!showCustCare">
  <div class="body">
    <p>{{msg}}</p>
  </div>
  <div class="footer">
    <button class="okBtn" (click)="closeDialog()">OK</button>
  </div>
</div>
<div class="custCareDialog" *ngIf="showCustCare" fxFlexFill fxLayoutAlign="center center">
  <img [src]="curCareImgPath">
</div>