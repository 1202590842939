<div>
  <div class="main-container" [ngClass]="parentClass">
    <div class="pnlCollectionTitle">
      <p *ngIf="!searchResultsPage">{{pageTitle}}</p>
      <div class="searchTitle" *ngIf="searchResultsPage && !showError">
        <h2>Search Products for</h2>
        <span>"{{searchKey}}"</span>
      </div>
    </div>
    <div class="collectionPart">
      <div class="filterParent" [ngClass]="{'showFilters': _showFilters, 'hideFilters':_showFilters }" [class.fixed]="navIsFixed" *ngIf="filterList != undefined && filterList.length != 0"
        [ngClass]="{'showFilters': _showFilters, 'hideFilters':_showFilters }">
        <div *ngIf="!searchResultsPage">
          <div *ngIf="buyingGuide != ''" class="pnlBuyingGuide">
            <a routerLink="/buying-guide/{{categoryUrl}}" target="_blank">BUYING GUIDE</a>
          </div>
          <div>
            <app-filters (onSelectingFilter)="updateCollection($event)" (applyFilterClose)="showFilters()"
              [filter-list]="filterList"></app-filters>
          </div>
        </div>
      </div>
      <div class="filter-mob" *ngIf="!errorPage && !_fetchCollectionOnUrl && filterList != undefined && filterList.length > 0">
        <span *ngIf="!searchResultsPage && !_showFilters" class="fa fa-lg filter-span" (click)="showFilters()"></span>
      </div>
      <div class="filter-mob" *ngIf="!errorPage && !_fetchCollectionOnUrl && filterList != undefined && filterList.length > 0">
        <span *ngIf="!searchResultsPage && _showFilters" class="fa fa-lg filter-span filterApplied" (click)="showFilters()"></span>
      </div>
      <div class="collectionParent" [ngClass]="{'hide': _showFilters, 'non-filter-collectionParent': (filterList == undefined || filterList.length == 0), 'move-left': navIsFixed}">
        <div class="selectedFiltersPanel" *ngIf="FilterModel != undefined && FilterModel.length > 0">
          <h4 class="showResultsHeading">Showing Results in : </h4>
          <div class="filterTagsPanel">
            <ul class="filterTypeIterator">
              <li *ngFor="let filter of FilterModel; let i=index;">
                <div class="selFilterTypePanel" *ngIf=" filter.filterModelList != undefined && filter.filterModelList.length > 0">
                  <p class="filterTypeLabel">{{filter.filterName}} : </p>
                  <ul class="filterNameIterator">
                    <ls-tag-container (tag-removed)="remove($event)" [tags]="filter?.filterModelList"></ls-tag-container>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="clearAllPanel" (click)="clearAllFilters()" *ngIf="FilterModel.length > 0">
            <span class="crossIcon fa fa-close"></span>
            <p class="clearAllLabel">Clear All Filters</p>
          </div>
        </div>
        <div class="tile-container-parent">
          <div *ngIf="!searchResultsPage">
            <div *ngIf="buyingGuide != ''" class="mb-pnlBuyingGuide">
              <a routerLink="/buying-guide/{{categoryUrl}}" target="_blank">BUYING GUIDE</a>
            </div>
          </div>
          <div *ngFor="let product of products" class="tile-container">
            <div class="productTile" *ngIf="collectionProductLinkType == -1 || collectionProductLinkType == constants?.collectionProductLinkType?.PRODUCT">
              <!-- *ngIf="collectionProductLinkType == -1 || collectionProductLinkType == 0" -->
              <app-product-tile [tile-data]="product" [view]="listViewStr" [canCompare]="canCompare" [specialColl]="_fetchCollectionOnUrl || searchResultsPage"
                (onSelectingCompare)="compareProducts($event)" [onRemoveCompare]="removeTtlId" (addToCart)="addToCartProduct($event)"
                (addToCartWishlist)="addToWishlist($event)"></app-product-tile>
            </div>
            <div class="mediaOption" *ngIf="collectionProductLinkType == constants?.collectionProductLinkType?.OFFER || collectionProductLinkType == constants?.collectionProductLinkType?.VIDEO">
              <!-- *ngIf="collectionProductLinkType == 1 || collectionProductLinkType == 2" -->
              <div class="pnlCollectionImg" *ngIf="collectionProductLinkType == constants?.collectionProductLinkType?.OFFER ">
                <!-- *ngIf="collectionProductLinkType == 1" -->
                <a [href]="product.desc">
                  <div class="pnlImgForMedia">
                    <!-- <img [src]="(product?.imageUrl && product.images && product.images[0])? product?.imageUrl +'m/'+ product.images[0]:defaultPath"
                      (error)="imgErrorHandler($event)"> -->
                    <img [src]="(product?.productURLText)? product?.productURLText:defaultPath" (error)="imgErrorHandler($event)">
                  </div>
                  <p class="imgNameForMedia">{{product.productName}}</p>
                </a>
              </div>
              <div class="pnlCollectionVideo" *ngIf="collectionProductLinkType == constants?.collectionProductLinkType?.VIDEO">
                <!-- *ngIf="collectionProductLinkType == 2" -->
                <div class="snippet">
                  <iframe [src]="product.productURLText | safeUrl"></iframe>
                </div>
                <p class="imgNameForMedia">{{product.productName}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="pnlShowMore"  *ngIf="!errorPage" [ngClass]="{'show':hasMore}" (click)="showMore()">
          <a [ngClass]="{'showMoreColltn' : _fetchCollectionOnUrl, 'lnkShowMore': !_fetchCollectionOnUrl }">Show More
            Products</a>
        </div>
        <div class="pnlNoResultOuter" *ngIf="errorPage">
          <div class="pnlNoResultInner">
            <p class="lblNoResult">Couldn't find what you were looking for?</p>
            <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="seoContent hide" *ngIf="content != undefined && showSeo">
      <div [innerHtml]='content | safeHtml' class="static-content"></div>
    </div>
    <div *ngIf="showError" class="no-results">
      <div class="pnlNoResultInner">
        <p class="lblNoResult">Couldn't find what you were looking for?</p>
        <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
      </div>
    </div>
    <div fxLayoutAlign="center center" class="errorPage" *ngIf="parentClass == 'showErrorPage'">
      <img [src]="errorPageUrl" style="max-width:100%; max-height:100%;">
    </div>
  </div>
  <div class="pnlCompare" *ngIf="compareProList.length >= 1">
    <div class="pnlCompareDetails">
      <div class="pnlCompareDet">
        <ul>
          <li *ngFor="let comparePro of compareProList">
            <div class="imgContainer">
              <img [src]="(comparePro?.productModel?.imageUrl  && comparePro?.productModel?.images && comparePro?.productModel?.images[0])?comparePro?.productModel?.imageUrl +'s/'+ comparePro?.productModel?.images[0] :defaultPath"
                (error)="imgErrorHandler($event)">
            </div>
            <input class="compareInput2" type="checkbox" checked (click)="removeComparePro(comparePro.productModel.titleId)">
            <p class="tag">{{comparePro.productModel.productName}}</p>
            <span class="removeSpan fa fa-close" (click)="removeComparePro(comparePro.productModel.titleId)"></span>
          </li>
        </ul>
      </div>
      <div class="pnlCompareBtn" [ngClass]="{'disableCompareBtn' : compareProList.length <= 1}">
        <a (click)="goToCompare()">COMPARE</a>
      </div>
    </div>
  </div>
</div>