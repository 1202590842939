<div class="dialogMainPanel" *ngIf="isImages">
  <div class="productImagePanel">
    <div class="mb-leftArrow" (click)="zoomSlideLeft()">
      <span class="fa fa-caret-left" [ngClass]="{'disable-arrow': disableZoomLeft, 'opacity-none': imageThumbnailsList.length <= 1}"></span>
    </div>
    <div class="mediumImage">
      <img [src]="imageSrc != undefined?imageSrc:defaultPath" (error)="imgErrorHandler($event)">
    </div>
    <div class="mb-rightArrow" (click)="zoomSlideRight()">
      <span class="fa fa-caret-right" [ngClass]="{'disable-arrow': disableZoomRight, 'opacity-none': imageThumbnailsList.length <= 1}"></span>
    </div>
  </div>
  <span class="fa fa-close" (click)="closeDialog()"></span>
</div>
<div *ngIf="isSharingDialog" class="shareDialogMainPanel">
  <div class="socialHeader">
    <p>Share this product</p>
  </div>
  <div class="socialLinks">
    <a *ngIf="isFbHash" class="socialLink fb" href={{socialShareFbUrl}} target="_blank"></a>
    <a *ngIf="isGoogleHash" class="socialLink google" href={{socialShareGoogleUrl}} target="_blank"></a>
    <a *ngIf="isLinkedInHash" class="socialLink linkedIn" href={{socialShareLinkedInUrl}} target="_blank"></a>
    <a *ngIf="isTwitterHash" class="socialLink twitter" href={{socialShareTwitterUrl}} target="_blank"></a>
  </div>
</div>