<div class="brandPnl" *ngIf="brands.length > 0">
  <div class="brandContainer">
    <div class="brandHdng">
      <p class="ourBrandLabel">{{parentCompanyName}}</p>
    </div>
    <div class="brand-pnl">
      <ul class="brandItr">
        <li class="brandItrLi" *ngFor="let brand of brands">
          <a class="brandLnk" [href]="'http://'+brand.manufacturerUrl" target="_blank">
            <img class="brandImg" [src]="brand.imageName" (error)="imgErrorHandler($event)">
            <p class="brandLabel">{{brand.manufacturerUrl}}</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>