<div class="productReviewContainer">
  <div class="header">
    <p class="heading">{{lblAddReview}}</p>
    <span class="closeButton fa fa-close" (click)="closeDialog()"></span>
  </div>
  <div class="addReviewForm">
    <form role="form" [formGroup]="addReviewForm" (ngSubmit)="addReviewSubmit($event, errorLabel)">
      <div class="pnlReviewBdy">
        <div class="pnlReviewNote">
          <p #errorLabel>{{note}}</p>
        </div>
        <div class="pnlTitleReview">
          <p>{{lblTitle}}</p>
          <input formControlName="title" type="text" tabindex="1" class="txtTitle"  maxlength="60">
        </div>
        <div class="pnlReviewContent">
          <p>{{lblReview}}</p>
          <textarea formControlName="review" type="text" tabindex="2" class="txtReview"></textarea>
        </div>
        <div class="pnlRating">
          <p>{{lblProductRating}}</p>
          <ls-rating formControlName="productRating" tabindex="3" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating1}}</p>
          <ls-rating formControlName="rating1" tabindex="4" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating2}}</p>
          <ls-rating formControlName="rating2" tabindex="5" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating3}}</p>
          <ls-rating formControlName="rating3" tabindex="6" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating4}}</p>
          <ls-rating formControlName="rating4" tabindex="7" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating5}}</p>
          <ls-rating formControlName="rating5" tabindex="8" [editable]=true></ls-rating>
        </div>
        <div class="pnlRating">
          <p>{{lblRating6}}</p>
          <ls-rating formControlName="rating6" tabindex="9" [editable]=true></ls-rating>
        </div>
        <div class="updateButton">
          <button tabindex="4" [disabled]="!addReviewForm.valid" class="button">{{lblButton}}</button>
        </div>
      </div>
    </form>
  </div>
</div>