<div class="comparePageMain" *ngIf = "products.length > 0">
  <div class="comparePageHeading">
    <p class="heading">COMPARE PRODUCTS</p>
    <div class="pnlPrintOption">
      <a (click)="printCompareProducts(true)">
        <span class="fa fa-print"></span>
        <p>Print the comparison</p>
      </a>
    </div>
  </div>
  <div class="pnlCompareLeft">
    <div class="pnlCatHdng">
      <p class="lblCatHdng">{{catHdng}}</p>
    </div>
    <div class="pnlHdngs">
      <ul class="hdngItr">
        <li *ngFor="let hdng of compareHdngs">
          <p class="lblProHdng">{{hdng}}</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="pnlCompareRight">
    <ul class="proDtlsItr">
      <li *ngFor="let product of products" [class.two-cols]="products.length == 2" [class.three-cols]="products.length == 3">
        <div class="pnlTileDetails">
          <div class="pnlProdDetails">
            <div class="pnlImg">
              <a [routerLink]="'/details/'+ (product.productURLText ? product.productURLText : '')" [queryParams]="{'t': product.titleId}">
                <img [src]="(product.imageUrl != undefined && product.imageUrl != null && product.images != null && product.images.length != 0) ? product.imageUrl+'/m/'+product.images[0] :defaultPath " 
                class = "center-position"  alt="" (error)="imgErrorHandler($event)">
              </a>
            </div>
            <p class="title">{{product.productName}}</p>
            <div class="pnlPrice">
              <p>{{product.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
            </div>
          </div>
          <div class="pnlProFeatures">
            <div class="proDesc" [innerHTML]="product.desc">
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="proSpec">
    <ul class="specHdrItr">
      <li *ngFor="let spec of specsArray; let idx = index;" class="specHdrLi">
        <p class="specHdr">{{spec.hdr}}</p>
        <ul class="childHdrItr">
          <li *ngFor="let childHdr of spec.childHdrs; let subIdx = index;" class="childHdrLi">
            <p class="childHdr">{{childHdr}}</p>
            <div *ngFor="let feature of featureArray; let i = index;" class="childValuesLi" [class.childValuesLi-three-cols]="featureArray.length == 3" [class.childValuesLi-two-cols]="featureArray.length == 2">
              <div *ngIf="feature.arr[idx].details[subIdx] != null" [innerHTML]="feature.arr[idx].details[subIdx].value"></div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>