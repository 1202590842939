import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { HomePageService } from '../../../../../services/home-page.service'
import { CollectionModel } from '../../../../../common/models/collectionModel'
import { isPlatformBrowser } from '@angular/common';
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  styleUrls: ['./home-products.component.scss']
})
export class HomeProductsComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }
  constructor(
    private homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  left: number = 0
  rightVisibility: boolean = true
  leftVisibility: boolean = false
  
  slideConst: number
  slideLength: number
  iteration: number = 0
  currIteration: number = 1

  collectionArray: Array<CollectionModel> = [];
  showCount: number = 3
  isDetailPage: boolean = false
  fetchProducts: boolean = false
  defaultShop = TemplateConstants.templateAssetsPath + '/images/default/defaultShop.png';

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 767) {
        this.showCount = 1;
      }
    }
    let categoryId;
    this.homePageService.getHomePageCollectionIn(this.isDetailPage, this.fetchProducts, categoryId, (data) => {
      if (data != undefined && data.data != undefined){
        let collectionArray: Array<CollectionModel> = data.data;
        collectionArray.forEach((ele) => {
          if(ele.collectionType == 0){
            this.collectionArray.push(ele);
          }
        })
      }
      if (this.collectionArray.length <= this.showCount) {
        this.rightVisibility = false;
      }
    });
  }

  moveToLeft(event) {
    if (this.slideLength == undefined) {
      this.slideLength = event.target.previousElementSibling.getElementsByClassName("hotCollPnl").length
    }
    if (this.slideConst == undefined) {
      this.slideConst = event.target.parentElement.getElementsByClassName("hotCollItr")[0].offsetWidth
    }
    this.leftVisibility = true
    this.left = this.left - this.slideConst
    this.iteration = Math.ceil(this.slideLength / this.showCount)
    this.currIteration++
    if (this.currIteration == this.iteration) {
      this.rightVisibility = false
    }
  }

  moveToRight() {
    this.rightVisibility = true
    this.left = this.left + this.slideConst
    this.currIteration--
    if (this.left == 0 || this.left > 0) {
      this.leftVisibility = false
    }
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultShop;
  }

}
