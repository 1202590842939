<header *ngIf="!checkOutVisibility">
  <div class="header-desktop">
    <div class="header-top pnlUserLinksOuter headerBg pnlSearchForMobileExpand" [ngClass]="{'header-top-bg': isSttc}">
      <div class="pnlSearchHeader">
        <div class="trackOrderLoginDtl" *ngIf="!loggedIn">
          <!-- <a [routerLink]="'login'" id="lnk_login" class="lnk_login">Login</a>
          <a [routerLink]="'login'" [queryParams]="{action: 'register'}" id="lnk_register" class="lnk_register">Registration</a> -->
          <ng-container
            *ngIf="!(companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmServiceUrl && companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmUrl)">
            <a id="lnk_login" class="lnk_login" (click)="goToLogin()">Login</a>
            <a id="lnk_register" class="lnk_register" (click)="goToRegistration()">Registration</a>

          </ng-container>
        </div>
        <div class="user-info" *ngIf="loggedIn">
          <div class="user-name-container">
            <p class="user-name">Welcome</p>
            <p class="lblUserName"
              [innerHTML]="(userState.fName !=null && userState.fName != '' )? userState.fName + '!!' : userState.userName + '!!'">
            </p>
          </div>
          <a class="my-account" [routerLink]="'/my-dashboard/profile'">My Account</a>
          <p class="log-out" (click)="logOut()">Logout</p>
        </div>
        <div class="pull-right cart-info hideCart"
          *ngIf="!(companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmServiceUrl && companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmUrl)"
          (click)="navigateToCart()">
          <span class="spnBag fa fa-lg fa-shopping-cart"></span>
          <p class="cart-count">({{cartCount}} &nbsp;items)</p>
        </div>
        <div class="app-search" [ngClass]="{'search-expanded': searchExpanded}">
          <app-search (inputFocus)="searchExpand()" (inputOutOfFocus)="searchOriginal()"></app-search>
        </div>
      </div>
    </div>
    <div class="vendor-info">
      <div class="width-adjust">
        <div class="vendor-logo">
          <!-- <a href="" class="logo-link"> -->
          <a [routerLink]="''" class="logo-link" *ngIf="companyDetails">
            <img [src]="companyDetails.storeLogo != undefined?companyDetails.storeLogo:defaultPath "
              class="center-position img-logo" onError="this.src = 'assets/images/defaultLogo.png'"
              title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
          </a>
        </div>
        <div class="vendor-email-tag">
          <p class="tagline" [innerHTML]="companyDetails?.storeTagLine"></p>
          <div class="cust-info">
            <div class="cust-contact">
              <span class="cust-phone"></span>
              <a class="cust-contact-num" [innerHTML]="companyDetails?.ccContactNo"></a>
            </div>
            <div class="cust-email">
              <span class="cust-email-span"></span>
              <a class="cust-email-id" [innerHTML]="companyDetails?.ccMail"></a>
            </div>
          </div>
        </div>
        <div class="parent-logo">
          <a href="http://www.taurus.es" target="_blank" class="parent-lnk">
            <img class="parent-img center-position" [src]='parentLogoPath'>
          </a>
        </div>
      </div>
    </div>
    <div class="category">
      <ul *ngIf="categoryList != undefined" class="itrCat">
        <li *ngFor="let category of categoryList; let i = index;"
          [ngClass]="{'selectedCategory': i == highlightedIndex}">
          <div id="itrCat-0">
            <a attr.categoryid={{category.categoryId}} class="lnkCatName" id="lnkCatName">
              <h1>{{category.categoryName}}</h1>
              <!-- <span *ngIf="category.categoryModels != undefined" class="mob-arrow-down fa fa-lg fa-angle-down"></span> -->
            </a>
            <ul *ngIf="category.categoryModels != undefined" class="itrCat">
              <li *ngFor="let subCat of category.categoryModels">
                <div id="itrCat-1">
                  <!-- <a attr.categoryid={{subCat.categoryId}} class="lnkCatName" id="lnkCatName" href="collections/{{subCat.categoryURL}}" style="position:relative"> -->
                  <a attr.categoryid={{subCat.categoryId}} class="lnkCatName" id="lnkCatName"
                    routerLink="collections/{{subCat.categoryURL}}" style="position:relative">
                    {{subCat.categoryName}}
                    <!-- <span *ngIf="subCat.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right" style="position: absolute;right: 9px;font-weight: bold;"></span> -->
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="header-mobile">
    <div class="vendor-email-tag">
      <div class="cust-contact">
        <span class="cust-phone"></span>
        <a class="cust-contact-num" [innerHTML]="companyDetails?.ccContactNo"></a>
      </div>
      <div class="cust-email">
        <span class="cust-email-span"></span>
        <a class="cust-email-id" [innerHTML]="companyDetails?.ccMail"></a>
      </div>
    </div>
    <div class="header-bottom">
      <div class="navigation" (click)="showCategories()">
        <span class="nav-menu" *ngIf="!isNavBarOpened"></span>
        <span class="closed-nav-menu" *ngIf="isNavBarOpened"></span>
      </div>
      <div class="header-logo">
        <!-- <a href="" class="lnkLogo"> -->
        <a [routerLink]="''" class="lnkLogo" *ngIf="companyDetails">
          <img [src]="companyDetails.storeLogo != undefined?companyDetails.storeLogo:defaultPath "
            class="center-position img-logo" onError="this.src = 'assets/images/defaultLogo.png'"
            title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
        </a>
      </div>
      <div class="cart-details">
        <div id="searchForMobile" class="searchForMobile col-xs-4 noPadding pull-right">
          <span id="Span74" class="searchSpn" (click)="showSearch(search)"></span>
        </div>
        <div id="pnlCartOuter"
          *ngIf="!(companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmServiceUrl && companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmUrl)"
          class="cartMobile col-xs-4 noPadding pull-right pnlCartOuter hideCart" (click)="navigateToCart()">
          <a id="Link164" class="cartSpn">
            <p id="Label165" class="lblCartNumMob">{{cartCount}}</p>
          </a>
        </div>
        <div id="lnk_login"
          *ngIf="!(companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmServiceUrl && companyDetails?.onlineStoreSettingDTO?.integrationDTO?.crmUrl)"
          class="userDetails col-xs-4 noPadding pull-right" (click)="openLoginDialog()">
          <a id="Link109" class="userSpan"></a>
        </div>
      </div>
    </div>
    <div class="search-header-mob" #search>
      <app-search></app-search>
    </div>
  </div>
</header>