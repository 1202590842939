export class StaticPageModel {
    recNo: number;
    linkName: string;
    altText: string;
    pageTitle: string;
    pageURL: string;
    pageFileName: string;
    seqNo: number;
    visibleImg: string;
    linkPath : string;
    httpUrl: string;

    public setData(pageURL:any, linkName:any){
        this.pageURL = pageURL;
        this.linkName = linkName;
    }
}