import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomePageService } from '../../../services/home-page.service';
import { SocialSettingsModel } from '../../../common/models/social-settings-model'
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  socialSettings: SocialSettingsModel  
  constructor(
    public homePageService: HomePageService,
  ) { }

  ngOnInit() {
    this.getSocialLinks();    
  }

  getSocialLinks() {
    this.homePageService.getStoreSettings("social", resp => {
      this.socialSettings = resp.data;
    })
  }

}
