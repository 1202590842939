import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms'
import { SelectItemModel } from '../../../common/models/select-item.model'
import { DealerScreen } from '../../../common/models/dealerScreen'
import { ContactService } from '../../../services/contact.service'
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

/**
 * This component displays list of all premium dealers of the store (city-wise) with their contact-details, names and addresses.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-premium-dealer',
  templateUrl: './premium-dealer.component.html',
  styleUrls: ['./premium-dealer.component.scss']
})
export class PremiumDealerComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  heading = "PREMIUM DEALER"
  headingDesc = "To Locate a Inalsa dealer close to you select the state and city from the drop down below or click here for Corporate Enquiry"
  stateOptions : Array<SelectItemModel> = new Array<SelectItemModel>()
  cityOptions : Array<SelectItemModel> = new Array<SelectItemModel>()
  stateList = []
  cityList = []
  premiumDealerForm: UntypedFormGroup
  showDealers:boolean = false
  dealers : Array<DealerScreen> = new Array<DealerScreen>()

  /**
   * @constructor
   * @param formBuilder 
   * @param contactService 
   * @param router 
   * @param activatedRoute 
   */
  constructor(
    private formBuilder : UntypedFormBuilder,
    private contactService: ContactService,  
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * Prepares combos for city and state.
   */
  ngOnInit() {
    let selectItem1 : SelectItemModel = new SelectItemModel();
    selectItem1.SelectItem(-1,'Select City')
    this.cityOptions.push(selectItem1)
    let selectItem2 : SelectItemModel = new SelectItemModel();
    selectItem2.SelectItem(-1,'Select State')
    this.stateOptions.push(selectItem2)
    this.premiumDealerForm = this.formBuilder.group({
      state : ["-1", [Validators.required]],
      city : ["-1", [Validators.required]]
    })
    this.getStateList()
  }

  /**
   * Fetches list of all states and populates them into the state-combo.
   */
  getStateList(){
    this.contactService.getStates((data) => {
      if(data != null && !data.error)
      if (data.data != null) {
        this.stateList = data.data;
        Object.keys(this.stateList).forEach(ele => {
          let selectItem : SelectItemModel = new SelectItemModel()  
          selectItem.SelectItem(ele, this.stateList[ele])    
          this.stateOptions.push(selectItem);
        })
      }
    })
  }

  /**
   * Fetches list of all cities for a state and populates them in city-combo.
   * @param stateCode - code of state for which cities are to fetched.
   */
  getCitiesByState(stateCode){
    this.cityOptions = []
    let selectItem1 : SelectItemModel = new SelectItemModel();
    selectItem1.SelectItem(-1,'Select City')
    this.cityOptions.push(selectItem1)
    this.premiumDealerForm.patchValue({'city':-1})
    this.contactService.getCitiesByState(stateCode, data=>{
      if(data != null && !data.error)
        if(data.data != null){
          this.cityList = data.data
          this.cityList.forEach(ele => {
            let selectItem: SelectItemModel = new SelectItemModel()
            selectItem.SelectItem(ele,ele)
            this.cityOptions.push(selectItem)
          })
        }
    })
  }

  /**
   * This method fetches list of all premium-dealers of store for a city.
   * @param errorLabel 
   */
  submitPremiumDealer(errorLabel){
    if(this.premiumDealerForm.value.state == -1 || this.premiumDealerForm.value.state == null){
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please Select The State !!";      
    }else if(this.premiumDealerForm.value.city == -1 || this.premiumDealerForm.value.city == null){
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please Select The City !!";
    }else if(this.premiumDealerForm.valid){
      let state = this.premiumDealerForm.value.state
      let city = this.premiumDealerForm.value.city
      this.contactService.getDealersByCity(state, city, data=>{
        if(!data.error && data != null && data.data != null){
          if(data.data.data != null){
            this.showDealers = true        
            this.dealers = data.data.data
          }
        }
      })
    }  
  }
}
