import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectItemModel } from 'src/app/common/models/select-item.model';
import { ProductDetailsService } from 'src/app/services/product-details.service';
import { ProductRegistrationModel } from 'src/app/common/models/productRegistrationModel';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from 'src/app/LSNG/components/ls-dialog/ls-dialog.service';
import { ProductRegisteredSuccessfullyComponent } from '../product-registered-successfully/product-registered-successfully.component';


@Component({
  selector: 'app-product-registration',
  templateUrl: './product-registration.component.html',
  styleUrls: ['./product-registration.component.scss']
})
export class ProductRegistrationComponent implements OnInit {
  invoiceFile:File;
  reviewScreenshotFile:File;
  formData = new FormData();  
  isUploadReviewEnable:boolean=false;
  purchaseOptions : Array<SelectItemModel> = new Array<SelectItemModel>()
  productRegistrationForm : UntypedFormGroup;
  maxDate;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public productDetailService: ProductDetailsService,
    public dialogService: LsDialogService<OkayMessageComponent>,
  ) { }

  ngOnInit(): void {

    let selectItem: SelectItemModel = new SelectItemModel()
    selectItem.SelectItem(-1, '--Select--')
    this.purchaseOptions.push(selectItem)

    this.productRegistrationForm = this.formBuilder.group({
      customerName: ["", Validators.required],
      email: ["", [Validators.required,Validators.email]],
      mobileNo: ["", Validators.required],
      productName: ["", Validators.required],
      dateOfPurchase:["", Validators.required],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
      pincode: ["", [Validators.required]],
      itemNo: ["" ],
      purchasedFrom: ["-1", [Validators.required]],
      invoiceFile:['', [Validators.required]],
      reviewScreenshotFile:['']
   
      
    })
    this.populatePurchaseCombo();


    //used to convert the todays date fromat into yyyy-mm-dd
  this.maxDate= new Date();
  this.maxDate=this.maxDate.toISOString().split('T')[0];

  
  }


   /**
   * Fetches departments to populate in departments combo.
   */
  populatePurchaseCombo(){
    this.productDetailService.getPurchaseFrom(data=>{

      // console.log(data,"purchase options");
      
      if(data != null && !data.error && data.data != null){
        let purOption = data.data
        purOption.forEach(element => {
          let selectItem: SelectItemModel = new SelectItemModel()
          selectItem.SelectItem(element, element)
          this.purchaseOptions.push(selectItem)
        });
      }
    })

    // console.log(this.purchaseOptions);
    
  }

  submitProductRegistration(errorLabel){
// console.log(this.productRegistrationForm);

if (this.productRegistrationForm.value.purchasedFrom == "---Select---" || this.productRegistrationForm.value.purchasedFrom == null || this.productRegistrationForm.value.purchasedFrom == undefined) {
  errorLabel.className = "errorLabel show";
  errorLabel.innerHTML = "Please select Purchase From !!";
} else if (this.productRegistrationForm.invalid) {
  errorLabel.className = "errorLabel show";
  errorLabel.innerHTML = "Please Fill All the Mandatory Fields !!";
}  else if (this.productRegistrationForm.valid) {
  let productRegistrationDTO: ProductRegistrationModel = new ProductRegistrationModel();
  
  productRegistrationDTO.customerName = this.productRegistrationForm.value.customerName
  productRegistrationDTO.emailId = this.productRegistrationForm.value.email
  productRegistrationDTO.mobNo = this.productRegistrationForm.value.mobileNo
  productRegistrationDTO.productName = this.productRegistrationForm.value.productName
  productRegistrationDTO.address = this.productRegistrationForm.value.address
  productRegistrationDTO.city = this.productRegistrationForm.value.city
  productRegistrationDTO.pincode = this.productRegistrationForm.value.pincode
  productRegistrationDTO.itemNumber=this.productRegistrationForm.value.itemNo
  productRegistrationDTO.purchaseDate=this.productRegistrationForm.value.dateOfPurchase
  productRegistrationDTO.purchasedFrom=this.productRegistrationForm.value.purchasedFrom

 this.formData.append("attachedInvoice",this.invoiceFile)
 if(this.isUploadReviewEnable){
  this.formData.append("attachedScreenShot",this.reviewScreenshotFile)
 }else{
  this.formData.append("attachedScreenShot","")
 }

  this.formData.append("data", JSON.stringify(productRegistrationDTO))    
  // console.log("servicesuppot", productRegistrationDTO)
  if(this.isUploadReviewEnable){

    if(this.invoiceFile != undefined && this.reviewScreenshotFile != undefined){
      errorLabel.className = "errorLabel hide";
      if(this.invoiceFile.size > 5242880 || this.reviewScreenshotFile.size > 5242880){
        this.dialogService.open(OkayMessageComponent,{},"File size should be less than 5 MB!!").subscribe({
        })
      }else if(!this.isDocType(this.invoiceFile.name,1) || !this.isDocType(this.reviewScreenshotFile.name,2)){
        this.dialogService.open(OkayMessageComponent,{},"Please select File of required Type only!!").subscribe({
        })
      }else{
        this.productDetailService.addProductRegistration(this.formData, resp => {
          if (!resp.error) {
            if (resp != null && resp.msgList != null) {
              if(resp.error){
                this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
              })
              }else{
                this.dialogService.open(ProductRegisteredSuccessfullyComponent,{  height: '320px', width: '258px' },[]).subscribe(response => {
                })
              }
              this.productRegistrationForm.reset()
              this.productRegistrationForm.patchValue({ 'purchaseFrom': -1 });
              this.isUploadReviewEnable=false;
              errorLabel = '';           
            }
          }
        })
      }
    }

  }else{
    if(this.invoiceFile != undefined){
      errorLabel.className = "errorLabel hide";
      
      if(this.invoiceFile.size > 5242880){
        this.dialogService.open(OkayMessageComponent,{},"File size should be less than 5 MB!!").subscribe({
        })
      }else if(!this.isDocType(this.invoiceFile.name,1)){
        this.dialogService.open(OkayMessageComponent,{},"Please select File of required Type only!!").subscribe({
        })
      }
      else{
        this.productDetailService.addProductRegistration(this.formData, resp => {
          if (!resp.error) {
            if (resp != null && resp.msgList != null) {
              this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
              })
              this.productRegistrationForm.reset()
              this.productRegistrationForm.patchValue({ 'purchaseFrom': -1 });
             
              errorLabel = '';           
            }
          }
        })
  
      }
  
    }
  }

 
}

  }

    /**
   * This method gets triggered when a file is selected.
   * File is appneded in formData (for multi-part request).
   * @param event - file selection event
   */
  onFileChange(event, type){
    if(type==1){
      this.invoiceFile=event.target.files[0];
      if(event.target.files.length > 0){
        let fileToUpload = event.target.files[0]
        this.formData = new FormData();
        this.formData.append("file[]", fileToUpload, fileToUpload.name);
      }
      
    }
    else if(type==2){
      this.reviewScreenshotFile=event.target.files[0];
        if(event.target.files.length > 0){
      let fileToUpload = event.target.files[0]
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
    }
    }
  
  }

  onPurchaseFromSelection(value){
 if(value== 'Amazon'||value== 'Flipkart'||value== 'Nykaa'||value== 'Firstcry'){
  //  this.isUploadReviewEnable=true;
   this.isUploadReviewEnable=false; //asked to hide the upload review for all Bug:
 }else{
  this.isUploadReviewEnable=false;
 }
  }

   /**
   * allows to enter numbers only.
   * @param event - keypress event
   */
  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  /**
   * allows to enter characters only.
   * @param event - keypress event
   */
  keyPressOnlyChars(event: any) {
    const pattern = /[a-z\A-Z\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

    /**
   * Checks file-types for resume file-upload.
   * @param fileName 
   */
  isDocType(fileName,type):boolean{
    let extn = fileName.split('.')[1]

    if(type==1){
      if(extn ==  'pdf' || extn == 'jpg'|| extn =='jpeg')
      return true
    else
      return false
    }
    else if(type==2){
      if( extn == 'png'||extn == 'jpg'|| extn =='jpeg')
      return true
    else
      return false
    }
   
  }

}
