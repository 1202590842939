import { Component, OnInit, Inject, PLATFORM_ID , ViewChild, HostListener, OnDestroy} from '@angular/core';
import { isPlatformBrowser } from '@angular/common'; 
import { Router, RouterEvent, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoginComponent } from '../../components/login/login.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { UserService } from '../../../services/user.service'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service'
import { OkayMessageComponent } from '../../../published/commonComponent/okay-message/okay-message.component'
import { filter } from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy {

  ngOnDestroy(){
    
  } 
  title = 'app';
  data;//data of dialog
  value;
  master : string
  routerDiv : string
  headerVisibility:boolean = true
  breadcrumbsVisibility:boolean = true
  isCllctnOrHome:boolean = false
  bannerVisibility:boolean = true
  isnavBarOpened : boolean = false
  isCustCareDialogShow : boolean
  showLnkTopArrow : boolean = false
  googleAnalyticsCode = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleAnalyticsCode : '';
  @ViewChild('accountDashboard',{static: true}) accountDashboard: any;
  catList
  currMap
  whatsappIconPath= '/assets/templates/template_0014/images/Whatsapp-icon.svg';
  
  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private userService : UserService,
    @Inject(PLATFORM_ID) private platformId : Object,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public loaderService: LoaderService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
   }

  ngOnInit() {
    Constants.MENU_BAR_NAV = this.accountDashboard;    
    if(isPlatformBrowser(this.platformId)){
      if(sessionStorage.getItem('custCareDialog') == null ){
        this.showCustCareDialog()
      }
      if(window.pageYOffset > 0){
        this.showLnkTopArrow = true
      }
    }
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      //header and breadcrumbs visibility
      if(data.url == '/' || data.url == '/'+Constants.WAR_NAME){
        this.headerVisibility = true
        this.breadcrumbsVisibility = false
      }else if(data.url.indexOf('checkout') > -1 || data.url.includes('/buying-guide') || data.url.includes('/pos') ){
        this.headerVisibility = false
        this.breadcrumbsVisibility = false        
      }else{
        this.headerVisibility = true   
        this.breadcrumbsVisibility = true     
      }
      //bannerVisibility
      if(data.url == '/' || data.url.includes('/collections') || data.url.includes('/search')){
        this.isCllctnOrHome = true
        this.bannerVisibility = true
      }else{
        this.isCllctnOrHome = false
        this.bannerVisibility = false
      }
      //detail page bg
      if(isPlatformBrowser(this.platformId)){
        this.master = "master";
        if(data.url.includes('details')){
          this.master = "master showBg";
          this.routerDiv = "routerDiv TransparentBg"
        }else{
          this.master = "master";
        }
      }
      //category highlight
      if(!(data.url.includes('collection') || data.url.includes('details') || data.url == '/' || data.url == '/'+Constants.WAR_NAME)){
        // console.log("banner update in master")'
        this.userService.updateCarousel(-1)
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void{
    if(event instanceof NavigationStart){
      this.showLoader();
    }
    if(event instanceof NavigationEnd){
      this.hideLoader();
    }
    if(event instanceof NavigationCancel){
      this.hideLoader();
    }
    if(event instanceof NavigationError){
      this.hideLoader();
    }
  }

  showLoader() {
    this.loaderService.show();
  }

  hideLoader() {
    this.loaderService.hide();
  }

  sideNavChange(event){
    this.isnavBarOpened = event
  }

  showCustCareDialog(){
    if(isPlatformBrowser(this.platformId)){
      this.isCustCareDialogShow = true
    setTimeout(() => {
      this.okayMessageDialogService.open(OkayMessageComponent, {} , 'custCare')
    }, 1000)
    sessionStorage.setItem('custCareDialog', 'true')
    }
  }

  scrollToTop(){
    if(isPlatformBrowser(this.platformId)){
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(isPlatformBrowser(this.platformId)){
      if(window.pageYOffset == 0){
        this.showLnkTopArrow = false
      }else if(window.pageYOffset > 0){ 
        this.showLnkTopArrow = true
      }
    }
  }

  currMapHandler(event){
    this.currMap = event
  }

  catgListHandler(event){
    this.catList = event
  }

  whatsappRedirectClick(){

    let url = 'https://wa.me/919773982065'
    window.open(url, '_blank');
  }

}
