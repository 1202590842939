<!-- <div fxFlexFill [class]="master" [ngClass]="{'overflow-hidden':isnavBarOpened}"> -->
<loader></loader>
<div fxFlexFill [class]="master">
  <app-header (currencyMapEvent)="currMapHandler($event)" (categoryListEvent)="catgListHandler($event)" (sideNavChanged)="sideNavChange($event)" *ngIf="headerVisibility" [ngClass]="{'app-header-banner': isCllctnOrHome, 'app-header-default': !isCllctnOrHome}"></app-header>
  <mat-sidenav-container>
    <mat-sidenav ngClass="menu-width" ngClass.xs="menu-width-xs" #accountDashboard opened="false" mode="over">
      <app-category [currencyMap]="currMap" [categoryList]="catList"></app-category>
    </mat-sidenav>
    <app-banner *ngIf="bannerVisibility"></app-banner>
    <app-breadcrumbs *ngIf="breadcrumbsVisibility"></app-breadcrumbs>
    <div [class]="routerDiv">
      <router-outlet></router-outlet>
    </div>
    <app-social-share *ngIf="headerVisibility"></app-social-share>
    <a class="lnkTopArrow" (click)="scrollToTop()" *ngIf="showLnkTopArrow"></a>
    <app-footer *ngIf="headerVisibility"></app-footer>
    <div class="whatsappRedirectDesktop" (click)="whatsappRedirectClick()" >
      <img class="whatsappIconDesktop" [src]="whatsappIconPath"   alt="">
    </div>
  </mat-sidenav-container>
</div>