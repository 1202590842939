<div class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile"
  [class.newProduct]="ifNew">
  <div class="addWishPanel fa fa-heart-o" (click)="addToWishList()"
    *ngIf="allowWishlist  && prodData?.buyEnquiryMode!==1"></div>
  <div class="imageWrapper" style="position:relative">
    <a [routerLink]="'/'+target" [queryParams]="queryParams" [ngClass]="{'disable':prodData?.outOfStock}"
      (click)="scrollToTop()">
      <img
        [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData?.images[0] : defaultPath "
        class="img-responsive center-position" alt="" (error)="imgErrorHandler($event)">
      <!-- <img [src]="prodData?.imageUrl != undefined?prodData?.imageUrl:defaultPath " class = "img-responsive center-position"  alt="" (error)="imgErrorHandler($event)"> -->
    </a>
    <div *ngIf="prodData?.outOfStock  && prodData?.buyEnquiryMode!==1" class="outOfStock"></div>
  </div>
  <div class="detailDiv">
    <p id="productName"> {{productName}}</p>
    <div class="priceDiv">
      <div class="pnlMrp" *ngIf="prodData?.mrp > prodData?.salePrice && showSaving == 1">
        <p class="label">MRP</p>
        <p class="value">{{prodData?.mrp | currency:currType:'symbol': priceRoundOffInteger}}</p>
      </div>
      <div class="pnlSalePrice">
        <p class="label">MRP</p>
        <p class="value">{{prodData?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
      </div>
    </div>
    <div class="features">
      <p [innerHTML]="data?.desc"></p>
    </div>
    <div class="pnlBuyOptions">
      <div class="tileCheckBox" *ngIf="!specialColltn">
        <input class="tileCheckBox-selector" type="checkbox" [(ngModel)]="checked"
          (click)="compare($event, data?.titleId, data)">
        <label class="tileCheckBox-label">Compare</label>
      </div>
      <div class="addToCartPnl"
        *ngIf="((prodData?.outOfStock == false && allowCart) || (prodData?.outOfStock == true && allowCart && prodData?.acceptOrderOnOutOfStock == 1))  && prodData?.buyEnquiryMode!==1"
        (click)="addToCartProduct()">
        <span></span>
        <p class="cartBuy">Add to Cart</p>
      </div>
    </div>
    <div class="special-lbl" [ngClass]="{'shortWidth': prodData?.offer}" *ngIf="generalSettings?.mrpInclusiveOfTax">(MRP
      is inclusive of all taxes)</div>
  </div>
  <div class="pnlOfferAvailable">
    <div *ngIf="prodData?.offer" class="imgForOffer"></div>
  </div>
  <div class="pnlNewProduct">
    <div *ngIf="ifNew" class="imgForNewPro">NEW</div>
  </div>
</div>