<div class="dealerLocParentPanel">
  <div class="dlOuterPanel">
    <p class="heading">{{heading}}</p>
    <p class="headDesc">{{headingDesc}}</p>
    <form [formGroup]="premiumDealerForm" (ngSubmit)="submitPremiumDealer(errorLabel)">
      <div class="combo">
        <select class="textFields" formControlName="state" (change) = "getCitiesByState($event.target.value)">
          <option *ngFor="let item of stateOptions" [value]="item.value">{{item.label}}</option>                  
        </select>
      </div>
      <div class="combo">
        <select class="textFields" formControlName="city">
          <option *ngFor="let item of cityOptions" [value]="item.value">{{item.label}}</option>                  
        </select>
      </div>
      <button class="button" [disabled]="!premiumDealerForm.valid">Submit</button>
    </form>
  </div>
  <p class="errorLabel" #errorLabel></p>
  <div class="dealerItrPanel" *ngIf="showDealers">
    <ul class="dealerItr">
      <li class="dealerItrLiHeader">
        <p class="dealerNameFixHeader">Dealer Name</p>
        <p class="adrressPanelHeader">Address</p>
        <p class="conDetailsHeader">Contact Details</p>
        <p class="conPersonFixHeader">Contact Person</p>        
      </li>
      <li *ngFor="let dealer of dealers" class="dealerItrLi">
        <div class="dealerNameFix">
          <p class="label-mb">Dealer Name:</p>
          <p class="value-mb">{{dealer.dealerName}}</p>
        </div>
        <div class="adrressPanel">
          <p class="address">{{dealer.dealerAddress}}</p>
          <a class="addressMap" [routerLink]="'/map-dealer'" [queryParams]= "{add: dealer.dealerAddress }"  target="_blank"></a>
        </div>
        <div class="conDetails">
          <p class="label-mb">Ph:</p>
          <p class="value-mb">{{dealer.contactNumber}}</p>
        </div>
        <div class="conPersonFix">
          <p class="label-mb">Contact Person:</p>
          <p class="value-mb">{{dealer.contactPerson}}</p>
        </div>        
      </li>
    </ul>
  </div>
</div>
<app-brands></app-brands>