<div fxLayout="row" class="loader-main" [class.loader-hidden]="!show">
  <div fxLayout="row" fxLayout="center center" class="loader-overlay">
    <div fxLayout="row" fxFlex fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="start center" class="loader-div loader-padding">
        <div class="loader-img">
          <img *ngIf="show" fxFlexAlign="center center" class="loader" [src]="loaderPath">
        </div>
      </div>
    </div>
  </div>
</div>