import { Component, OnInit, ViewChild, PLATFORM_ID, Inject, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
// import { } from 'googlemaps'
import { isPlatformBrowser } from '@angular/common';

declare var google: any
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

/**
 * This component displays the location of premium-dealer's store in google-map using geo-code.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-map-dealer',
  templateUrl: './map-dealer.component.html',
  styleUrls: ['./map-dealer.component.scss']
})
export class MapDealerComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  dealerAdd: string

  @ViewChild('errorLabel' , {static : true}) errorLabel: any;

  /**
   * @constructor
   * @param router 
   * @param activatedRoute 
   * @param platformId 
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  /**
   * Fetch geo-code of premium-dealer' store's location.
   * Initialize google-map.
   */
  ngOnInit() {
    this.getDealerAdd()
    this.initMap()
  }

  /**
   * Fetches geo-code from current route's query-parameter.
   */
  getDealerAdd() {
    this.dealerAdd = this.activatedRoute.snapshot.queryParamMap.get('add') + ''
    // console.log("address",this.dealerAdd)
  }

  /**
   * initializes google-map with initial-settings.
   */
  initMap() {
    if (isPlatformBrowser(this.platformId)) {
      let geocoder = new google.maps.Geocoder()
      let latlng = new google.maps.LatLng(-34.397, 150.644)
      let mapOptions = {
        zoom: 16,
        center: latlng
      }
      let map = new google.maps.Map(document.getElementById('map'), mapOptions)
      this.codeAddress(geocoder, map)
    }
  }

  /**
   * Uses geo-code to display its location in google-map.
   * @param geocoder
   * @param map - initialized google-map's instance
   */
  codeAddress(geocoder, map) {
    geocoder.geocode({ 'address': this.dealerAdd }, function (results, status) {
      if (status == 'OK') {
        if (map != undefined) {
          map.setCenter(results[0].geometry.location)
          // console.log("location set")
          let marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location
          })
        }
      } else {
        console.log("Geocode was not successful for the following reason:")
      }
    })
  }

}
