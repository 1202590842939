import { Component, OnInit, OnDestroy } from '@angular/core'
import { HomePageService } from '../../../services/home-page.service'
import { BrandModel } from '../../../common/models/brandModel'
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  parentCompanyName = 'TAURUS WORLDWIDE'
  brands: Array<BrandModel> = []
  defaultPath = TemplateConstants.templateAssetsPath + '/images/default/defaultCategory.png';

  constructor(
    private homePageService: HomePageService,
  ) { }

  ngOnInit() {
    this.homePageService.fetchAllBrands( data => {
      if(data != undefined && data.data != undefined){
        this.brands = data.data
      }
    })
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
