import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackOrderService } from '../../../services/track-order.service';
import { OrderModel } from '../../../common/models/orderModel';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { QueryComponent } from '../../../published/commonComponent/query/query.component';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  myOrders: Array<OrderModel> = []
  order : OrderModel
  const = Constants  
  showLeft : boolean;
  showRight : boolean;
  showDetails = [];
  currType:string = "INR";
  noOrdersFlag : boolean =false;
  priceRoundOffInteger: string = '1.2'  
  
  constructor(
    public activatedRoute: ActivatedRoute,
    private trackOrderService: TrackOrderService,
    private queryPopUpService : LsDialogService<QueryComponent>,
    
  ) { }

  ngOnInit() {
    // let email = this.activatedRoute.snapshot.queryParamMap.get("email").toString();
    // let orderId = this.activatedRoute.snapshot.queryParamMap.get('orderId').toString();
    // console.log(email);
    // console.log(orderId);
    // this.trackOrderService.trackOrder(email, orderId, resp => {
    //   console.log(resp);
    // })
    this.getOrderDetails();
    this.showLeft = true
    if(Constants.ONLINE_STORE_SETTING_MODEL != undefined){
      if(Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined){
        if(Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1){
          this.priceRoundOffInteger = '1.0'
        }else if(Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0){
          this.priceRoundOffInteger = '1.2'            
        }
      }
    }
  }

  toggleShowDetails(index){
    this.showDetails[index] = !this.showDetails[index];
  }

  getOrderDetails(){
    let email = this.activatedRoute.snapshot.queryParamMap.get("email").toString();
    let orderId = this.activatedRoute.snapshot.queryParamMap.get('orderId').toString();
    this.trackOrderService.trackOrderUsingOrderId(email, orderId ,(data)=>{
      if(data != undefined && !data.error && data.data != undefined){
        this.order = data.data[0];
        // console.log("Order Status : ", this.order.orderStatus);
        // this.initializeFlagArray();            
      }else if(data != undefined && data.error){
        this.noOrdersFlag = true;
      }
    })
  }

  leftClick(){
    this.showLeft = true
    this.showRight = false;
  }

  rightClick(){
    this.showRight = true;
    this.showLeft = false;
  }

  query(orderId, productModel){
    let data = {
      orderId: orderId,
      productModel: productModel
    }
    this.queryPopUpService.open(QueryComponent, {}, data ).subscribe(response => {
    });
  }
}
