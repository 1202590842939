<div *ngIf="socialSettings != undefined" class="social-side-panel">
  <div class="itrSocialLnks">
    <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook social" title="Facebook" ><span></span></a>
    <a *ngIf="socialSettings.instagramPageURL!= ''" href={{socialSettings.instagramPageURL}} class="instagram social" title="Instagram" ><span></span></a>          
    <a *ngIf="socialSettings.pinterestPageURL!= ''" href={{socialSettings.pinterestPageURL}} class="pinterest social" title="Pinterest" ><span></span></a>          
    <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus social" title="gPlus" ><span></span></a>
    <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin social" title="LinkedIn"><span></span></a>
    <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter social" title="Twitter"><span></span></a>
    <a *ngIf="socialSettings.youtubePageURL!= ''" href={{socialSettings.youtubePageURL}} class="youtube social" title="Youtube"><span></span></a>    
</div>
</div>