import { Component, OnInit, OnDestroy } from '@angular/core'
import { MediaGalleryScreen } from '../../../common/models/mediaGalleryScreen'
import { HomePageService } from '../../../services/home-page.service'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { MediaGalleryDialogComponent } from './media-gallery-dialog/media-gallery-dialog.component'
import { SelectItemModel } from '../../../common/models/select-item.model'
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

/**
 * This component displays media-gallery for a store. It displays all images and videos in different tabs.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  tabs: Array<String> = ['PHOTOS', 'VIDEOS']
  selectedTabIndex: number = 0
  mediaImages: Array<MediaGalleryScreen> = []
  mediaVideos: Array<MediaGalleryScreen> = []
  imagesPageNum = 1
  imagesNumRec = 8
  imageHasMore: boolean = false
  imageErrorPage: boolean = false
  videoPageNum = 1
  videoNumRec = 8
  videoHasMore: boolean = false
  videoErrorPage: boolean = false
  yearOptions : Array<SelectItemModel> = new Array<SelectItemModel>()  
  currYear = null;
  defaultImgPath = TemplateConstants.templateAssetsPath + '/images/default/noImage.jpg';

  /**
   * @constructor
   * @param homePageService 
   * @param mediaGalleryDialog 
   */
  constructor(
    private homePageService: HomePageService,
    public mediaGalleryDialog: LsDialogService<MediaGalleryDialogComponent>,
  ) { }

  /**
   * Prepare a combo 
   */
  ngOnInit() {
    let selectItem : SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1,'Select Year')
    this.yearOptions.push(selectItem)
    this.getMediaImages(this.imagesPageNum, this.currYear)
    this.getMediaVideos(this.videoPageNum, null)
  }

  tabClicked(i) {
    this.selectedTabIndex = i
  }

  getMediaImages(pageNum, currYear) {
    this.homePageService.getMediaGallery(Constants.RESOURCE_TYPE.IMAGES, Constants.RESOURCE_CATEGORY.SHOW_ON_STORE, pageNum, this.imagesNumRec, currYear, (resp) => {
      if (resp != null && !resp.error) {
        if (resp.data != null && resp.data.data != null) {
          let images = resp.data.data
          if(this.currYear != null && pageNum ==1){
            this.mediaImages = []
          }
          images.forEach(element => {
            this.mediaImages.push(element)
          })
        } else {
          this.imageErrorPage = true
        }
        if (resp.data != null && resp.data.hasMore != null) {
          this.imageHasMore = resp.data.hasMore
        }
        if(resp.data != null && resp.data.list != null && this.yearOptions.length <= 1){
          let years = resp.data.list;
          Object.keys(years).forEach(ele => {
            let selectItem : SelectItemModel = new SelectItemModel()  
            selectItem.SelectItem(years[ele], years[ele])    
            this.yearOptions.push(selectItem);
          })
        }
      } else {
        this.imageErrorPage = true
      }
    })
  }

  getMediaImagesForYear(year){
    this.imagesPageNum = 1
    this.currYear = year
    this.getMediaImages(this.imagesPageNum, this.currYear)
  }

  getMediaVideos(pageNum, currYear) {
    this.homePageService.getMediaGallery(Constants.RESOURCE_TYPE.VIDEOS, Constants.RESOURCE_CATEGORY.SHOW_ON_STORE, pageNum, this.videoNumRec, currYear, (resp) => {
      if (resp != null && !resp.error) {
        if (resp.data != null && resp.data.data != null) {
          let videos = resp.data.data
          videos.forEach(element => {
            this.mediaVideos.push(element)
          })
        } else {
          this.videoErrorPage = true
        }
        if (resp.data != null && resp.data.hasMore != null) {
          this.videoHasMore = resp.data.hasMore
        }
      } else {
        this.videoErrorPage = true
      }
    })
  }

  imageShowMoreMedia() {
    this.getMediaImages(++this.imagesPageNum, this.currYear)
  }

  videoShowMoreMedia() {
    this.getMediaVideos(++this.videoPageNum, null)
  }

  showMediaDialog(clickedImage) {
    let data = {
      clickedImage: clickedImage,
      images: this.mediaImages
    }
    this.mediaGalleryDialog.open(MediaGalleryDialogComponent, {}, data).subscribe(response => {
    });
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultImgPath;
  }

}
