<div fxLayout="column" class="mainContainer">
  <div fxLayout="row" fxLayoutAlign="center center" class="header">
    <p class="heading">{{lblReturn}}</p>
    <span class="close fa fa-close" (click)="closeDialog()"></span>
  </div>
  <div class="returnForm">
    <form role="form" [formGroup]="returnForm" (ngSubmit)="returnFormSubmit($event, errorLabel)">
      <div class="returnPopupBody">
        <div class="errorPnl">
          <p class="errorLabel" #errorLabel></p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="returnPnl">
          <p class="label">{{lblForReturnType}}</p>
          <div class="value">
            <select formControlName="returnType" name="returnTypeCombo" class="textFields" (change)="onChangeReturnType($event.target.value)">
                <option *ngFor="let type of returnTypeList" [value]="type.value">{{type.label}}</option>                  
            </select>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="reasonPnl">
          <p class="label">{{lblForReason}}</p>
          <div class="value">
            <select formControlName="reason" name="reasonCombo" class="textFields">
                  <option *ngFor="let reason of reasonList" [value]="reason.value">{{reason.label}}</option>                  
              </select>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="descPnl">
          <p class="label">{{lblForDesc}}</p>
          <div class="value">
            <textarea formControlName="desc" name="desc"></textarea>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="mandatoryFieldForExchange" *ngIf="showOtherVariantsAvailable">
          <p class="label">{{lblForSelectVariant}}</p>
          <div fxLayout="column" class="value">
            <div class="pnlFilterItrOuter">
              <ul class="filterItr">
                <li class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
                  <div class="pnlMandtryFieldInner">
                    <p class="variantDesc">{{variantType.variantHeading}}</p>
                    <ul class="variantItr">
                      <li  class="variantItrLi" [ngClass]="{'filterSelected' : selectionArray && (optionIdx == selectionArray[variantTypeIdx]) }"
                        *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="selectedVariant($event, variantTypeIdx, optionIdx, variant.mappedUniqueIds)">
                        <p class="filter">{{variant.option}}</p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <p class="lblErrorMandtryField">**Select a variant</p>
          </div>
        </div>
        <div class="returnPopupAddPnl">
          <div fxLayout="row" fxLayoutAlign.gt-xs="center center" class="pickUpAddr">
            <p class="label">{{lblForPickup}}</p>
            <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" class="value">
              <div class="dateSelector">
                <ls-date-picker [touch-ui]="false" formControlName="pickupDate" [(value)]="pickupDate" (keypress)="keyPressDOB($event)"></ls-date-picker>
              </div>
              <p class="addrSmallLabel">at</p>
              <div class="timeSelector">
                <ls-text-field (change)="validateTimeField()" type="time" formControlName="pickupTime" ></ls-text-field>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" class="exchangeDeliveryAddress">
            <div class="label">
              <p>From</p>
            </div>
            <div fxLayout="column" class="value">
              <div class="showHideContainer" *ngIf="showDeliveryAddress">
                <div fxLayout="column" class="defaultAddrDiv">
                  <div fxLayout="row" class="selectDefault">
                    <input type="checkbox" name="defaultAddr" [checked]="isDefaultAddress" (change)="addNewAddress($event)" formControlName="defaultAddr"> 
                    <p>Delivery Address</p>
                  </div>
                  <div fxLayout="column" class="deliveryAddress">
                    <p class="lblNameShippingAdd"></p>
                    <p class="lblNumShippingAdd">{{dataList.order.phone}}</p>
                    <p class="lblShippingAddLine1">{{dataList.order.address1}}&nbsp;{{dataList.order.address2}}</p>
                    <p class="lblShippingAddLine2">{{dataList.order.city}}-{{dataList.order.pinCode}}</p>
                  </div>
                </div>
                <div class="addNewAddPnl">
                  <a (click)="hideDeliveryAddress()">Add new address</a>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" class="exchangeNewAddress" *ngIf="!showDeliveryAddress">
            <div fxLayout="row" class="exchangeNewAddrFormDiv">
              <div fxLayout="column" class="leftAddrContainer">
                <div class="newAddrName">
                  <input placeholder="Name" formControlName="addrName" maxlength="100">
                </div>
                <div class="newAddrLine1 top-padding">
                  <textarea placeholder="Address(Line1)" formControlName="addrLine1"></textarea>
                </div>
                <div class="newAddrLine1 top-padding">
                  <textarea placeholder="Address(Line2)" formControlName="addrLine2"></textarea>
                </div>
              </div>
              <div fxLayout="column" class="rightAddrContainer">
                <div class="newAddrPin">
                  <input placeholder="Pincode" formControlName="pinCode" (keypress)="keyPress($event)" maxlength="6" type="number" pattern="[0-9]*">
                </div>
                <div class="newAddrState top-padding">
                  <select formControlName="state" name="stateCombo">
                      <option *ngFor="let state of stateList" [value]="state.value">{{state.label}}</option>                  
                  </select>
                </div>
                <div class="newAddrCity top-padding">
                  <input placeholder="City" formControlName="city" maxlength="100">
                </div>
                <div class="newAddrPhone top-padding">
                  <input placeholder="Phone Number" formControlName="tellNo" (keypress)="keyPress($event)" maxlength="10" type="number" pattern="[0-9]*">
                </div>
              </div>
            </div>
            <div class="selectDelAddPnl">
              <a (click)="_showDeliveryAddress()">Select Delivery Address</a>
            </div>
          </div>
        </div>
        <div fxLayout="column" class="bankDetailsPnl" *ngIf="showBankForm">
          <p class="label">{{bankdtlsLbl}}</p>
          <div fxLayout="row" class="bankdtlsDiv">
            <div fxLayout="column" class="bankLeftPnl">
              <div class="bnkPerName">
                <input placeholder="Name as per your Bank record" formControlName="nameAsPerBank" maxlength="100">
              </div>
              <div class="bnkAccNo top-padding">
                <input placeholder="Bank Account Number" formControlName="bankAccountNo" (keypress)="keyPress($event)" type="number" pattern="[0-9]*">
              </div>
            </div>
            <div fxLayout="column" class="bankRightPnl">
              <div class="bankName">
                <input placeholder="Name of the Bank" formControlName="bankName" maxlength="100">
              </div>
              <div class="bnkIfsc top-padding">
                <input placeholder="IFSC Code" formControlName="ifscCode" maxlength="30">
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="btnPnl">
          <button class="returnBtn" type="submit">Return</button>
          <!-- <button class="cancelBtn" type="clear" (click)="closeDialog()">Cancel</button> -->
          <div class="cancelBtn" (click)="closeDialog()">
            <a>Cancel</a>
          </div>
        </div>
        <div class="retAndRefPolicyPnl">
          <a (click)="goToReturnAndRefund()" target="_blank">{{retAndRefLbl}}</a>
        </div>
      </div>
    </form>
  </div>
</div>