export class ProductRegistrationModel {
    customerName:string;            // Customer Name - Mandatory(100)
    emailId:string;                 // Email Id - Mandatory(255)
    mobNo:string;                   // Mob No - Mandatory(15)
    productName:string;             // Product Name - Mandatory(100)
    purchaseDate:Date;              // Purchase Date - Mandatory(10)
    city:string;                    // City - Mandatory(65)
    pincode:string;                 // Pincode - Mandatory(10)
    address:string;                 // Address - Mandatory(500)
    itemNumber:string;              // Item Number - Mandatory(60)
    purchasedFrom:string;           // Purchased From - Mandatory(50)
    
}