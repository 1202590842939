import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CategoryService } from '../../../services/category.service';
import { CategoryModel } from '../../../common/models/category-model';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { HomePageService } from '../../../services/home-page.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { HttpService } from 'src/app/services/http.service';

/**
 * This component fetches buying-guide attachment for a category and displays the content.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-buying-guide',
  templateUrl: './buying-guide.component.html',
  styleUrls: ['./buying-guide.component.scss']
})
export class BuyingGuideComponent implements OnInit, OnDestroy {

  data
  url: string = ''
  buyingGuideUrl: string = ''
  _catSubscription;

  /**
   * @constructor
   * @param categoryService 
   * @param router 
   * @param httpService 
   * @param homePageService 
   */
  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private httpService: HttpService,
    private homePageService: HomePageService
  ) { }

  /**
   * Category-url is present in current-route for which buying-guide is to be displayed.
   * Fetch current-route and get category-url by splitting route.
   * Then, filter-out category's attachment from categories data.
   */
  ngOnInit() {
    this.url = this.router.url.split("buying-guide/")[1].split("?")[0];
    this._catSubscription = this.homePageService.childCategoriesObservable.subscribe((data) => {
      if (data != undefined) {
        let CHILD_CATEGORY_MAP: Array<CategoryModel> = data;
        for (var index = 0; index < CHILD_CATEGORY_MAP.length; index++) {
          var element = CHILD_CATEGORY_MAP[index];
          if (element.categoryURL == this.url) {
            if (element.attachments != null && element.attachments != '') {
              this.buyingGuideUrl = element.attachments;
              this.getData();
              break;
            }
          }
        }
      }
    });
  }

  /**
   * unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
  }

  /**
   * Fetches file's content from CDN using file-url, and
   * displays file's content.
   */
  getData() {
    if (this.buyingGuideUrl != undefined && this.buyingGuideUrl != "") {
      this.httpService.getCDNContent(this.buyingGuideUrl, (data) => {
        if (data != undefined && data != "")
          this.data = data;
      });
    }
  }

}
