import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { HomePageService } from '../../../services/home-page.service';
import { CategoryService } from '../../../services/category.service';
import { SocialSettingsModel } from '../../../common/models/social-settings-model'
import { CategoryModel } from '../../../common/models/category-model';
import { StaticPageModel } from '../../../common/models/static-page-model'
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  socialSettings: SocialSettingsModel
  showNewsletter: boolean
  categoryList: Array<CategoryModel>
  data: Array<StaticPageModel> = []
  quickLinks: Array<StaticPageModel> = []
  footerDummyLinks: Array<StaticPageModel>
  fbWidgetSrc: string
  copyRTxt: string
  httpUrl: string
  loggedInUser: boolean = false;
  checkOutVisibility;
  _quickLinksSubsciption;
  _catSubscription;
  companyImgPath = TemplateConstants.templateAssetsPath + "/images/tarus.png";

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public homePageService: HomePageService,
    private sanitizer: DomSanitizer,
    public dialogService: LsDialogService<OkayMessageComponent>,
    public location: Location
  ) { }

  ngOnInit() {
    this.checkOutVisibility = this.location.path().includes('/checkout')
    this.getSocialLinks();
    this.getCategories();
    if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO.allowSubscription) {
      this.showNewsletter = true;
    } else {
      this.showNewsletter = false;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser') != null) {
        this.loggedInUser = true;
      }
    }
    this.getQuickLinks();
    this.copyRTxt = Constants.VENDOR_DETAILS.storeCopyrightText;
  }

  ngOnDestroy() {
    if (this._quickLinksSubsciption) {
      this._quickLinksSubsciption.unsubscribe();
    }
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
  }

  getSocialLinks() {
    this.socialSettings = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO;
    if (this.socialSettings) {
      if (this.socialSettings.fbClientKey != "" && this.socialSettings.fbClientSecret != "") {
        this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + this.socialSettings.fbClientKey
          + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + this.socialSettings.fbClientSecret;
      }
    } else {
      this.homePageService.getStoreSettings("social", resp => {
        this.socialSettings = resp.data;
        if (resp.data.fbClientKey != "" && resp.data.fbClientSecret != "") {
          this.fbWidgetSrc = "https://www.facebook.com/plugins/likebox.php?href=https://www.facebook.com/" + resp.data.fbClientKey
            + "&width=260&height=280&colorscheme=light&show_faces=false&header=true&hide_cover=true&stream=true&show_border=true&appId=" + resp.data.fbClientSecret;
        }
      })
    }
  }


  getCategories() {
    this._catSubscription = this.homePageService.parentCategoriesObservable.subscribe((data) => {
      this.categoryList = data;
    })
  }

  getQuickLinks() {
    this._quickLinksSubsciption = this.homePageService.quicLinksObservable.subscribe((data) => {
      // let quickLinks = data
      if (data != undefined) {
        this.footerDummyLinks = this.setFooterDummyData();
        let flagForCareer: boolean = false
        data.forEach((model, index) => {
          if (model.pageURL.includes("http") || model.pageURL.includes("https")) {
            this.httpUrl = model.pageURL;
          }
          if (model.linkName.includes('Feedback')) {
            this.footerDummyLinks.push(model);
          }
          if (model.linkName.includes('Lifetime')) {
            this.footerDummyLinks.push(model);
          }
          if (model.linkName.includes('Service')) {
            this.footerDummyLinks.push(model);
          }
          if(model.linkName.includes('Out of Warranty')){
            this.footerDummyLinks.push(model);
          }
          if(model.linkName.includes('Recipes')){
            this.footerDummyLinks.push(model);
          }
        })
        this.quickLinks = this.footerDummyLinks
      } else {
      }
    })
  }

  setFooterDummyData() {
    let footerDummyLinks = new Array<StaticPageModel>();
    let model = new StaticPageModel();
    model.setData("", "Home");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("media-gallery", "Media Gallery");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("terms-of-use", "Terms of Use");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("about-us", "About Us");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("contact-us", "Contact Us");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("privacy-policy", "Privacy Policy");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("premium-dealer", "Premium Dealer");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("career", "Career");
    footerDummyLinks.push(model);
    model = new StaticPageModel();
    model.setData("product-registration", "Product Registration");
    footerDummyLinks.push(model);
    return footerDummyLinks;
  }

  subscribeNewsletter(event, error, input) {
    if(isPlatformBrowser(this.platformId)){
      let email = input.value;
      if (event.key == "Enter") {
        if (email == "" || !this.validateEmail(email)) {
          error.className = "show errorLbl"
          error.innerHTML = "Invalid Email!!";
          input.className = "tfSubscriptionEmail error-tag";
          error.style.visibility = "visible";
          setTimeout(function () {
            error.style.visibility = "hidden";
          }, 300);
        } else {
          error.className = "hide errorLbl";
          error.innerHTML = "";
          input.className = "tfSubscriptionEmail";
          this.homePageService.subscribeNewsletter(input.value, (resp) => {
            this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
              input.value = "";
              setTimeout(function () {
                error.style.visibility = "hidden";
              }, 300);
            })
          })
        }
      }
    }
  }

  validateEmail(email) {
    var at = email.indexOf("@");
    var dot = email.lastIndexOf(".");
    return !(at < 1 || at + 2 > dot || dot + 2 > email.length);
  }

}
