import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { ContactService } from '../../../services/contact.service'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service'
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component'
import { HttpService } from '../../../services/http.service'
import { HomePageService } from '../../../services/home-page.service'
import { StaticPageModel } from '../../../common/models/static-page-model'
import { ContactQueryModel } from '../../../common/models/contact-us-query-model'
import { Location } from '@angular/common';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { HttpClient } from '@angular/common/http';
import * as FormData from 'form-data';

/**
 * This component contains contact-us form. Form contains fields; name, email, phone-number, location, pincode, message.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  formData = new FormData();
  contactUsForm: UntypedFormGroup;
  contactUsLink: StaticPageModel;
  errorFlag: boolean = true;
  content: string;
  img404Src: string;
  _homePageSubscription;
  defaultPath = TemplateConstants.templateAssetsPath + 'images/errorPages/404_pageNotFound.jpg';

  /**
   * @constructor
   * @param contactService 
   * @param formBuilder 
   * @param dialogService 
   * @param httpService 
   * @param http 
   * @param homePageService 
   * @param location 
   */
  constructor(
    private contactService: ContactService,
    private formBuilder: UntypedFormBuilder,
    private dialogService: LsDialogService<OkayMessageComponent>,
    private httpService: HttpService,
    private http: HttpClient,
    private homePageService: HomePageService,
    public location: Location
  ) { }

  /**
   * Prepares form-group for contact-us form.
   * Fetches static-page content for 'contact-us' page and displays it in the component.
   */
  ngOnInit() {
    this.contactUsForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.email, Validators.required]],
      phoneNumber: ["", Validators.required],
      cin: [""],
      location: ["", Validators.required],
      pincode: ["", Validators.required],
      message: ["", Validators.required],
    })
    this._homePageSubscription = this.homePageService.quicLinksObservable.subscribe((links) => {
      let quickLinks = links
      quickLinks.forEach(item => {
        if (this.location.path() === ("/" + item.pageURL)) {
          let cdnPath = item.linkPath;
          this.errorFlag = false;
          if (cdnPath != undefined && cdnPath != "") {
            this.httpService.getCDNContent(cdnPath, (data) => {
              if (data != undefined && data != "")
                this.content = data;
            });
          }
        }
      })
      if (this.errorFlag) {
        this.img404Src = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400
      }
    })
  }

  /**
   * Unsubscribe all subscriptions when component destroys.
   */
  ngOnDestroy() {
    if (this._homePageSubscription) {
      this._homePageSubscription.unsubscribe();
    }
  }

  /**
   * allows enter numbers only.
   * @param event - keypress event
   */
  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault()
    }
  }

  /**
   * This method submits the contact-us form request.
   * It first prepares queryModel and assigns form values to it and sends the request.
   * After successful submission and response, display the success message and reset the form.
   */
  submitContactUs() {
    let queryModel: ContactQueryModel = new ContactQueryModel();
    queryModel.name = this.contactUsForm.value.name
    queryModel.email = this.contactUsForm.value.email
    queryModel.mobileNo = this.contactUsForm.value.phoneNumber
    queryModel.address = this.contactUsForm.value.location
    queryModel.pincode = this.contactUsForm.value.pincode
    queryModel.desc = this.contactUsForm.value.message
    queryModel.addlData = this.contactUsForm.value.cin
    queryModel.queryType = 2
    this.formData.append("data", JSON.stringify(queryModel))
    this.contactService.sendContactQuery(this.formData, resp => {
      if (resp != null && !resp.error) {
        if (resp.msgList != null) {
          this.contactUsForm.reset()
          this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          })
        }
      }
    })
  }

  /**
   * set default image on image error event.
   * @param event - image error event
   */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
//Your query is received, we will get back to you shortly