import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { ProductModel } from '../../../common/models/productModel'
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

/**
 * This component compares two/ max-three products and displays the differences in table-form. 
 * Products of same category only can be compared.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  currType: string = "INR"
  for: string = ''
  catHdng: string = ''
  t1: number
  t2: number
  t3: number
  titleIds: Array<number> = []
  exportPdfs: boolean
  products: Array<ProductModel> = []
  compareHdngs: Array<string> = []
  featureArray: Array<{ arr: Array<{ name: string, specialData: string, details: Array<{ key: string, value: string }> }> }> = []
  specsArray: Array<{ hdr: string, childHdrs: Array<string> }> = []
  priceRoundOffInteger: string = '1.2'
  defaultPath = TemplateConstants.defaultImgPath;

  /**
   * @constructor
   * @param platformId 
   * @param router 
   * @param activatedRoute 
   * @param categoryListPageService 
   * @param okayMessageDialogService 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryListPageService: CategoryListPageService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>
  ) { }

  /**
   * Fetch category-name and title-Ids of products which are to be compared from route's query-parameters.
   */
  ngOnInit() {
    this.initializeVars()
    if (this.activatedRoute.snapshot.queryParamMap.get("for") != null && this.activatedRoute.snapshot.queryParamMap.get("for") != '') {
      this.for = this.activatedRoute.snapshot.queryParamMap.get("for")
      this.catHdng = this.for
      if (this.for.includes('-')) {
        this.catHdng = this.for.split("-").join(" ")
      }
    }
    if (this.activatedRoute.snapshot.queryParamMap.get("t1")) {
      this.t1 = parseInt(this.activatedRoute.snapshot.queryParamMap.get("t1"))
    }
    if (this.activatedRoute.snapshot.queryParamMap.get("t2")) {
      this.t2 = parseInt(this.activatedRoute.snapshot.queryParamMap.get("t2"))
    }
    if (this.activatedRoute.snapshot.queryParamMap.get("t3")) {
      this.t3 = parseInt(this.activatedRoute.snapshot.queryParamMap.get("t3"))
    }
    if (this.t1 && this.t2 && !this.t3) {
      this.titleIds.push(this.t1)
      this.titleIds.push(this.t2)
    } else if (this.t1 && this.t2 && this.t3) {
      this.titleIds.push(this.t1)
      this.titleIds.push(this.t2)
      this.titleIds.push(this.t3)
    }
    this.exportPdfs = false
    this.printCompareProducts(this.exportPdfs)
    if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
      if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
          this.priceRoundOffInteger = '1.0'
        } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
          this.priceRoundOffInteger = '1.2'
        }
      }
    }
  }

  /**
   * Prepares initial headings for comparison and fetches currency-type.
   */
  initializeVars() {
    this.compareHdngs = ['Model Name', 'Price', 'Features']
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
    }
  }

  base64URL;
  
  /**
   * This method fetches product-details of products using title-Ids for prepapring comparison-table.
   * Fetches attributes of each product and iterate them to segregate headings and their values from attribue-map and pushes into feature-array.
   * @param exportPdfs - 'true' is pdf is to be exported else 'false'
   */
  printCompareProducts(exportPdfs) {
    if (exportPdfs) {
      this.categoryListPageService.printCompareProducts(this.titleIds, exportPdfs, (data) => {
        if (!data.error) {
          if (data.data && isPlatformBrowser(this.platformId)) {
            let response = data.data;
            window.open(response, "_blank");
          }
        }
      })
    } else {
      this.categoryListPageService.printCompareProducts(this.titleIds, exportPdfs, (data) => {
        if (!data.error) {
          if (data != null && data.data != null && data.data != undefined && data.data.length != 0) {
            this.products = data.data
            for (var index = 0; index < this.products.length; index++) {
              var product: ProductModel = this.products[index];
              if (product.attributes) {
                let tempArray: Object = product.attributes;
                let featArray2: Array<{ name: string, specialData: string, details: Array<{ key: string, value: string }> }> = []
                Object.keys(tempArray).forEach(key => {
                  if (typeof (tempArray[key]) == 'object') {
                    let value = tempArray[key]
                    let featArray: Array<{ key: string, value: string }> = []
                    Object.keys(value).forEach(element => {
                      featArray.push({
                        key: element,
                        value: value[element]
                      })
                    })
                    if (featArray.length != 0) {
                      featArray2.push({
                        name: key,
                        details: featArray,
                        specialData: null
                      })
                    }
                  } else if (typeof (tempArray[key]) == 'string') {
                    featArray2.push({
                      name: key,
                      specialData: tempArray[key],
                      details: null
                    })
                  }
                });
                this.featureArray.push({
                  arr: featArray2
                })
              }
            }
            this.changeFeatureArray()
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, data.msgList[0]).subscribe(response => {
          })
        }
      })
    }
    // console.log("feature array", this.featureArray)
  }

  /**
   * This method prepares parent headings for comparison and their values for each products in  childHdrs in specsArray.
   */
  changeFeatureArray() {
    if (this.featureArray.length > 0) {
      this.featureArray[0].arr.forEach(ele => {
        let childHrds: Array<string> = []
        if (ele.details != null) {
          ele.details.forEach(childEle => {
            childHrds.push(childEle.key)
          })
        }
        /* hide special data */
        if (ele.specialData == null || ele.specialData == undefined) {
          this.specsArray.push({
            hdr: ele.name,
            childHdrs: childHrds
          })
        }
      })
    }
  }

  /**
   * set default image on image error event.
   * @param event - image error event
   */
  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
