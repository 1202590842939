<div class="partners">
  <div class="heading">
    <p class="heading-partner">Partners in Prosperity</p>
  </div>
  <p class="partner-subheading"> Join us in our mission to Protect the Environment by selling our products at very attractive terms</p>
  <div class="form-class">
    <p class="error-label" #errorTag></p>
    <form enctype="multipart/form-data" [formGroup]="partnersForm" (ngSubmit)="submitPartners(errorTag)">
      <input type="text" placeholder="Your Name" class="text-field" formControlName="name" maxlength="60">
      <input type="email" placeholder="Your Email-id" class="text-field" formControlName="email" maxlength="255">
      <textarea placeholder="Remarks" class="text-field" formControlName="message"></textarea>
      <div class="upload-file">
        <input type="file" class="file-upload" formControlName="file" (change)="onFileChange($event)">
      </div>
      <button class="submit-form">Submit</button>
    </form>
  </div>
</div>