import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SuggestionOption } from '../../../LSNG/components/ls-suggestion-field/suggestion-option';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-test-tag',
  template: `
    <span style="background-color: gray; color: white">{{label}}</span>
  `,
  styleUrls: ['./search.component.scss']
})
export class SearchTagComponent implements OnInit, SuggestionOption , OnDestroy {

  ngOnDestroy(){
    
  }
  built  = false;
  label = "";

  json : any;

  constructor(private _cdr : ChangeDetectorRef) { }

  ngOnInit() {
  }

  buildComponent(json : any) : void {
    this.built = true;
    this.label = json.label;
    this._cdr.detectChanges();
  }

}
