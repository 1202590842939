import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Router } from '@angular/router'
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit, OnDestroy {

  loggedIn: boolean = false
  msg;
  dialog: LsMdDialog<LoginPopupComponent>
  dataList;
  _userSubscription;

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.msg = this.dataList
    this._userSubscription = this.userService.userStateModel.subscribe((userModel) => {
      if (Object.keys(userModel).length != 0) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    })
  }

  ngOnDestroy() {
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
  }

  loginClicked() {
    this.dialog.close("login");
    this.router.navigate(['login'])
  }

  registerClicked() {
    this.dialog.close("register");
    this.router.navigate(['login'], { queryParams: { action: 'register' } })
  }

}
