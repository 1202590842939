import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants'
import { SelectItemModel } from '../../../common/models/select-item.model'
import { GetUserInfoService } from '../../../services/getUserInfo.service'
import { CategoryService } from '../../../services/category.service'
import { ContactService } from '../../../services/contact.service'
import { CategoryModel } from '../../../common/models/category-model'
import { ServiceSupportDTO } from '../../../common/models/serviceSupportDTO'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { HomePageService } from '../../../services/home-page.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

/**
 * This component contains the form for submitting request for service-support. Form has fields; kind of request (demo/service request), product, category, name, contact, address, pin-code, complaint.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-service-support',
  templateUrl: './service-support.component.html',
  styleUrls: ['./service-support.component.scss']
})
export class ServiceSupportComponent implements OnInit, OnDestroy {

  heading = "SERVICE SUPPORT"
  productOptions: Array<SelectItemModel> = new Array<SelectItemModel>()
  modelOptions: Array<SelectItemModel> = new Array<SelectItemModel>()
  stateOptions: Array<SelectItemModel> = new Array<SelectItemModel>()
  kindOfRadioOptions = [
    { value: Constants.SERVICE_SUPPORT_KIND_OF_OPTIONS.DEMO_REQUEST + '', label: 'Demo Request', disabled: false },
    { value: Constants.SERVICE_SUPPORT_KIND_OF_OPTIONS.SERVICE_REQUEST + '', label: 'Service Request', disabled: false }
  ];
  stateList = []
  productChildCtgryList: Array<CategoryModel>
  productsOnCategoryId: Array<CategoryModel>
  serviceSupportForm: UntypedFormGroup
  _catSubscription;

  /**
   * @constructor
   * @param getUserInfoService 
   * @param categoryService 
   * @param ContactService 
   * @param formBuilder 
   * @param dialogService 
   * @param homePageService 
   */
  constructor(
    public getUserInfoService: GetUserInfoService,
    public categoryService: CategoryService,
    public ContactService: ContactService,
    public formBuilder: UntypedFormBuilder,
    public dialogService: LsDialogService<OkayMessageComponent>,
    private homePageService: HomePageService,
  ) { }

  /**
   * On component initialization, prepare form-group.
   */
  ngOnInit() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select---');
    this.productOptions.push(selectItem)
    this.modelOptions.push(selectItem)
    this.stateOptions.push(selectItem)
    this.getStateList()
    this.getProductChildCtgryList()

    this.serviceSupportForm = this.formBuilder.group({
      kindOfRequest: [1, [Validators.required]],
      product: ["---Select---", [Validators.required]],
      model: ["", Validators.required],
      name: ["", Validators.required],
      contactNo: ["", Validators.required],
      state: ["---Select---", [Validators.required]],
      city: ["", [Validators.required]],
      pincode: ["", [Validators.required]],
      locality: ["", [Validators.required]],
      address: ["", [Validators.required]],
      landmark: ["",],
      complaint: ["", [Validators.required, Validators.maxLength(1000)]]
    })
  }

  /**
   * unsubscribe all subscription when component destroys
   */
  ngOnDestroy(){
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
  }

  /**
   * Fetches list of all states and populates them in state-combo.
   */
  getStateList() {
    this.getUserInfoService.getStateList((data) => {
      if (data != null && !data.error)
        if (data.data != null) {
          this.stateList = data.data;
          this.stateList.forEach(ele => {
            let selectItem: SelectItemModel = new SelectItemModel()
            selectItem.SelectItem(ele.stateCode, ele.stateName)
            this.stateOptions.push(selectItem);
          })
        }
    })
  }

  /**
   * Fetches all child-level categories to populate in category combo.
   */
  getProductChildCtgryList() {
    // this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.CHILD_ONLY, (data) => {
    //   if (data != null && !data.error)
    //     if (data.data != null) {
    //       this.productChildCtgryList = data.data
    //       this.productChildCtgryList.forEach(element => {
    //         let selectItem: SelectItemModel = new SelectItemModel();
    //         selectItem.SelectItem(element.categoryId, element.categoryName);
    //         this.productOptions.push(selectItem)
    //       });
    //     }
    // });
    this._catSubscription = this.homePageService.childCategoriesObservable.subscribe((data) => {
      if (data) {
        this.productChildCtgryList = data;
        if(this.productChildCtgryList.length > 0){
          this.productChildCtgryList.forEach(element => {
            let selectItem: SelectItemModel = new SelectItemModel();
            selectItem.SelectItem(element.categoryId, element.categoryName);
            this.productOptions.push(selectItem)
          });
        }
      }
    })
  }

  /**
   * Fetches all products for a categroyId and populates them in the model-combo.
   * @param categoryId - category-id of which products are to be fetched
   */
  populateModelOptionsOnCategoryId(categoryId) {
    this.modelOptions = []
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select---');
    this.modelOptions.push(selectItem)
    this.categoryService.getProductsOnCategoryId(categoryId, (data) => {
      if (data != null && !data.error)
        if (data.data != null) {
          this.productsOnCategoryId = data.data
          Object.keys(this.productsOnCategoryId).forEach(ele => {
            let selectItem: SelectItemModel = new SelectItemModel();
            selectItem.SelectItem(ele, this.productsOnCategoryId[ele]);
            this.modelOptions.push(selectItem)
          })
        }
    })
  }

  //uncomment to populte model combo
  onProductOptionSelection(categoryName) {
    // let categoryId;
    // if (this.productOptions && this.productOptions.length > 0) {
    //   for (var index = 0; index < this.productOptions.length; index++) {
    //     var element = this.productOptions[index];
    //     if (element.label == categoryName) {
    //       categoryId = element.value;
    //     }
    //   }
    // }
    // this.populateModelOptionsOnCategoryId(categoryId)
  }

  /**
   * allows enter only numbers and +, - signs
   * @param event - keypress event
   */
  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * Submits the form after validation.
   * If error is present, then display the error in error-label.
   * Else prepare ServiceSupportDTO by assigning all form-fields values and send the request.
   * After successful response, display the success response and reset the form.
   * @param errorLabel - element-reference in which error is to be dislayed.
   */
  submitServiceSupport(errorLabel) {
    if (this.serviceSupportForm.value.product == "---Select---" || this.serviceSupportForm.value.product == null || this.serviceSupportForm.value.product == undefined) {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please select product !!";
    } else if (this.serviceSupportForm.value.model == "" || this.serviceSupportForm.value.model == null || this.serviceSupportForm.value.model == undefined) {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please Enter model !!";
    } else if (this.serviceSupportForm.value.state == "---Select---" || this.serviceSupportForm.value.state == null || this.serviceSupportForm.value.state == undefined) {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please select state !!";
    } else if (this.serviceSupportForm.invalid) {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Please Fill All the Mandatory Fields !!";
    } else if (this.serviceSupportForm.valid) {
      let serviceSupportDTO: ServiceSupportDTO = new ServiceSupportDTO();
      serviceSupportDTO.serviceType = this.serviceSupportForm.value.kindOfRequest
      serviceSupportDTO.productName = this.serviceSupportForm.value.product
      serviceSupportDTO.modelNo = this.serviceSupportForm.value.model
      serviceSupportDTO.name = this.serviceSupportForm.value.name
      serviceSupportDTO.contactNo = this.serviceSupportForm.value.contactNo
      serviceSupportDTO.state = this.serviceSupportForm.value.state
      serviceSupportDTO.city = this.serviceSupportForm.value.city
      serviceSupportDTO.pincode = this.serviceSupportForm.value.pincode
      serviceSupportDTO.locality = this.serviceSupportForm.value.locality
      serviceSupportDTO.address = this.serviceSupportForm.value.address
      serviceSupportDTO.landmark = this.serviceSupportForm.value.landmark
      serviceSupportDTO.complaint = this.serviceSupportForm.value.complaint
      // console.log("servicesuppot", serviceSupportDTO)
      this.ContactService.addServiceRequest(serviceSupportDTO, resp => {
        if (!resp.error) {
          if (resp != null && resp.msgList != null) {
            this.dialogService.open(OkayMessageComponent, {}, 'Your service request has been registered. We will get back to you shortly.').subscribe(response => {
            })
            this.serviceSupportForm.reset()
            this.serviceSupportForm.patchValue({ 'kindOfRequest': 1 });
            this.serviceSupportForm.patchValue({ 'product': "---Select---"});
            // this.serviceSupportForm.patchValue({ 'model': "---Select---" });
            this.serviceSupportForm.patchValue({ 'state': "---Select---" }); 
            errorLabel = '';           
          }
        }
      })
    }
  }

}
