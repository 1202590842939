import { Component, OnInit } from '@angular/core';
import { TemplateConstants } from '../../commonComponent/template-constants';

@Component({
  selector: 'app-online-store',
  templateUrl: './online-store.component.html',
  styleUrls: ['./online-store.component.scss']
})
export class OnlineStoreComponent implements OnInit {
  tmpastPath=TemplateConstants.templateAssetsPath
  constructor() { }

  ngOnInit() {
  } 
  goToLink(number){
    if(number == 1){
      window.open(" https://www.amazon.in/stores/INALSA/page/E4011AF0-EA5D-471F-993B-3D5377D1C8AE?ref_=ast_bln", "_blank")
    }else if(number == 2){
      window.open(" https://www.flipkart.com/search?q=INALSA&otracker=search&otracker1=search&marketplace=FLIPKART&as-show=on&as=off&sort=popularity", "_blank")
    }else if(number == 3){
      window.open(" https://www.tatacliq.com/search/?searchCategory=all&text=INALSA", "_blank")
    }else if(number == 4){
      window.open(" https://www.reliancedigital.in/search?q=INALSA:relevance&page=0", "_blank")
    }else if(number == 5){
      window.open(" https://paytm.com/shop/search?q=Inalsa&from=organic&child_site_id=1&site_id=1&category=24717&brand=3021", "_blank")
    }

  } 
}
