<div class="pagesContentContainer">
  <div class="panel">
    <div class="pageContentPanel">
      <!-- <div class="loaderDiv" *ngIf="productDetails == undefined && !showError"></div> -->
      <div fxLayoutAlign="center center" class="errorPage" *ngIf="showError">
        <img [src]="errorPageUrl" style="max-height:100%; max-width:100%;">
      </div>
      <!-- <div *ngIf="productDetails != undefined"> -->
      <div *ngIf="!showError">
        <div class="productUpperPanel" [ngClass]="{'cols-12': showZoomImg}">
          <div class="mb-proTitle">
            <p class="categoryName">{{categoryName}}</p>
            <h2 class="mb-heading">{{productName}}</h2>
          </div>
          <div class="mb-productRateRevPanel" [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
            <div class="rating" *ngIf="giveRating >= 0">
              <ls-rating [editable]=false name="giveRating" [(ngModel)]="giveRating"></ls-rating>
            </div>
            <div class="lnkShare" (click)="openSharingDialog()">
              <span></span>
            </div>
            <div class="lnkCart" (click)="addToCartProduct()">
              <span></span>
            </div>
            <div class="lnkAddReview" title="Write a Review" (click)="addReview()">
              <span></span>
            </div>
            <div class="lnkAddWishlist" title="Add to Wishlist" (click)="addToCartWishList()" *ngIf="allowWishlist">
              <span></span>
            </div>
          </div>
          <div class="productImageMainPanel" [ngClass]="{'cols-12': showZoomImg}">
            <div class="zoom-icons" [ngClass]="{'opacity-none': !showZoomImg}">
              <span class="cross-zoom-img fa fa-close" (click)="exitImgZoom()"></span>
              <span class="left-arrow-zoom-img" (click)="zoomSlideLeft()"
                [ngClass]="{'disable-arrow': disableZoomLeft, 'opacity-none': imageThumbnailsList.length <= 1 || showSuperZoom}"></span>
              <span class="right-arrow-zoom-img" (click)="zoomSlideRight()"
                [ngClass]="{'disable-arrow': disableZoomRight, 'opacity-none': imageThumbnailsList.length <= 1 || showSuperZoom}"></span>
            </div>
            <div class="mb-leftArrow">
              <span class="fa fa-angle-left" (click)="zoomSlideLeft()"
                [ngClass]="{'disable-arrow': disableZoomLeft, 'opacity-none': imageThumbnailsList.length <= 1}"></span>
            </div>
            <div class="productImagePanel"
              [ngClass]="{'opacity-none': playVideo, 'productImgPnl-zoom': showZoomImg, 'super-zoom': showSuperZoom}">
              <div fxLayoutAlign="center center" *ngIf="!showSuperZoom" class="mediumImage"
                (click)="showZoomImgDialog(imageThumbnailsList,productDetails?.productName, image)">
                <img [ngClass]="{'zoomImg': showZoomImg}" [src]="image != undefined?image:defaultPath"
                  title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)">
              </div>
              <div fxLayoutAlign="center center" *ngIf="showSuperZoom" class="mediumImage"
                (click)="showZoomImgDialog(imageThumbnailsList,productDetails?.productName, image)">
                <img [src]="image != undefined?image:defaultPath" [ngClass]="{'zoomImg': showZoomImg}"
                  title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)">
              </div>
            </div>
            <div class="mb-rightArrow">
              <span class="fa fa-angle-right" (click)="zoomSlideRight()"
                [ngClass]="{'disable-arrow': disableZoomRight, 'opacity-none': imageThumbnailsList.length <= 1}"></span>
            </div>
            <div class="imageThumbnailPanel" [ngClass]="{'thumbnail-pnl-zoom': showZoomImg}">
              <div class="leftArrowPnl">
                <span class="left-arrow"
                  [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3, 'disable-arrow': disableLeft}"
                  (click)="slideRight()"></span>
              </div>
              <div class="imgThumbnailContainer">
                <div class="thumbnailIterator" [style.left]="leftUpdatedValue + '%'">
                  <div *ngFor="let thumbnail of imageThumbnailsList; let i=index" class="thumbnailDiv"
                    (mouseover)="updateImageSrc(i)" (click)="updateImageSrc(i)">
                    <img [src]="thumbnail != undefined?thumbnail:defaultPath" class="center-position"
                      title="{{productDetails?.productName}}" alt="" (error)="imgErrorHandler($event)">
                  </div>
                </div>
              </div>
              <div class="rightArrowPnl">
                <span class="right-arrow"
                  [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3, 'disable-arrow': disableRight}"
                  (click)="slideLeft()"></span>
              </div>
            </div>
            <!-- <div class="zoomImgDivOuter" [ngClass]="{'hide': productImageHover == false || !image}">
              <div class="zoomImgDiv" #productMainImage [style.background-image]="'url('+image+')'"></div>
            </div> -->
            <div fxHide.lt-sm style="position: relative" *ngIf="youtubeEmbedLink">
              <div class="playVideoPnl">
                <span class="playVideoIcon fa fa-play-circle-o"></span>
                <a [ngClass]="{'hide':playVideo}" (click)="playProVideo()">Play Video</a>
                <a [ngClass]="{'hide':!playVideo}" (click)="showProImages()">Back to Images</a>
              </div>
            </div>
            <div class="videoOuterPnl" *ngIf="playVideo">
              <iframe class="video" allowfullscreen="" webkitallowfullscreen="" mozallowfullscreen=""
                [src]="youtubeEmbedLinkUrl | safeUrl"></iframe>
            </div>
          </div>
          <div class="productDetailPanel" [ngClass]="{'display-none': showZoomImg}">
            <div class="productTitlePanel">
              <p class="categoryName">{{categoryName}}</p>
              <h2 class="productTitleHeading">{{productName}}</h2>
            </div>
            <div class="productDelPnl">
              <div class="pricePanel">
                <div class="oldPricePnl" *ngIf="mrp > salePrice && productDetails?.showSaving && discount > 0">
                  <p class="lbl">MRP:</p>
                  <!-- <div class="lbl inline-lbl">
                    <p>MRP</p>
                    <p *ngIf="!generalSettings?.mrpInclusiveOfTax">:</p>
                    <p *ngIf="generalSettings?.mrpInclusiveOfTax">(inclusive of all taxes):</p>
                  </div> -->
                  <p class="val">{{mrp | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="currPricePnl">
                  <!-- <p class="lbl">BUY:</p> -->
                  <div class="lbl inline-lbl">
                    <p>MRP</p>
                    <p *ngIf="!generalSettings?.mrpInclusiveOfTax">:</p>
                    <p *ngIf="generalSettings?.mrpInclusiveOfTax">(inclusive of all taxes):</p>
                  </div>
                  <p class="val">{{salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="discountPnl" *ngIf="discount > 0 && productDetails?.showSaving">
                  <p class="lblDiscount">You Save</p>
                  <!-- <p class="val">{{discount | number:'2.1'}}%</p> -->
                  <p class="val">{{discount | currency:currType:'symbol': discountRoundOffInteger}}</p>
                </div>
                <div class="shippingPanel">
                  <p class="shippingDaysLabel">Estimated Shipping in :
                    {{productDetails?.shippingDays}}-{{shippingDays}}days</p>
                  <a *ngIf="showReturnFlag == true" class="returnPolicyLink" (click)="goToReturnAndRefund()"
                    target="_blank">Easy
                    Return Policy</a>
                </div>
                <div class="freeShippingPnl" *ngIf="productDetails?.shippingCharges == 0">
                  <p class="freeShippingLbl">{{freeShippingLbl}}</p>
                </div>
              </div>
              <div class="productRateRevPanel">
                <div class="rating" *ngIf="giveRating >= 0" [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
                  <ls-rating [editable]=false name="giveRating" [(ngModel)]="giveRating"></ls-rating>
                </div>
                <a class="addWishPanel" title="Add to Wishlist" (click)="addToCartWishList()" *ngIf="allowWishlist"
                  [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
                  <span class="fa fa-heart-o"></span>
                </a>
                <a class="writeReviewLink" title="Write a Review" (click)="addReview()"
                  [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
                  <span></span>
                </a>
                <div class="sharePnl" (click)="openSharingDialog()">
                  <div class="shareIcon"></div>
                </div>
              </div>
              <div class="socialSharePanel" *ngIf="!isMobile">
                      <div style="padding-left: 10px;">
                        Share On
                      </div> 
                <div class="socialLinks">
                  <a *ngIf="isFbHash" title="Share on Facebook" class="socialLink fb" href={{socialShareFbUrl}}
                    target="_blank"></a>
                  <a *ngIf="isGoogleHash" title="Share on Google" class="socialLink google"
                    href={{socialShareGoogleUrl}} target="_blank"></a>
                  <a *ngIf="isLinkedInHash" title="Share on LinkedIn" class="socialLink linkedIn"
                    href={{socialShareLinkedInUrl}} target="_blank"></a>
                  <a *ngIf="isTwitterHash" title="Share on Twitter" class="socialLink twitter"
                    href={{socialShareTwitterUrl}} target="_blank"></a>
                </div>
              </div>
              <div (click)="shareProduct()">
                <img class="shareIconMobile" [src]="shareIconPath" alt="">
              </div>
              <div class="pnlFilterItrOuter">
                <ul class="filterItr">
                  <li class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
                    <div class="pnlMandtryFieldInner">
                      <p class="variantDesc">{{variantType?.variantHeading}}</p>
                      <ul class="variantItr">
                        <li #VariantList class="variantItrLi"
                          [ngClass]="{'filterSelected' : selectionArray && (optionIdx == selectionArray[variantTypeIdx]) }"
                          *ngFor="let variant of variantType.options; let optionIdx = index;"
                          (click)="setProductVariant($event, variantTypeIdx, optionIdx, variant.mappedUniqueIds)">
                          <p class="filter"
                            [ngClass]="{'colorFilter': variantType?.variantHeading.toUpperCase() == 'COLOR'}"
                            title="{{variant.option}}">{{variant.option}}</p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="pnlForQuantity" [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
                <p class="lblQuantityTag">{{quantityTag}}</p>
                <div class="pnlSpinner">
                  <div class="firstDiv" (click)="decreaseProQuantity()"></div>
                  <div class="midDiv">
                    <input value="{{productQuantity}}" disabled />
                  </div>
                  <div class="secondDiv" (click)="increaseProQuantity()"></div>
                </div>
              </div>

              <div class="mb-pnlForQuantity" [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
                <div class="pnlQuantityTag">
                  <p class="lblQuantityTag">{{quantityTag}}</p>
                </div>
                <div fxLayoutAlign="start center" class="pnlSpinner">
                  <div fxLayout="row" class="spinner">
                    <div class="firstDiv">
                      <input value="{{productQuantity}}" disabled />
                    </div>
                    <div fxLayout="column" class="secondDiv">
                      <div fxFlex="50" fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up"
                        (click)="increaseProQuantity()"></div>
                      <div fxFlex="50" fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down"
                        (click)="decreaseProQuantity()"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pnlForStatus">
                <div class="lblStatus" *ngIf="isoutOfStock == true">
                  <p class="preLbl">This item is</p>
                  <p>{{outOfStockTag}}</p>
                </div>
                <!-- <div fxLayoutAlign="start center" class="lblStatus" *ngIf="isProNotAvailable == false && isoutOfStock == false">
                  <p>{{availableTag}}</p>
                </div> -->
                <div class="lblStatus" *ngIf="isProNotAvailable == true && isoutOfStock == false">
                  <p>{{notAvailableTag}}</p>
                </div>
              </div>
              <div class="minOrderQtyPnl"></div>
              <div class="keyFeaturesPnl">
                <div class="pnlForEnquiry" *ngIf="enquiryMode == true || buyAndEnquiryMode == true">
                  <div fxLayoutAlign="center center" class="callForPriceBtn">
                    <a [href]="'enquiry?t='+productDetails?.titleId+'&s=0'">Enquire</a>
                  </div>
                </div>
                <div class="addToCartPnl"
                  *ngIf="((isoutOfStock == false && allowCart) || (isoutOfStock == true && allowCart && productDetails?.acceptOrderOnOutOfStock == 1)) && productDetails?.buyEnquiryMode == 0">
                  <div class="addToCartBtn" (click)="addToCartProduct()">
                    <span class="icon fa fa-shopping-cart"></span>
                    <a>{{addToCartLbl}}</a>
                  </div>
                </div>
                <!-- find nearest store -->
                <div class="addToCartPnl"
                  *ngIf="((isoutOfStock == false && allowCart) || (isoutOfStock == true && allowCart && productDetails?.acceptOrderOnOutOfStock == 1)) && productDetails?.buyEnquiryMode == 1">
                  <div class="addToCartBtn" (click)="openStores()">
                    <!-- <span class="icon fa fa-shopping-cart"></span> -->
                    <a style="display: block !important;">FIND NEAREST STORE</a>
                  </div>
                </div>

                <div class="buyNowPnl"
                  *ngIf="(isoutOfStock == false || (productDetails?.acceptOrderOnOutOfStock == 1 && isoutOfStock == true)) && productDetails?.buyEnquiryMode == 0">
                  <div class="buyNowBtn" (click)="buyProduct()">
                    <span class="icon buyNowIcon"></span>
                    <a>{{buyNowLbl}}</a>
                  </div>
                </div>
                <!-- Buy online -->
                <div class="buyNowPnl"
                  *ngIf="(isoutOfStock == false || (productDetails?.acceptOrderOnOutOfStock == 1 && isoutOfStock == true)) && productDetails?.buyEnquiryMode == 1">
                  <div class="buyNowBtn" (click)="openPage()">
                    <span class="icon buyNowIcon"></span>
                    <a>BUY ONLINE</a>
                  </div>
                </div>
              </div>
              <div class="shipmentPanel"
                *ngIf="(productDetails?.shippingCharges > 0 || productDetails?.cod > 0 || (showGst && gst > 0) || productDetails?.handlingCharges > 0)">
                <div class="shippingChargesForProductDetail" *ngIf="productDetails?.shippingCharges > 0">
                  <p class="label">{{shippingChargesTag}}</p>
                  <p class="value">{{productDetails?.shippingCharges | currency:currType:'symbol':
                    priceRoundOffInteger}}</p>
                </div>
                <div class="codChargesForProductDetail" *ngIf="productDetails?.cod > 0">
                  <p class="label">{{codChargesTag}}</p>
                  <p class="value">{{productDetails?.cod | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="gstChargesForProductDetail" *ngIf="showGst && productDetails?.gst > 0">
                  <p class="label">{{gstChargesTag}}</p>
                  <p class="value">{{productDetails?.gst | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
                <div class="pnlHandlingCls" *ngIf="productDetails?.handlingCharges > 0">
                  <p class="label">{{handlingChargesTag}}</p>
                  <p class="value">{{productDetails?.handlingCharges | currency:currType:'symbol':
                    priceRoundOffInteger}}</p>
                </div>
              </div>
              <div class="vatPanelCls"></div>
              <div fxHide.lt-sm class="shippingPanel">
                <p class="shippingDaysLabel">Estimated Shipping in : {{shippingDays}}-{{shippingDays+2}}days</p>
                <a *ngIf="showReturnFlag == true" class="returnPolicyLink" (click)="goToReturnAndRefund()"
                  target="_blank">Easy
                  Return Policy</a>
                <div fxLayoutAlign="start center" fxLayout="row" style="margin-top: 20px;">
                  <div fxLayoutAlign="center center" class="freeShippingPnl"
                    *ngIf="productDetails?.shippingCharges == 0">
                    <p class="freeShippingLbl">{{freeShippingLbl}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pnlOfferMain">
            <ul class="comboOffers">
              <li class="comboOffersContainer" *ngFor="let comboOfferDetail of comboOfferDetails"
                [ngClass]="{'hide': !(comboOfferDetail.releaseDateValid &&
              comboOfferDetail.validityDateValid && comboOfferDetail.visibility == 0 && comboOfferDetail.offerVisibilityOnOfferConditionType)}">
                <div class="pnlOfferHdng">
                  <span></span>
                </div>
                <div class="pnlOfferCondition">
                  <ul class="offerConditionItr">
                    <li *ngFor="let offerCondition of comboOfferDetail.conditions">
                      <ul class="offerConditionProdItr">
                        <li *ngFor="let offerProDetail of offerCondition.offerProductsDetails">
                          <div class="proTileOffer">
                            <div class="lblForBuy">
                              <p>BUY</p>
                            </div>
                            <a class="pnlOfferImg"
                              [routerLink]="'/details/'+ (offerProDetail?.productURLText ? offerProDetail?.productURLText : '')"
                              [queryParams]="{'t': offerProDetail?.titleId}" target="_blank">
                              <img
                                [src]="(offerProDetail?.imageUrl && offerProDetail?.images && offerProDetail?.images[0])?offerProDetail?.imageUrl +'m/'+ offerProDetail?.images[0]:defaultPath"
                                defaultPath=TemplateConstants.defaultImgPath;>
                            </a>
                            <a class="pnlOfferTitle">{{offerProDetail?.productName}}</a>
                            <div class="pnlOfferLblPrice">for {{offerProDetail?.salePrice | currency:currType:'symbol':
                              priceRoundOffInteger}}</div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="pnlOfferAction">
                  <ul class="offerActionItr">
                    <li *ngFor="let offerAction of comboOfferDetail.actions">
                      <ul>
                        <li *ngFor="let offerProDetail of offerAction.offerProductsDetails">
                          <div class="proTileOffer">
                            <div class="lblForBuy">
                              <p>FREE</p>
                            </div>
                            <a class="pnlOfferImg"
                              [routerLink]="'/details/'+ (offerProDetail?.productURLText ? offerProDetail?.productURLText : '')"
                              [queryParams]="{'t': offerProDetail?.titleId}" target="_blank">
                              <img
                                [src]="(offerProDetail?.imageUrl && offerProDetail?.images && offerProDetail?.images[0])?offerProDetail?.imageUrl +'m/'+ offerProDetail?.images[0]:defaultPath"
                                defaultPath=TemplateConstants.defaultImgPath;>
                            </a>
                            <a class="pnlOfferTitle">{{offerProDetail?.productName}}</a>
                            <div class="pnlOfferLblPrice">worth {{offerProDetail?.salePrice |
                              currency:currType:'symbol': priceRoundOffInteger}}</div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="pnlOfferDetail">
                  <ul class="lblForComboPrice">
                    <li *ngFor="let offerCondition of comboOfferDetail.conditions">
                      <ul>
                        <li *ngFor="let offerProDetail of offerCondition.offerProductsDetails">
                          <p>Buy</p>
                          <p>{{offerProDetail?.qty}}</p>
                          <a class="proLink">{{offerProDetail?.productName}}</a>
                          <p>of</p>
                          <p>{{offerProDetail?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="lblForComboPrice">
                    <li *ngFor="let offerAction of comboOfferDetail.actions">
                      <ul>
                        <li *ngFor="let offerProDetail of offerAction.offerProductsDetails">
                          <p>Get</p>
                          <p>{{offerProDetail?.qty}}</p>
                          <a class="proLink">{{offerProDetail?.productName}}</a>
                          <p>worth</p>
                          <p>{{offerProDetail?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="productSpecPanel" *ngIf="featureArray != undefined">
          <div class="pnlFeatureTab">
            <div class="tab-headers">
              <ul>
                <li *ngFor="let tabHeader of tabHeaders; let i = index;" class="tab-header-div"
                  [ngClass]="{'hide':!tabHeader.showTab}">
                  <a class="tab-header" *ngIf="tabHeader.showTab" [ngClass]="{'selected-tab': i == selectedTab}"
                    (click)="tabClicked(i)">
                    <span>{{tabHeader?.tabTitle}}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div class="features" [ngClass]="{'hide': selectedTab != 0}">
                <p [innerHTML]="productDetails?.desc"></p>
              </div>
              <div class="specifications" [ngClass]="{'hide': selectedTab != 1}">
                <ul class="specPanel" *ngFor="let feature of featureArray">
                  <p class="featureHeading" *ngIf="feature.specialData == null">{{feature.name}}</p>
                  <!-- special Heading title has been hidden -->
                  <ul class="attributeContainer" *ngIf="feature.details != null">
                    <li *ngFor="let detail of feature.details">
                      <div class="attrLi">
                        <p class="attrName" [innerHTML]="detail.key"></p>
                        <p class="attrValue" [innerHTML]="detail.value"></p>
                      </div>
                    </li>
                  </ul>
                  <ul class="specialData hide" *ngIf="feature.specialData != null">
                    <!-- special Heading data have been hidden -->
                    <p class="specialSpcfctn" [innerHTML]="feature.specialData"></p>
                  </ul>
                </ul>
              </div>
              <div class="description" [ngClass]="{'hide': selectedTab != 2}">
                <p [innerHTML]="descContent" *ngIf="descContent != undefined && descContent != ''"></p>
              </div>
              <div class="dwDemo" [ngClass]="{'hide': selectedTab != 3}">
                <ul>
                  <li *ngFor="let attachment of attachments">
                    <div class="demo-video" *ngIf="attachment.isYoutube">
                      <iframe [src]="attachment.url | safeUrl"></iframe>
                    </div>
                    <div class="demo-file" *ngIf="!attachment.isYoutube">
                      <span class="fa fa-download"></span>
                      <a [href]="attachment.url | safeUrl" target="_blank">{{attachment.docName}}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="featureTabArr">
            <ul class="featureTabItr">
              <li *ngIf="tabHeaders[0].showTab">
                <div class="feaTabHeader">
                  <p>Features</p>
                  <span class="spanCollapsed" (click)="toggleSpecPnl(0)"
                    [ngClass]="{'spanExpanded': specPnlClicked[0]}"></span>
                </div>
                <div class="feaTabContent collapsed" [ngClass]="{'in': specPnlClicked[0]}">
                  <div class="features">
                    <p [innerHTML]="productDetails?.desc"></p>
                  </div>
                </div>
              </li>

              <li *ngIf="tabHeaders[1].showTab">
                <div class="feaTabHeader">
                  <p>Specifications</p>
                  <span class="spanCollapsed" (click)="toggleSpecPnl(1)"
                    [ngClass]="{'spanExpanded': specPnlClicked[1]}"></span>
                </div>
                <div class="feaTabContent collapsed" [ngClass]="{'in': specPnlClicked[1]}">
                  <div class="specifications">
                    <ul class="specPanel" *ngFor="let feature of featureArray">
                      <p class="featureHeading" *ngIf="feature.specialData == null">{{feature.name}}</p>
                      <ul class="attributeContainer" *ngIf="feature.details != null">
                        <li *ngFor="let detail of feature.details">
                          <div class="attrLi">
                            <p class="attrName" [innerHTML]="detail.key"></p>
                            <p class="attrValue" [innerHTML]="detail.value"></p>
                          </div>
                        </li>
                      </ul>
                      <ul class="specialData hide" *ngIf="feature.specialData != null">
                        <p class="specialSpcfctn" [innerHTML]="feature.specialData"></p>
                      </ul>
                    </ul>
                  </div>
                </div>
              </li>

              <li *ngIf="tabHeaders[2].showTab">
                <div class="feaTabHeader">
                  <p>Description</p>
                  <span class="spanCollapsed" (click)="toggleSpecPnl(2)"
                    [ngClass]="{'spanExpanded': specPnlClicked[2]}"></span>
                </div>
                <div class="feaTabContent collapsed" [ngClass]="{'in': specPnlClicked[2]}">
                  <div class="description">
                    <p [innerHTML]="descContent" *ngIf="descContent != undefined && descContent != ''"></p>
                  </div>
                </div>
              </li>

              <li *ngIf="tabHeaders[3].showTab">
                <div class="feaTabHeader">
                  <p>Download/Demo</p>
                  <span class="spanCollapsed" (click)="toggleSpecPnl(3)"
                    [ngClass]="{'spanExpanded': specPnlClicked[3]}"></span>
                </div>
                <div class="feaTabContent collapsed" [ngClass]="{'in': specPnlClicked[3]}">
                  <div class="dwDemo">
                    <ul>
                      <li *ngFor="let attachment of attachments">
                        <div class="demo-video" *ngIf="attachment.isYoutube">
                          <iframe [src]="attachment.url | safeUrl"></iframe>
                        </div>
                        <div class="demo-file" *ngIf="!attachment.isYoutube">
                          <span class="fa fa-download"></span>
                          <a [href]="attachment.url | safeUrl" target="_blank">{{attachment.docName}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="productReviewPanel" *ngIf="ratingAndReviewData"
          [ngClass]="{'hide': productDetails?.buyEnquiryMode == 1}">
          <div class="heading">
            <div>
              <h3>{{lblCustomerReviews}}</h3>
              <a (click)="addReview()">Write a Review</a>
            </div>
          </div>
          <div class="pnlRateReview">
            <div class="ratingPnl">
              <div class="currRatingPanel">
                <div class="pnlRatingHeadingMain">
                  <div class="overallRating">
                    <p>Overall Rating ( </p>
                    <p>{{totalReviews.length}}</p>
                    <p>Reviews )</p>
                  </div>
                  <ls-rating [editable]=false [(ngModel)]="proAvgRating"></ls-rating>
                  <p class="currRateLbl">{{proAvgRating | number: '1.1-1'}}</p>
                </div>
                <div class="pnlRatingDist">
                  <p class="lblRatingDist">Rating Distribution</p>
                  <div class="pnlRatingFromCustMain">
                    <div class="pnlRatingFromCust" *ngFor="let ratingCount of ratingCountList; let i = index;">
                      <div class="lblNumStar">
                        <span>{{ratingCountList.length - i}} Stars</span>
                        <p>(</p>
                        <p class="currRateLabel">{{ratingCount}}</p>
                        <p>)</p>
                      </div>
                      <div class="RatingDesign">
                        <ul #avgCustRating>
                          <li *ngFor="let block of blocks">
                            <span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="pnlAvgFeatureRating">
                    <div class="pnlFeatures" *ngFor="let ratingBlock of ratingBlocks; let i = index;">
                      <p class="lblNumStar">{{ratingBlock.lbl}}</p>
                      <div class="RatingDesign">
                        <ul #avgFeatureRating>
                          <li *ngFor="let block of blocks">
                            <span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reviewPnl">
              <ul class="reviewItr">
                <li *ngFor="let reviewModel of ratingAndReviewData.reviewModels">
                  <div class="customerReviewPanel">
                    <div class="pnlCustRatingHdng">
                      <p class="postingDateLbl">{{reviewModel.createDate | date:'EEE MMM dd HH:mm:ss zzz yyyy'}};</p>
                      <p class="userNameLbl">{{reviewModel.username}}</p>
                      <p class="saysLbl">says</p>
                      <ls-rating [editable]=false [(ngModel)]="reviewModel.productRating"></ls-rating>
                    </div>
                    <div class="pnlUserReviewDet">
                      <p class="reviewTitleLabel">{{reviewModel.reviewTitle}}</p>
                      <p *ngIf="reviewModel.reviewText != null && reviewModel.reviewText != ''" class="userReviewLabel">
                        {{reviewModel.reviewText}}</p>
                    </div>
                    <div class="pnlAvgFeatureRating" #userRating>
                      <div class="pnlFeatures" *ngFor="let ratingBlock of ratingBlocks; let i = index;">
                        <p class="lblNumStar">{{ratingBlock.lbl}}</p>
                        <div class="RatingDesign">
                          <ul>
                            <li *ngFor="let block of blocks">
                              <span></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>