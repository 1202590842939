import { Routes } from '@angular/router';
import { CartComponent } from '../components/cart/cart.component';
import { CollectionsComponent } from '../components/collections/collections.component';
import { DetailsComponent } from '../components/details/details.component';
import { DetailGuard } from '../../services/detail.guard';
import { LoginAuthService } from '../../services/login-auth.service';
import { ContactUsComponent } from '../components/contact-us/contact-us.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { OrderTrackingComponent } from '../components/order-tracking/order-tracking.component';
import { OrderConfirmedComponent } from '../components/order-confirmed/order-confirmed.component';
import { OrderCancelledComponent } from '../components/order-cancelled/order-cancelled.component';
import { UnsubscribeNewsLetterComponent } from '../components/unsubscribe-news-letter/unsubscribe-news-letter.component';
import { EnquiryComponent } from '../components/enquiry/enquiry.component';
import { StoreLocatorComponent } from '../components/store-locator/store-locator.component';
import { PartnersComponent } from '../components/partners/partners.component';
import { MediaGalleryComponent } from '../components/media-gallery/media-gallery.component';
import { BuyingGuideComponent } from '../components/buying-guide/buying-guide.component';
import { PremiumDealerComponent } from '../components/premium-dealer/premium-dealer.component';
import { MapDealerComponent } from '../components/map-dealer/map-dealer.component';
import { DistributorComponent } from '../components/distributor/distributor.component';
import { CareerComponent } from '../components/career/career.component';
import { ServiceSupportComponent } from '../components/service-support/service-support.component';
import { CompareComponent } from '../components/compare/compare.component';
import { ProductSetComponent } from '../components/product-set/product-set.component';
import { DivisionComponent } from '../components/division/division.component';
import { ChannelPartnerComponent } from '../components/channel-partner/channel-partner.component';
import { StaticPageComponent } from '../components/static-page/static-page.component';
import { LoginComponent } from '../components/login/login.component';
import { OnlineStoreComponent } from '../components/online-store/online-store.component';
import { ProductRegistrationComponent } from '../components/product-registration/product-registration.component';

export const routes: Routes = [
    {
        path: '', loadChildren: () => import('../../published/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'store/:storeId', loadChildren: () => import('../../published/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'shopping-cart', component:CartComponent  
    },
    {
        path: 'login', component : LoginComponent
    },
    {
        path: 'collections', component : CollectionsComponent 
    },
    {
        path: 'collections/:id1', component : CollectionsComponent 
    },
    {
        path: 'collections/:id1/:id2', component : CollectionsComponent 
    },
    {
        path: 'collections/:id1/:id2/:id3', component : CollectionsComponent 
    },
    {
        path: 'search', component : CollectionsComponent  
    },
    {
        path: 'search/:id1', component : CollectionsComponent  
    },
    {
        path: 'author', component : CollectionsComponent  
    },
    {
        path: 'author/:id1', component : CollectionsComponent  
    },
    {
        path: 'details', component : DetailsComponent, canDeactivate: [DetailGuard]  
    },
    {
        path: 'details/:product', component : DetailsComponent  , canDeactivate: [DetailGuard]  
    },
    {
        path: 'blog', loadChildren : () => import('../../published/blogs/blogs.module').then(m => m.BlogsModule) 
    },
    {
        path : 'checkout', loadChildren : () => import('../../published/payment/payment.module').then(m => m.PaymentModule) , canActivate: [
            // AuthGuard
            LoginAuthService
        ]
    },
    {
        path: 'contact-us', component : ContactUsComponent  
    },
    {
        path: 'forgot-password', component : ForgotPasswordComponent  
    },
    {
        path: 'my-dashboard', loadChildren:() => import('../../published/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate : [
            // AuthGuard
            LoginAuthService

        ]
    },
    {
        path: 'track-order', component : OrderTrackingComponent  
    },
    {
        path: 'order-confirmed', component : OrderConfirmedComponent  
    },
    {
        path: 'order-cancelled', component : OrderCancelledComponent  
    },
    {
        path: 'online-store', component : OnlineStoreComponent
    },
    {
        path: 'unsubscribe-news-letter', component : UnsubscribeNewsLetterComponent  
    },
    {
        path: 'enquiry', component : EnquiryComponent
    },
    {
        path: 'store-locator', component : StoreLocatorComponent  
    },
    {
        path: 'partners', component : PartnersComponent 
    },
    {
        path: 'media-gallery', component : MediaGalleryComponent  
    },
    {
        path: 'buying-guide', component:BuyingGuideComponent  
    },
    {
        path: 'buying-guide/:id1', component:BuyingGuideComponent  
    },
    {
        path: 'buying-guide/:id1/:id2', component:BuyingGuideComponent  
    },
    {
        path: 'premium-dealer', component: PremiumDealerComponent
    },
    {
        path: 'map-dealer', component: MapDealerComponent
    },
    {
        path: 'distributor', component: DistributorComponent
    },
    {
        path: 'career', component: CareerComponent
    },
    {
        path: 'service-support', component: ServiceSupportComponent  
    },
    {
        path: 'compare', component: CompareComponent
    },
    {
        path: 'product-set', component: ProductSetComponent
    },
    {
        path: 'division', component: DivisionComponent
    },
    {
        path: 'channel-partner', component: ChannelPartnerComponent
    },
    {
        path: 'pos', loadChildren: () => import('../../pos/pos.module').then(m => m.PosModule)
    },
    {
        path:'product-registration', component: ProductRegistrationComponent
    },
    {
        path: '**', component: StaticPageComponent
    }
]

