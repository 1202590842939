import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginService } from '../../../services/login.service';
import { UserModel } from '../../../common/models/user-model';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
    selector: 'app-dashboard-options',
    templateUrl: './dashboard-options.component.html',
    styleUrls: ['./dashboard-options.component.scss'],
})

export class DashboardOptionComponent implements OnInit, OnDestroy {

    ngOnDestroy(){
      
    } 
    userModel: UserModel
    userName: string
    dialog: LsMdDialog<DashboardOptionComponent>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loginService: LoginService,
        public dialogService: LsDialogService<OkayMessageComponent>,
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('currentUser') !== null) {
                this.userModel = JSON.parse(localStorage.getItem('currentUser'));
                this.userName = this.userModel.userName;
            }
        }

    }

    onClick() {
        this.dialog.close(null);
    }

    logOut() {
        var maintainLocalCart = false;
        this.loginService.logOut(maintainLocalCart, resp => {
            this.dialog.close(null);
            if (resp != null && resp != undefined) {
                this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
                })
            }
        });
    }
}
