import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginService } from '../../../services/login.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { UserModel } from '../../../common/models/user-model'
import { LoginRequestModel } from '../../../common/models/loginRequestModel'
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';
import { HomePageService } from '../../../services/home-page.service';
import { CartCountService } from '../../../services/cart-count.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { CartComponent } from '../../../published/components/cart/cart.component'
import { OkayMessageComponent } from '../../../published/commonComponent/okay-message/okay-message.component';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartService } from '../../../services/cart.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }
  dialog: LsMdDialog<LoginComponent>;
  loginForm: UntypedFormGroup
  registerForm: UntypedFormGroup
  user: UserModel
  fbOauth: boolean
  googleOauth: boolean
  email: string = ""
  password: string = ""
  rememberMe: boolean = false
  isLoginForm: boolean = false
  isRegisterForm: boolean = false
  heading: string = ""
  loginRequestModel: LoginRequestModel = new LoginRequestModel()
  isChannelPartnerLogin: boolean = false
  currCart: CartModel;

  constructor(
    public loginService: LoginService,
    public formBuilder: UntypedFormBuilder,
    private socialAuthService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public YesNoDialogService: LsDialogService<YesNoPopupComponent>,
    private homePageService: HomePageService,
    private cartCountService: CartCountService,
    private cartService: CartService,
    private okayMessageService: LsDialogService<OkayMessageComponent>,
    @Inject(PLATFORM_ID) private platformId: Object,

  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(data => {
      if (this.activatedRoute.snapshot.queryParamMap.get('action') == null) {
        this.isLoginForm = true;
        this.isRegisterForm = false;
        this.heading = 'LOGIN';
      } else if (this.activatedRoute.snapshot.queryParamMap.get('action') == 'register') {
        this.isRegisterForm = true;
        this.isLoginForm = false;
        this.heading = 'REGISTER';
      }
    });
    this.cartCountService.cart.subscribe((cart) => {
      this.currCart = cart;
    })
    this.loginRequestModel = this.loginService.getLoginRequestModel();
    if (this.loginRequestModel.moveTo == 'channel-partner') {
      this.isChannelPartnerLogin = true;
    }
    if (Constants.VENDOR_DETAILS != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO != undefined) {
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId != null && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId != "") {
        this.fbOauth = true;
      }
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile != null && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile != "") {
        this.googleOauth = true;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != undefined && localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != "") {
        let emailCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email');
        let pwdCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd');
        this.email = emailCookie;
        this.password = pwdCookie;
        this.rememberMe = true;
      }
    }
    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: [this.password, [Validators.required]],
      remember: [this.rememberMe]
    });
    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      emailSignUp: ["", [Validators.email, Validators.required]],
      mobileSignUp: [""],
      passwordSignUp: ["", [Validators.required]],
      cnfrmPwSignUp: ["", [Validators.required]],
      subscribeToNewsSignUp: [false]
    });
  }

   loginSubmit($event, errorLabel) {       if(!isPlatformBrowser(this.platformId)) {         return       }
    let email, password;
    let rememberMe = this.loginForm.value.remember;
    if (this.loginForm.value.email != "") {
      email = this.loginForm.value.email;
      password = this.loginForm.value.password;
    }
    if (this.loginForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Field is Required !";
    } else {
      let userType = Constants.UserType.registered;
      this.loginService.login(this.rememberMe, email, password, false, userType, (resp) => {
        if (!resp.error && resp.data) {
          if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data && resp.otherInfo.cartWishlist.data.length > 0) {
            let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
            let wishlistCart: CartModel, userCart: CartModel;
            if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[0];
            } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[0];
            }
            if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[1];
            } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[1];
            }
            if (wishlistCart) {
              this.setLocalCart(wishlistCart, true);
            }
            if (userCart) {
              this.cartAppendHandler(userCart, resp, errorLabel);
            } else {
              this.afterLogin(resp, errorLabel);
            }
          } else {
            if (isPlatformBrowser(this.platformId)) {
              let localCart = new CartModel();
              if (localStorage.getItem('localCart')) {
                localCart = JSON.parse(localStorage.getItem('localCart'));
              }
              if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
                localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
                this.computeCart(localCart);
              } else {
                this.afterLogin(resp, errorLabel);
              }
            }
          }
        } else {
          errorLabel.className = "errorLabel show";
          errorLabel.innerHTML = resp.msgList[0];
        }
        if (rememberMe && resp.data != undefined) {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email', email);
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd', resp.data.data.password);
          }
        }
      })
    }
  }

  signUpSubmit($event, errorLabel) {
    let storeUserModel = new UserModel()
    let subscribeToNwsLttr = ''
    let mode
    if (this.registerForm.value.emailSignUp != "") {
      storeUserModel.email = this.registerForm.value.emailSignUp
      storeUserModel.password = this.registerForm.value.passwordSignUp
      storeUserModel.userName = this.registerForm.value.name
      storeUserModel.mobile = this.registerForm.value.mobile
      storeUserModel.userType = Constants.UserType.registered
      subscribeToNwsLttr = this.registerForm.value.subscribeToNewsSignUp
      mode = Constants.LOGIN_MODE.DEFAULT
    }
    if (this.registerForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "**Field(s) required !";
    } else if (this.registerForm.value.passwordSignUp != this.registerForm.value.cnfrmPwSignUp) {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Passowrd MisMatch !";
    } else {
      errorLabel.className = "errorLabel hide";
      storeUserModel.userType = Constants.UserType.registered;
      mode = Constants.LOGIN_MODE.DEFAULT;
      this.loginService.sign_up(storeUserModel, subscribeToNwsLttr, mode, (resp) => {
        if (!resp.error && resp.data) {
          let localCart = new CartModel();
          if (localStorage.getItem('localCart')) {
            localCart = JSON.parse(localStorage.getItem('localCart'));
          }
          if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
            localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
            this.computeCart(localCart);
          }
          this.afterLogin(resp, errorLabel);
        } else if (resp.error) {
          errorLabel.className = "errorLabel show";
          errorLabel.innerHTML = resp.msgList[0];
        }
      })
    }
  }

  public socialSignIn(socialPlatform: string, errorLabel) {
    let socialPlatformProvider;
    if (socialPlatform === "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        let user: UserModel = new UserModel();
        user.userName = userData.name;
        user.email = userData.email;
        user.userType = Constants.UserType.registered;
        this.loginService.sign_up(user, null, Constants.LOGIN_MODE.OAUTH, resp => {
          if (!resp.error && resp.data) {
            if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data && resp.otherInfo.cartWishlist.data.length > 0) {
              let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
              let wishlistCart: CartModel, userCart: CartModel;
              if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[0];
              } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[0];
              }
              if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[1];
              } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[1];
              }
              if (wishlistCart) {
                this.setLocalCart(wishlistCart, true);
              }
              if (userCart) {
                this.cartAppendHandler(userCart, resp, errorLabel);
              } else {
                this.afterLogin(resp, errorLabel);
              }
            } else {
              let localCart = new CartModel();
              if (localStorage.getItem('localCart')) {
                localCart = JSON.parse(localStorage.getItem('localCart'));
              }
              if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
                localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
                this.computeCart(localCart);
              } else {
                this.afterLogin(resp, errorLabel);
              }
            }
          } else {
            errorLabel.className = "errorLabel show";
            errorLabel.innerHTML = resp.msgList[0];
          }
        })
      }
    )
  }

  /**
 * Cart append handling - (append to previous cart ?)
 * LocalCart - Cart maintained before Login
 * LoginCart - Cart maintained after Login
 * If LocalCart and LoginCart both are not empty - then only pop-up will come. If yes - then both carts will merge o/w LocalCart will remain.
 * Otherwise, if only LocalCart exists - then LocalCart will remain.
 *            else (only LoginCart exists) - then LoginCart will remain. 
 */

  cartAppendHandler(prevUserCart: CartModel, resp, errorLabel) {
    let localCart = new CartModel();
    if (localStorage.getItem('localCart')) {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0 && prevUserCart.cartProductModels && prevUserCart.cartProductModels.length > 0) {
      this.YesNoDialogService.open(YesNoPopupComponent, {}, "Do you want to append to previous cart?").subscribe(response => {
        if (response == "yes") {
          let updatedLocalCart: CartModel = new CartModel();
          updatedLocalCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
          updatedLocalCart.cartProductModels = localCart.cartProductModels;
          prevUserCart.cartProductModels.forEach((ele) => {
            let isPresent: boolean;
            for (var index = 0; index < localCart.cartProductModels.length; index++) {
              var localPro = localCart.cartProductModels[index];
              if (localPro.titleId == ele.titleId) {
                isPresent = true;
                if (localPro.variantId && ele.variantId) {
                  isPresent = false;
                  if (localPro.variantId == ele.variantId) {
                    isPresent = true;
                    break;
                  }
                }
              }
            }
            if (!isPresent) {
              updatedLocalCart.cartProductModels.push(ele);
            }
          });
          this.computeCart(updatedLocalCart);
        } else if (response == "no") {
          localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
          this.computeCart(localCart);
        }
      });
    } else if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
      this.computeCart(localCart);
    } else if (prevUserCart.cartProductModels && prevUserCart.cartProductModels.length > 0) {
      this.setLocalCart(prevUserCart, false);
    }
    this.afterLogin(resp, errorLabel);
  }

  computeCart(cart: CartModel) {
    this.cartService.computeCart(cart, (resp) => {
      if (!resp.error && resp.data && resp.data[0]) {
        this.setLocalCart(resp.data[0], false);
      } else {
        this.okayMessageService.open(OkayMessageComponent, {}, 'Error appending to previous cart!');
      }
    })
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
      this.cartCountService.updateCart(newLocalCart);
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
      this.cartCountService.updateWishlistCart(newLocalCart);
    }
  }

  afterLogin(resp, errorLabel) {
    let respUserType;
    if (localStorage.getItem('currentUser') != null || localStorage.getItem.apply('currentuser') != undefined) {
      respUserType = JSON.parse(localStorage.getItem('currentUser')).userType;
    }
    if (this.loginRequestModel.moveTo != '' && this.loginRequestModel.moveTo != 'channel-partner') {
      let params, qParams;
      if (this.loginRequestModel.isDetailPage) {
        if (this.loginRequestModel.titleId != '') {
          params = {
            t: this.loginRequestModel.titleId
          }
        } else if (this.loginRequestModel.titleId != '' && this.loginRequestModel.variantId != '') {
          params = {
            t: this.loginRequestModel.titleId,
            sizeRecNum: this.loginRequestModel.variantId
          }
        }
        qParams = Object.assign({}, params)
        this.router.navigate([this.loginRequestModel.moveTo], { queryParams: qParams });
      } else if (this.loginRequestModel.moveTo == '/shopping-cart') {
        this.router.navigate(['/']);
      } else {
        this.router.navigate([this.loginRequestModel.moveTo]);
      }
    } else if (this.loginRequestModel.moveTo == 'channel-partner') {
      if (respUserType == Constants.UserType.channelPartner) {
        this.router.navigate([this.loginRequestModel.moveTo]);
      } else {
        errorLabel.className = "errorLabel show";
        errorLabel.innerHTML = "Not Authenticated As Dealer";
      }
    } else {
      if (!resp.error && resp.data != null) {
        this.router.navigate(['']);
      } else if (resp.error) {
        errorLabel.className = "errorLabel show";
        errorLabel.innerHTML = resp.msgList[0];
      }
    }
  }

  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  manageStrength(event: any, pwdStrength) {
    let password = this.registerForm.value.passwordSignUp
    if (password.length == 0) {
      pwdStrength.className = "pwdStrength";
    } else if (password.length < 6 && password.length > 0) {
      pwdStrength.className = "pwdStrength short";
      pwdStrength.innerHTML = "Too short";
    } else {
      let strength = this.getStrength(password, 0);
      //if value is less than 2
      if (strength < 2) {
        pwdStrength.className = "pwdStrength weak";
        pwdStrength.innerHTML = "Weak";
      } else if (strength == 2) {
        pwdStrength.className = "pwdStrength good";
        pwdStrength.innerHTML = "Good";
      } else {
        pwdStrength.className = "pwdStrength strong";
        pwdStrength.innerHTML = "Strong";
      }
    }
  }

  getStrength(password, strength) {
    //if length is 8 characters or more, increase strength value
    if (password.length > 7)
      strength += 1;
    //if password contains both lower and uppercase characters, increase strength value
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))
      strength += 1;
    //if it has numbers and characters, increase strength value
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/))
      strength += 1;
    //if it has one special character, increase strength value
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/))
      strength += 1;
    //if it has two special characters, increase strength value
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/))
      strength += 1;
    return strength;
  }

}
