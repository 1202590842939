import { Component, OnInit, OnDestroy } from '@angular/core';
import { LsMdDialog } from '../../../../LSNG/components/ls-dialog/ls-dialog.service';
import { MediaGalleryScreen } from '../../../../common/models/mediaGalleryScreen'
import { TemplateConstants } from '../../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-media-gallery-dialog',
  templateUrl: './media-gallery-dialog.component.html',
  styleUrls: ['./media-gallery-dialog.component.scss']
})
export class MediaGalleryDialogComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }
  dialog: LsMdDialog<MediaGalleryDialogComponent>
  dataList
  leftArrowVisibility: boolean = true
  rightArrowVisibility: boolean = true
  images: Array<MediaGalleryScreen> = []
  selectedImgIndex: number = 0
  resourceName: string = ''
  resourceTitle: string = ''
  currIndex: number = 0
  defaultImgPath = TemplateConstants.templateAssetsPath + '/images/default/noImage.jpg';

  constructor() { }

  ngOnInit() {
    if (this.dataList != undefined) {
      if (this.dataList.images != null && this.dataList.images.length > 0)
        this.images = this.dataList.images
      if (this.dataList.clickedImage)
        this.selectedImgIndex = this.dataList.clickedImage
    }
    this.currIndex = this.selectedImgIndex
    this.setImage(this.currIndex)
  }

  setImage(index){
    if(index >=0 && index < this.images.length){
      this.resourceName = this.images[index].resourceName
      this.resourceTitle = this.images[index].resourceTitle
    }
    if(index <= 0){
      this.leftArrowVisibility = false
      this.rightArrowVisibility = true
    }else if(index >= this.images.length-1){
      this.rightArrowVisibility = false
      this.leftArrowVisibility = true
    }else{
      this.rightArrowVisibility = true
      this.leftArrowVisibility = true
    }
  }

  closeDialog() {
    this.dialog.close(null)
  }

  leftArrowClicked() {
    if(this.currIndex > 0){
      this.currIndex -= 1
      this.setImage(this.currIndex)
    }
  }

  rightArrowClicked() {
    if(this.currIndex < this.images.length){
      this.currIndex += 1
      this.setImage(this.currIndex)
    }
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultImgPath;
  }

}
