<div class="contactUsLayout">
  <div class="pnlContact">
    <div class="pnlContactLeft">
      <p class="heading">CONTACT US</p>
      <div class="pnlContactLeftInner">
        <form  [formGroup]="contactUsForm" (ngSubmit)="submitContactUs()">
          <p class="label">Name:</p>
          <input type="text" formControlName="name" class="textFields" maxlength="60">

          <p class="label">Email ID:</p>
          <input type="email" formControlName="email" class="textFields" maxlength="255">

          <p class="label">Phone:</p>
          <input type="text" formControlName="phoneNumber" class="textFields" maxlength="10" minlength="10"  pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)"> 

          <p class="label">CIN:</p>
          <input type="text" formControlName="cin" class="textFields" maxlength="20" (keypress)="keyPressOnlyNumbers($event)">

          <p class="label">Location:</p>
          <input type="text" formControlName="location" class="textFields" maxlength="100">

          <p class="label">Pincode:</p>
          <input type="text" formControlName="pincode" class="textFields" maxlength="6" minlength="6"  pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)">

          <p class="label">Message:</p>
          <textarea formControlName="message" maxlength="500" class="textFields textarea"></textarea>
          <button class="button" [disabled]="!contactUsForm.valid">Send</button>          
        </form>
      </div>
    </div>
    <div class="pnlContactRight">
      <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != 'false'"></div>      
    </div>
  </div>
  <div *ngIf="errorFlag" class="page-not-found">
    <img [src]="img404Src != undefined?img404Src:defaultPath" class="page-not-found-img" (error)="imgErrorHandler($event)">
  </div>
</div>