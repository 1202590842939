import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit, Input, OnDestroy, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service'
import { BannerModel } from '../../../../../common/models/bannerModel'
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Constants } from '../../../../../common/constants/lsnetx.constants';
import { UserService } from '../../../../../services/user.service'
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { filter } from 'rxjs/operators';

export class CoOrdinates {
  xCoOrdinate: number
  yCoOrdinate: number
}

export class DIRECTIONS {
  static readonly NO_SWIPE = 'no_swipe'
  static readonly LEFT = 'left'
  static readonly RIGHT = 'right'
  static readonly UP = 'up'
  static readonly DOWN = 'down'

}
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { interval } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('carouselElement', [
      state('in', style({ left: '0' })),
      state('left', style({ left: '-100%' })),
      state('right', style({ left: '100%' })),
      state('out', style({ left: '100%' })),
      transition('* =>  in', [animate('0.5s ease-out')]),
      transition('in =>  left', [animate('0.5s ease-in')]),
      transition('in =>  right', [animate('0.5s ease-in')])
    ])
  ]
})
export class BannerComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input('height') height: string;
  @Input('width') width: string;
  imageTimeout: number = 70 * 60;
  // imageTimeout: number = 60;  
  carouselElements: Array<BannerModel>;
  showIndicators: boolean = true;

  @Output('clickAction') clickAction = new EventEmitter<number>()
  interval: any;
  currentUrl: string;
  currentTitle: string
  currentClickAction: string
  currentIndex: number = 0;
  indicators = [];
  lefts: Array<string> = ['0']
  states: Array<string> = ['in']
  categoryRelatedBanners: Array<BannerModel> = undefined
  banners: Array<BannerModel> = new Array()
  bannerHeight
  subCatUrl: string
  catUrl: string
  private _subscription
  isHome: boolean = true

  @ViewChildren('indicator') indicatorList;
  constructor(public homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.homePageService.getBannerOffer(true, (data) => {
      if (!data.error && data.data) {
        this.banners = data.data;
        let isDone: boolean = false;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data: NavigationEnd) => {
          this.currentUrl = data.url
          if (this.currentUrl == '/' || this.currentUrl == '/' + Constants.WAR_NAME) {
            this.isHome = true
          } else {
            this.isHome = false
          }
          if (this.currentUrl) {
            isDone = true;
            this.getBanners(this.currentUrl);
          }
        });
        if (!isDone) {
          isDone = true;
          this.currentUrl = this.router.url;
          if (this.currentUrl == '/' || this.currentUrl == '/' + Constants.WAR_NAME) {
            this.isHome = true
          } else {
            this.isHome = false
          }
          this.getBanners(this.currentUrl);
        }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.bannerHeight = '100vh'
    }
    if (this.bannerHeight > 400) {
      this.bannerHeight = 400 + 'px'
    }
    this.userService.updateCarousel(0);
  }

  getBanners(currUrl) {
    if (currUrl) {
      if (currUrl == '/' || currUrl == '/' + Constants.WAR_NAME) {
        this.userService.updateCarousel(0);
        this.categoryRelatedBanners = [];
        if (Constants.CATEGORY_MAP != undefined) {
          Constants.CATEGORY_MAP.forEach(category => {
            if (category.categoryId != '' && category.categoryId != undefined) {
              this.banners.forEach(banner => {
                if (banner.categoryId == category.categoryId) {
                  this.categoryRelatedBanners.push(banner)
                }
              })
            }
          })
          this.carouselElements = this.categoryRelatedBanners;
          if (isPlatformBrowser(this.platformId) && this.carouselElements && this.carouselElements.length > 1) {
            for (let i = 0; i < this.carouselElements.length; i++) {
              this.lefts.push('100%')
              this.states.push('out')
            }
            this._subscription = interval(this.imageTimeout).subscribe((x) => this.slideRight());
            for (let i = 0; i < this.carouselElements.length; i++) {
              this.indicators.push(i);
              this.showIndicators = true;
            }
          }
        }
      } else if (currUrl.includes('/collections')) {
        this.pauseCarousel();
        let urlCrumbs = currUrl.split('/')
        let indexOfParentRoute = urlCrumbs.indexOf('collections')
        if (urlCrumbs[indexOfParentRoute + 1] == 'offers' || urlCrumbs[indexOfParentRoute + 1] == 'new-product' || urlCrumbs[indexOfParentRoute + 1] == 'live-demo') {
          switch (urlCrumbs[indexOfParentRoute + 1]) {
            case 'offers':
              this.getDefaultBanners(8)
              break;
            case 'new-product':
              this.getDefaultBanners(10)
              break;
            case 'live-demo':
              this.getDefaultBanners(9)
              break;
            case 'default':
              this.getDefaultBanners(11)
              break;
          }
          this.indicators = []
        } else {
          let categoryId
          if (urlCrumbs[indexOfParentRoute + 1] != undefined || urlCrumbs[indexOfParentRoute + 1] != '') {
            this.catUrl = urlCrumbs[indexOfParentRoute + 1]
            if (urlCrumbs[indexOfParentRoute + 2] != undefined || urlCrumbs[indexOfParentRoute + 2] != '') {
              let subUrl = urlCrumbs[indexOfParentRoute + 2]
              if (subUrl && subUrl.includes('?')) {
                subUrl = subUrl.split("?")[0]
              }
              this.subCatUrl = urlCrumbs[indexOfParentRoute + 1] + '/' + subUrl
            }
          }
          this.categoryRelatedBanners = []
          this.categoryRelatedBanners = [];
          if (Constants.CATEGORY_MAP != undefined) {
            let subCatFound: boolean = false
            Constants.CATEGORY_MAP.forEach(category => {
              if (category.categoryURL == this.catUrl && category.categoryModels && category.categoryModels.length) {
                category.categoryModels.forEach(subCat => {
                  if (subCat.categoryURL == this.subCatUrl) {
                    categoryId = subCat.categoryId
                    subCatFound = true
                  }
                });
                if (!subCatFound) {
                  categoryId = category.categoryId
                }
              }
            })
            let singleBannerFlagForCategory: boolean = false
            this.banners.forEach(banner => {
              if (banner.categoryId != "" && banner.categoryId != undefined) {
                if (categoryId == banner.categoryId && !singleBannerFlagForCategory) {
                  this.categoryRelatedBanners.push(banner);
                  this.indicators = [];
                  singleBannerFlagForCategory = true;
                  this.showIndicators = false;
                  this.lefts = ['0'];
                  this.states = ['in'];
                }
              }
            })
          }
        }
      } else if (currUrl.includes('/search')) {
        this.pauseCarousel();
        this.getDefaultBanners(11)
      }
    }
  }

  ngAfterViewInit() {

  }

  getDefaultBanners(index) {
    let bannerUrl = TemplateConstants.templateAssetsPath + '/images/banner/' + 'banner' + index + '.jpg';
    let bannerModel = new BannerModel()
    bannerModel.imageName = bannerUrl
    this.categoryRelatedBanners = []
    this.categoryRelatedBanners.push(bannerModel)
    this.indicators = []
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  doSomething(event) {
    let href: string = this.banners[event].imageHref
    if (href != undefined && href != '' && isPlatformBrowser(this.platformId)) {
      if (href.indexOf('collections') > -1) {
        this.router.navigate(['collections' + href.split('collections')[1]])
      } else {
        if(isPlatformBrowser(this.platformId)){
          window.location.href = href;
        }
      }
    }
  }

  slideLeft(): void {
    //new code
    if (this.indicatorList != undefined) {
      let indicatorArr = this.indicatorList.toArray();
      if (indicatorArr[this.currentIndex] != undefined && indicatorArr[this.currentIndex].nativeElement != undefined) {
        indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
        indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
        this.states[this.currentIndex] = 'right'
        this.currentIndex = (this.currentIndex - 1 + this.carouselElements.length) % this.carouselElements.length;
        indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
        indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
        this.slideIn(this.currentIndex, 'left')
        this.userService.updateCarousel(this.currentIndex);
      }
    }
  }

  slideRight(): void {
    //new code
    if (this.indicatorList != undefined) {
      let indicatorArr = this.indicatorList.toArray();
      if (indicatorArr[this.currentIndex] != undefined && indicatorArr[this.currentIndex].nativeElement != undefined) {
        indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
        indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
        this.states[this.currentIndex] = 'left'
        this.currentIndex = (this.currentIndex + 1) % this.carouselElements.length;
        indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
        indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px';     
        this.slideIn(this.currentIndex, 'right')
        this.userService.updateCarousel(this.currentIndex);
      }
    }
  }

  pauseCarousel(): void {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  resumeCarousel(): void {
    if(isPlatformBrowser(this.platformId)){
      this._subscription = interval(this.imageTimeout).subscribe((x) => this.slideRight());
    }
  }

  openImage(index): void {
    let indicatorArr = this.indicatorList.toArray();
    indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
    indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    if (index < this.currentIndex) {
      this.slideIn(index, 'left')
      this.states[this.currentIndex] = 'right'
    } else if (index > this.currentIndex) {
      this.slideIn(index, 'right')
      this.states[this.currentIndex] = 'left'

    }
    this.currentIndex = index;
    indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
    indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
  }

  slideIn(index: number, direction: 'left' | 'right'): void {
    //prepare
    if (direction === 'left') {
      this.lefts[index] = '-100%'
      this.states[index] = 'left'
    } else {
      this.lefts[index] = '100%'
      this.states[index] = 'right'
    }
    if(isPlatformBrowser(this.platformId)){
      setTimeout(() => {
        this.states[index] = 'in'
      }, 100);
    }
  }

  current: number = 0
  handleClick(index: number): void {
    this.clickAction.emit(index)
  }

  private startCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  private endCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  touchHandler(event: TouchEvent, type: string): void {
    if (type === 'start') {
      this.startCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
    } else {
      this.endCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
      this.handleCarouselSwipe()
    }
  }

  private handleCarouselSwipe(): void {
    let direction = this.xDirectionFinder()
    if (direction != DIRECTIONS.NO_SWIPE) {
      if (direction === DIRECTIONS.LEFT) {
        this.slideLeft()
      } else {
        this.slideRight()
      }
      //this.handleCarouselSwipe()
    }
  }

  private xDirectionFinder(): string {
    if (Math.abs(this.startCordinates.xCoOrdinate - this.endCordinates.xCoOrdinate) < 20)
      return DIRECTIONS.NO_SWIPE
    if (this.startCordinates.xCoOrdinate <= this.endCordinates.xCoOrdinate) {
      return DIRECTIONS.LEFT
    } else {
      return DIRECTIONS.RIGHT
    }
  }

}
