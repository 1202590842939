import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { ProductModel } from '../../../common/models/productModel';
import { OrderQueryModel } from '../../../common/models/orderQueryModel';
import { TrackOrderService } from '../../../services/track-order.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  currUser
  currUserName
  dialog: LsMdDialog<QueryComponent>
  dataList
  orderQueryModel: OrderQueryModel = new OrderQueryModel()
  orderQueryForm: UntypedFormGroup
  queryNames = [
    { value: 'Shipment', label: 'Shipment' },
    { value: 'Refund', label: 'Refund' },
    { value: 'Cancel', label: 'Cancel' },
    { value: 'General', label: 'General' }
  ]

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,    
    public formBuilder: UntypedFormBuilder,
    private trackOrderService: TrackOrderService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,        
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)){
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.currUser) {
          if (this.currUser.fName)
            this.currUserName = this.currUser.fName
          if (this.currUser.fName && this.currUser.mName)
            this.currUserName = this.currUser.fName + ' ' + this.currUser.mName
          if (this.currUser.fName && this.currUser.mName && this.currUser.lName)
            this.currUserName = this.currUser.fName + ' ' + this.currUser.mName + ' ' + this.currUser.lName
        }
      }
  
      this.orderQueryForm = this.formBuilder.group({
        queryName: ["", [Validators.required]],
        desc: ["", [Validators.required]],
      })
    }      
  }

  closeDialog() {
    this.dialog.close(null);
  }

  orderQuerySubmit(event: Event) {
    this.orderQueryModel.userName = this.currUserName;
    this.orderQueryModel.orderId = parseInt(this.dataList.orderId);
    this.orderQueryModel.mobileNo = this.currUser.mobile;
    if(this.orderQueryForm){
      this.orderQueryModel.queryName = this.queryNames[this.orderQueryForm.value.queryName[0]].label;
      this.orderQueryModel.desc = this.orderQueryForm.value.desc;
    }
    this.trackOrderService.getOrderQueryInfo(this.orderQueryModel, (resp) => {
      if(resp){
        if(!resp.error){
          this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          });
          this.closeDialog();
        }else{
          this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          });
        }
      }
    })
  }

}
