import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-cancelled',
  templateUrl: './order-cancelled.component.html',
  styleUrls: ['./order-cancelled.component.scss']
})
export class OrderCancelledComponent implements OnInit {

  constructor() { }

  ngOnInit(){
  }

}
