import { Component, OnInit, ViewChildren, QueryList, ElementRef, Inject, PLATFORM_ID, Input, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CategoryService } from '../../../services/category.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { HomePageService } from '../../../services/home-page.service';
import { CurrencyModel } from '../../../common/models/currency-model';
import { CategoryModel } from '../../../common/models/category-model';
import { UserModel } from '../../../common/models/user-model';
import { LoginComponent } from '../../components/login/login.component';
import { LoginService } from '../../../services/login.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { UserService } from '../../../services/user.service';
import { CartCountService } from '../../../services/cart-count.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  // categoryList: Array<CategoryModel>;
  // currencyList: Array<CurrencyModel>
  headerDialog: LsMdDialog<CategoryComponent>
  loggedIn: boolean
  userModel: UserModel
  userName: string
  userEmail: string
  showCurrency: boolean
  dialog: LsMdDialog<CategoryComponent>;
  selectedCurrencyIndex: number
  wishlistCount: number = 0
  _catSubscription;

  @ViewChildren("frstLevel") firstLevelEle: QueryList<ElementRef>;
  @ViewChildren("secLevel") secondLevelEle: QueryList<ElementRef>;
  @Input('currencyMap') currencyList: Array<CurrencyModel>
  @Input('categoryList') categoryList: Array<CategoryModel>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private categoryService: CategoryService,
    private dialogService: LsDialogService<LoginComponent>,
    private homePageService: HomePageService,
    private loginService: LoginService,
    private userService: UserService,
    private cartCountService: CartCountService
  ) { }

  ngOnInit() {
    // this.getCategories();
    // this.fetchCurrencies();
    if (isPlatformBrowser(this.platformId)) {
      this.userService.userStateModel.subscribe((userModel) => {
        this.userModel = userModel;
        if (Object.keys(userModel).length != 0) {
          this.userEmail = this.userModel.email
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
      if (window.localStorage.getItem('currentUser') != null) {
        this.getWishlistCartCount();
      }
      if (sessionStorage.getItem('currencyId') != null || sessionStorage.getItem('currencyId') != undefined) {
        this.selectedCurrencyIndex = parseInt(sessionStorage.getItem('currencyId')) - 1;
      } else {
        this.selectedCurrencyIndex = 0;
      }
    }
  }

  ngOnDestroy() {
    if (this._catSubscription) {
      this._catSubscription.unsubscribe();
    }
  }

  getWishlistCartCount() {
    this.cartCountService.wishlistCart.subscribe((cart) => {
      let wishlistCart = cart;
      if (wishlistCart) {
        if (wishlistCart.cartProductModels && wishlistCart.cartProductModels.length > 0) {
          this.wishlistCount = wishlistCart.cartProductModels.length;
        } else {
          this.wishlistCount = 0;
        }
      } else {
        if(isPlatformBrowser(this.platformId)){
          if (JSON.parse(localStorage.getItem('wishlist'))) {
            let localCart = JSON.parse(localStorage.getItem('wishlist'));
            if (localCart.cartProductModels && localCart.cartProductModels.length > 0) {
              this.wishlistCount = localCart.cartProductModels.length;
            }
          } else {
            this.wishlistCount = 0;
          }
        }
      }
    });
  }

  // getCategories() {
  //   this._catSubscription = this.homePageService.allCategoriesObservable.subscribe((data) => {
  //     this.categoryList = data;
  //     Constants.CATEGORY_MAP = data;
  //   })
  // }

  showSubCat(index, $event) {
    let ele = this.firstLevelEle.toArray()[index];
    if ($event.target.className.includes('mob-arrow-right')) {
      $event.target.className = "mob-arrow-down";
    } else {
      $event.target.className = "mob-arrow-right";
    }
    if (ele.nativeElement.className.includes("show-sub-cat")) {
      ele.nativeElement.className = "itrCat";
    } else {
      ele.nativeElement.className = "itrCat show-sub-cat"
    }
  }

  showSecondCat(parentIdx, idx, event) {
    let absIndex = 0;
    for (let i = 0; i < parentIdx; ++i) {
      absIndex += this.categoryList[i].categoryModels.length;
    }

    let secLevelIndex = absIndex + idx;

    for (let j = idx; j >= 0; --j) {
      if (this.categoryList[parentIdx].categoryModels[j].categoryModels == undefined || this.categoryList[parentIdx].categoryModels[j].categoryModels.length == 0) {
        --secLevelIndex;
      }
    }

    let ele = this.secondLevelEle.toArray()[secLevelIndex];
    if (event.target.className.includes('arrow-upleft')) {
      event.target.className = "arrow-upRight";
    } else {
      event.target.className = "arrow-upleft";
    }
    if (ele.nativeElement.className.includes("show-sec-cat")) {
      ele.nativeElement.className = "itrCat secCat";
    } else {
      ele.nativeElement.className = "itrCat secCat show-sec-cat";
    }
  }


  showLoginPopup() {
    Constants.MENU_BAR_NAV.toggle();
    this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(resp => {

    })
  }

  toggleMenu() {
    Constants.MENU_BAR_NAV.toggle();
  }

  fetchCurrencies() {
    this.homePageService.getCurrencyDetails(resp => {
      if (resp.data != undefined && resp.data.length > 1) {
        this.showCurrency = true;
        this.currencyList = resp.data;
        let currMap: Array<{ 'id': any, 'code': any }> = []
        this.currencyList.forEach(ele => {
          currMap.push({
            id: ele.currencyId,
            code: ele.currencyCode
          })
        })
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('currencyMap', JSON.stringify(currMap));
        }
      }
    });
  }

  logOut() {
    var maintainLocalCart = false;
    this.loginService.logOut(maintainLocalCart, resp => {
      this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
      })
    });
  }

  updateCurrency(selectedCurrencyIndex: number, currencyOption) {
    this.selectedCurrencyIndex = selectedCurrencyIndex;
    let currencyId = this.currencyList[this.selectedCurrencyIndex].currencyId;
    currencyOption.className = "currency-option";
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('currencyId', currencyId + "");
      window.location.reload();
    }
  }

  showCategories(event) {
    if (event.target.className.includes('cat-lbl')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat cat-lbl";
      } else {
        event.target.className = "show-cat cat-lbl";
      }
    } else if (event.target.className.includes('shop-label')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat cat-lbl";
      } else {
        event.target.parentElement.className = "show-cat cat-lbl";

      }
    }
  }

  showCurrencyList(event) {
    if (event.target.className.includes('curr-lbl')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat curr-lbl";
      } else {
        event.target.className = "show-cat curr-lbl";
      }
    } else if (event.target.className.includes('curr-choose')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat curr-lbl";
      } else {
        event.target.parentElement.className = "show-cat curr-lbl";

      }
    }
  }

  showAccInfo(event) {
    if (event.target.className.includes('my-acc')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat my-acc";
      } else {
        event.target.className = "show-cat my-acc";
      }
    } else if (event.target.className.includes('acnt-label')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat my-acc";
      } else {
        event.target.parentElement.className = "show-cat my-acc";

      }
    }
  }
}
