import { Component, OnInit, OnDestroy } from '@angular/core';
import { LsMdDialog } from '../../../../LSNG/components/ls-dialog/ls-dialog.service';
import { TemplateConstants } from '../../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-product-img-dialog',
  templateUrl: './product-img-dialog.component.html',
  styleUrls: ['./product-img-dialog.component.scss']
})
export class ProductImgDialogComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  dialog: LsMdDialog<ProductImgDialogComponent>
  dataList
  imageThumbnailsList
  imageSrc
  imageUrl: string
  imageList
  productName: string
  leftcheck: number = 0
  leftUpdatedValue: number = 0
  selectedImg: string
  isImages: boolean
  isSharingDialog: boolean
  socialShareFbUrl: string
  socialShareGoogleUrl: string
  socialShareLinkedInUrl: string
  socialShareTwitterUrl: string
  isFbHash: boolean
  isGoogleHash: boolean
  isLinkedInHash: boolean
  isTwitterHash: boolean
  isFirstImg: boolean = true
  disableZoomLeft: boolean
  disableZoomRight: boolean
  imageIndex: number = 0
  defaultPath = TemplateConstants.defaultImgPath;

  constructor() { }

  ngOnInit() {
    this.isImages = this.dataList.isImages
    this.isSharingDialog = this.dataList.isSharingDialog
    if (this.dataList.isImages) {
      this.imageUrl = this.dataList.imageUrl
      this.imageList = this.dataList.imagesList
      this.imageThumbnailsList = this.dataList.imageThumbnailsList
      this.productName = this.dataList.productName
      this.selectedImg = this.dataList.selectedImg
      this.imageIndex = this.dataList.selectedImgIdx
      this.updateImageSrc(this.imageIndex);
    } else if (this.dataList.isSharingDialog) {
      this.isFbHash = this.dataList.isFb
      this.isGoogleHash = this.dataList.isGoogle
      this.isLinkedInHash = this.dataList.isLinkedIn
      this.isTwitterHash = this.dataList.istwitter
      this.socialShareFbUrl = this.dataList.fbUrl
      this.socialShareGoogleUrl = this.dataList.googleUrl
      this.socialShareLinkedInUrl = this.dataList.linkedInUrl
      this.socialShareTwitterUrl = this.dataList.twitterUrl
    }
    this.setZoomSlideIcons()
  }

  closeDialog() {
    this.dialog.close(null)
  }

  setZoomSlideIcons() {
    if (this.imageIndex > 0 && this.imageIndex < this.imageList.length - 1) {
      this.disableZoomRight = false
      this.disableZoomLeft = false
    } else if (this.imageIndex == 0) {
      this.disableZoomLeft = true
      this.disableZoomRight = false
    } else if (this.imageIndex == this.imageList.length - 1) {
      this.disableZoomLeft = false
      this.disableZoomRight = true
    }
  }

  zoomSlideRight() {
    if (this.imageIndex < this.imageList.length - 1) {
      this.disableZoomLeft = false
      this.imageIndex += 1
      this.imageSrc = this.imageUrl + 'l/' + this.imageList[this.imageIndex]
      // if (this.imageIndex == this.imageList.length - 1) {
      //   this.disableZoomRight = true
      // } else {
      //   this.disableZoomRight = false
      // }
      this.setZoomSlideIcons()
    }
  }

  zoomSlideLeft() {
    if (this.imageIndex > 0) {
      this.disableZoomRight = false
      this.imageIndex -= 1
      this.imageSrc = this.imageUrl + 'l/' + this.imageList[this.imageIndex]
      // if (this.imageIndex == 0) {
      //   this.disableZoomLeft = true
      // } else {
      //   this.disableZoomLeft = false
      // }
      this.setZoomSlideIcons()
    }
  }

  updateImageSrc(index) {
    this.disableZoomLeft = false
    this.disableZoomRight = false
    this.imageSrc = ''
    if (this.selectedImg != undefined && this.isFirstImg) {
      this.imageSrc = this.selectedImg
      this.isFirstImg = false
    }
    else
      this.imageSrc = this.imageUrl + '/l/' + this.imageList[index];
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
