import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router'
import { ProductModel } from '../../../common/models/productModel'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartService } from '../../../services/cart.service';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { UserModel } from '../../../common/models/user-model';
import { Constants } from '../../../common/constants/lsnetx.constants'
import { LoginService } from '../../../services/login.service';
import { LoginRequestModel } from '../../../common/models/loginRequestModel';
import { CartCountService } from '../../../services/cart-count.service'
import { CartModel } from '../../../common/models/cartModel';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { GeneralSettingsModel } from '../../../common/models/generalSettingsModel';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit, OnChanges, OnDestroy {

  ngOnDestroy(){
    
  }

  onProductTile: boolean
  ifNew: boolean = false
  specialColltn: boolean = false
  priceRoundOffInteger: string = '1.2';
  showSaving: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialogService: LsDialogService<LoginComponent>,
    public cartService: CartService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public loginService: LoginService,
    public cartCountService: CartCountService

  ) {
    this.onProductTile = false;
  }

  @Input('tile-data') data: ProductModel = new ProductModel()
  @Input('specialColl') specialColl: boolean
  @Input()
  set view(viewVal: string) {
    this.onProductTile = (viewVal == "list");
  }
  prodData: ProductModel = new ProductModel()
  productName: string
  @Input('canCompare') canCompare: boolean
  @Input('onRemoveCompare') onRemoveCompare: string

  @Output() onSelectingCompare: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToCartWishlist: EventEmitter<any> = new EventEmitter<any>();

  currType: string = "INR"
  target: string = ''
  sizeRec: string = ''
  variantData: Array<{ variantHeading: string, variantArr: Array<{ variantName: string, variantId: Array<number> }> }> = []
  defaultPath = TemplateConstants.defaultImgPath;
  ratingValue: number

  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  variants: Array<ProductModel> = new Array()
  showVariants: boolean = false
  defaultClass: boolean = true

  currUser: UserModel
  cartProductMdel = new CartProductModel();
  currUserEmail
  currTitleId
  currVariantId
  queryParams
  allowCart: boolean
  allowWishlist: boolean
  checked: boolean
  generalSettings: GeneralSettingsModel;

  ngOnInit() {
    if (this.data) {
      this.prodData = this.data;
      this.showSaving = this.data.showSaving;
      // if (this.data.variantList && this.data.variantList.length > 0) {
      //   this.prodData = this.data.variantList[0]
      // } else {
      //   this.prodData = this.data
      // }
      this.productName = this.data.productName
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.currUserEmail = this.currUser.email;
      }
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      if (Constants.CART_SETTINGS != undefined) {
        if (Constants.CART_SETTINGS.allowCart != undefined) {
          if (Constants.CART_SETTINGS.allowCart == 1)
            this.allowCart = true;
          else if (Constants.CART_SETTINGS.allowCart == 0)
            this.allowCart = false;
        }
        if (Constants.CART_SETTINGS.allowWishList != undefined) {
          if (Constants.CART_SETTINGS.allowWishList == 1)
            this.allowWishlist = true;
          else if (Constants.CART_SETTINGS.allowWishList == 0)
            this.allowWishlist = false;
        }
      }
      if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
          this.generalSettings = Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO ;
          if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0'
          } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2'
          }
        }
      }
    }
  }

  ngOnChanges() {
    this.setUrl()
    // this.getVariants()
    if (this.data != undefined) {
      this.variants = this.data.variantList
      if (this.data.releaseDate != null) {
        let releaseDate = this.data.releaseDate
        let todayDate = new Date()
        if (releaseDate > todayDate) {
          this.ifNew = true
        }
      }
    }
    if (this.specialColl != undefined) {
      this.specialColltn = this.specialColl
    }
    if (this.onRemoveCompare != undefined) {
      if (this.data.titleId == this.onRemoveCompare) {
        this.checked = false
      }
    }
  }


  compare($event, titleId, productModel) {
    if (!this.canCompare) {
      if ($event.target.checked) {
        $event.preventDefault()
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Cannot compare more than three products').subscribe(response => { });
      } else {
        let compareProList: Array<{ titleId: number, productModel: ProductModel, event }> = []
        compareProList.push({
          titleId: titleId,
          productModel: productModel,
          event: $event.target.checked
        })
        this.onSelectingCompare.emit(compareProList)
      }
    } else {
      let compareProList: Array<{ titleId: number, productModel: ProductModel, event }> = []
      compareProList.push({
        titleId: titleId,
        productModel: productModel,
        event: $event.target.checked
      })
      this.onSelectingCompare.emit(compareProList)
    }
  }

  setUrl() {
    if (this.data != undefined) {
      let url: string = this.data.productURLText != undefined ? this.data.productURLText : ''
      this.target = 'details/' + url
      this.queryParams = { 't': this.data.titleId }
    }
  }

  getVariants() {
    if (this.data == undefined)
      return
    if (this.data.variantAttrMap != undefined) {
      let tempVariantArr = this.data.variantAttrMap;
      Object.keys(tempVariantArr).forEach(key => {
        let filters = tempVariantArr[key]
        let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
        Object.keys(filters).forEach(element => {
          let idxArr: Array<number> = []
          filters[element].forEach(idxValue => {
            idxArr.push(idxValue);
          });
          filtersArr.push({
            option: element,
            mappedUniqueIds: idxArr
          })
        })
        this.variantTypes = new Array()
        this.variantTypes.push({
          variantHeading: key,
          options: filtersArr
        })
      })
      this.variantTypes.slice(0, 1)
    }
  }


  setProductVariant(event, variantId) {
    this.defaultClass = false
    let liList: Array<HTMLElement> = event.target.parentElement.parentElement.getElementsByTagName('li')
    for (var index = 0; index < liList.length; index++) {
      var element = liList[index]
      element.classList.remove('filterSelected')
    }
    event.target.parentElement.classList.add('filterSelected')
    let variant: ProductModel = new ProductModel();
    this.variants.forEach(element => {
      if (element.id == variantId) {
        variant = element
      }
    });
    if (variant.imageUrl != undefined && variant.imageUrl != "") {
      this.data.imageUrl = variant.imageUrl
    }
    if (variant.mrp != undefined) {
      this.data.mrp = variant.mrp
    }
    if (variant.salePrice != undefined) {
      this.data.salePrice = variant.salePrice
    }
    this.queryParams = { 't': this.data.titleId, 'sizeRecNum': variant.id }
    this.currVariantId = variant.id;
  }

  addToWishList() {     
      if(!isPlatformBrowser(this.platformId)) {       return    }
    // this.cartProductMdel.isFromCart = false;
    // this.cartProductMdel.titleId = parseInt(this.data.titleId);
    // this.cartProductMdel.numCopies = 1;
    // if (window.localStorage.getItem('currentUser') !== null) {
    //   let wishlistCart = this.wishlistCart;
    //   wishlistCart.cartProductModels.push(this.cartProductMdel);
    //   this.cartService.computeCart(wishlistCart, (resp) => {
    //     if (!resp.error) {
    //       if (resp.data) {
    //         this.cartCountService.updateWishlistCart(resp.data);
    //         let msg = 'Added to your Wishlist!';
    //         this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
    //       }
    //     } else {
    //       this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to wishlist!');
    //     }
    //   });
    // } else {
    //   this.goToLogin(Constants.LOGIN_AFTER_ACTION.ADD_TO_WISHLIST, this.cartProductMdel);
    // }
    this.addToCartWishlist.emit(this.data);
  }

  addToCartProduct() {
    this.addToCart.emit(this.data.titleId);
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0 });
    }
  }

}

