import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { isPlatformBrowser } from '@angular/common';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-okay-message',
  templateUrl: './okay-message.component.html',
  styleUrls: ['./okay-message.component.scss']
})
export class OkayMessageComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  msg;
  dialog: LsMdDialog<OkayMessageComponent>
  dataList;
  showCustCare: boolean = false;
  curCareImgPath = TemplateConstants.templateAssetsPath + '/images/custCare.jpg';

  constructor(
    @Inject(PLATFORM_ID) private platformId : Object,
  ) { }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      this.msg = this.dataList;
    if (this.msg == 'custCare') {
      this.showCustCare = true
      setTimeout(() => {
        this.closeDialog()
      }, 10000);
    }
    }
  }

  closeDialog() {
    this.dialog.close(null);
  }

}
