import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SuggestionOption } from '../../../LSNG/components/ls-suggestion-field/suggestion-option';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-test-suggestion',
  template: `
    <div>
      <a *ngIf="!isCategory" class="nonCat" href="details/{{value}}">
        <p class="catName">{{addlData}}</p>
        <p class="seperator">|</p>
        <p>{{label}}</p>
      </a>
      <a *ngIf="isCategory" class="isCat" href= "collections/{{value}}">{{label}}</a>      
    </div>

  `,
  styleUrls: ['./search.component.scss']
})
export class SuggestionOptionComponent implements OnInit, SuggestionOption, OnDestroy {

  ngOnDestroy(){
    
  }

  built = false;
  label = "";
  value = "";
  json: any;
  isCategory: boolean
  addlData: ''

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  buildComponent(json: any): void {
    this.built = true;
    this.label = json.label;
    this.value = json.value;
    this.isCategory = json.categoryLink
    this.addlData = json.addlData
    this._cdr.detectChanges();
  }



}
