<div class="mediaTabWrapper">
  <p class="heading">MEDIA GALLERY</p>
  <div class="mediaTabPanel">
    <div class="tabPanelParent">
      <ul>
        <li class="tab-link-div" (click)="tabClicked(i)" *ngFor="let tab of tabs; let i = index;">
          <a class="tab-link" [ngClass]="{'selected-tab': i == selectedTabIndex}">
            <span>{{tab}}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div class="selectYear" [ngClass]="{'hide': selectedTabIndex == 1}">
        <select class="textFields" (change)="getMediaImagesForYear($event.target.value)">
          <option *ngFor="let item of yearOptions" [value]="item.value">{{item.label}}</option>                  
        </select>
      </div>
      <div class="images hideTab" [ngClass]="{'show': selectedTabIndex == 0}">
        <ul>
          <li class="media-images-tab" *ngFor="let mediaImage of mediaImages; let i = index;" (click)="showMediaDialog(i)">
            <img [src]="mediaImage.resourceName" class="img" alt="{{mediaImage.resourceTitle}}" (error)="imgErrorHandler($event)">
            <div class="image-hover-panel">
              <p class="imageTitle">{{mediaImage.resourceTitle}}</p>
              <p class="imageDate">{{mediaImage.date}}</p>
            </div>
          </li>
        </ul>
        <div class="pnlShowMore">
          <a class="lnkShowMoreProducts" *ngIf="imageHasMore" (click)="imageShowMoreMedia()">Show More Media</a>
        </div>
        <p *ngIf="imageErrorPage">No Results Found</p>
      </div>
      <div class="videos hideTab" [ngClass]="{'show': selectedTabIndex == 1}">
        <ul>
          <li class="media-video-tab" *ngFor="let mediaVideo of mediaVideos">
            <div class="snippet">
              <iframe class="mediaVideoes" [src]="mediaVideo.resourceName | safeUrl" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
          </li>
        </ul>
        <div class="pnlShowMore">
          <a class="lnkShowMoreProducts" *ngIf="videoHasMore" (click)="videoShowMoreMedia()">Show More Media</a>
        </div>
        <p *ngIf="videoErrorPage">No Results Found</p>
      </div>
    </div>
  </div>
</div>
<app-brands></app-brands>