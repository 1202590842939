<div class="forgot-pass-word">
  <p class="heading"> FORGOT PASSWORD?</p>
  <form [formGroup]="forgotPwdForm" (ngSubmit)="forgotPwdRequest($event)">
    <div class="forgotPwdCntnt">
      <div class="pwdPnl">
        <p class="lblPwd">
          To reset your password, enter the Email Id you use to login:
        </p>
      </div>
      <div class="pnlEmailForgotPwd">
        <input type="text" formControlName="email" tabindex="1" placeholder="Enter Email ID" class="textFields" maxlength="255">
      </div>
      <div class="forgotPwdBtnPnl">
        <div class="pnlCancelBtn">
          <button class="button" type="submit" tabindex="2">Submit</button>
        </div>
        <div class="pnlCancelBtn">
          <button class="button" type="submit" tabindex="3" (click)="navigateToHome()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>