export class CareerModel {
    resumeId: number;
    fullName: string;
    contactNo: string;
    emailId: string;
    workExperience: string;
    organisation: string;
    designation: string;
    department: string;
    currentCTC: string;
    expectedCTC: string;
    attachedResume: string;
    resumeFile: Array<String>
}