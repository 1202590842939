import { Component, OnInit, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-gift-wrap',
  templateUrl: './gift-wrap.component.html',
  styleUrls: ['./gift-wrap.component.scss']
})
export class GiftWrapComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }

  constructor() { }

  ngOnInit() {
  }

}
