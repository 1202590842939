<div class="careerOuterPnl">
      <p class="heading">CAREER</p>
      <div class="careerFormPnl">
        <form  [formGroup]="careerForm" (ngSubmit)="submitCareer(errorLabel)">
          <p class="label">Full Name*</p>
          <input type="text" formControlName="name" class="textFields" (keypress)="keyPressOnlyChars($event)" maxlength="60">

          <p class="label">Contact No.*</p>
          <input type="text" formControlName="phoneNumber" class="textFields" maxlength="10" minlength="10"  pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)"> 

          <p class="label">Email ID*</p>
          <input type="email" formControlName="email" class="textFields" maxlength="255">

          <p class="label">Total Work Experience*</p>
          <input type="text" formControlName="totalWorkExp" class="textFields" maxlength="60">

          <p class="label">Current Organisation*</p>
          <input type="text" formControlName="currOrg" class="textFields" maxlength="60">

          <p class="label">Current Designation*</p>
          <input type="text" formControlName="currDest" class="textFields" maxlength="60">

          <p class="label">Department*</p>
          <select class="textFields" formControlName="department">
            <option *ngFor="let item of deptOptions" [value]="item.value">{{item.label}}</option>                  
          </select>

          <p class="label">Current CTC*</p>
          <input type="text" formControlName="currCtc" class="textFields" maxlength="100">

          <p class="label">Expected CTC*</p>
          <input type="text" formControlName="expCtc" class="textFields" maxlength="100">

          <p class="label">Upload Resume <br> (word doc/ PDF format) *</p>
          <div class="upload-file">
            <input type="file" class="file-upload" formControlName="file" (change)="onFileChange($event)">
          </div>
          <p class="errorLabel" #errorLabel></p>          
          <div class="btnPnl">
            <button class="button" [disabled]="!careerForm.valid">Submit</button>          
            <button  type="reset" class="button resetBtn">Reset</button>                      
          </div>
        </form>
      </div>
</div>