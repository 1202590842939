import { NgModule } from '@angular/core';
import { OfferComponent } from '../home/home-page/homeComponents/offer/offer.component';
import { SharedModuleModule } from '../../shared-module/shared-module.module';
import { HomeProductsComponent } from '../home/home-page/homeComponents/home-products/home-products.component';
import { ProductTileComponent } from '../commonComponent/product-tile/product-tile.component';
import { CategoryComponent } from '../commonComponent/category/category.component';
import { BannerComponent } from '../home/home-page/homeComponents/banner/banner.component';
import { PopUpTriggerDirective } from '../commonComponent/pop-up/pop-up-trigger.directive';
import { PopUpComponent } from '../commonComponent/pop-up/pop-up.component';
import { DialogConfig } from '../common-data/dialog.config';
import { ReturnProductComponent } from '../dashboard/my-orders/return-product/return-product.component';
import { PaginationComponent } from '../commonComponent/pagination/pagination.component';

@NgModule({
    imports: [
        SharedModuleModule
    ],
    declarations: [
        OfferComponent,
        HomeProductsComponent,
        ProductTileComponent,
        CategoryComponent,
        BannerComponent,
        PopUpTriggerDirective,
        PopUpComponent,
        ReturnProductComponent,
        PaginationComponent
    ],
    exports: [
        OfferComponent,
        HomeProductsComponent,
        ProductTileComponent,
        CategoryComponent,
        BannerComponent,
        PopUpTriggerDirective,
        PopUpComponent,
        ReturnProductComponent,
        PaginationComponent
    ],
    providers: [
        DialogConfig
    ]
})
export class FeaturesModule { }
