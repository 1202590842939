<div class="pnlOrderQueryOuter">
  <div class="innerBdy">
    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="header">
      <p class="headingLbl">Order Query</p>
    </div> -->
    <span class="close fa fa-remove" (click)="closeDialog()"></span>    
    <div class="formBdy">
      <form  [formGroup]="orderQueryForm" (ngSubmit)="orderQuerySubmit($event)">
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryOrderId orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>OrderId</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{dataList.orderId}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryName orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Name</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUserName}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryEmail orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Email</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUser.email}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryPhoneNum orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Phone</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUser.mobile}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryComboOuter">
          <div fxLayout="start center" class="label">
            <p>Query</p>
          </div>
          <div fxLayout="start center" class="value">
            <ls-combo ngModel [multiple]=false  [options]="queryNames" formControlName="queryName" placeholder="Select Query"></ls-combo>                
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryComment">
          <div fxLayout="start center" class="label">
            <p>Comment</p>
          </div>
          <div fxLayout="start center" class="value">
            <textarea formControlName="desc"></textarea>
          </div>
        </div>
        <div fxLayoutAlign="center center" class="pnlOrderQueryButton">
          <div class="sendbtn">
            <button type="submit" [disabled]="!orderQueryForm.valid">Send</button>
          </div>
          <div class="cancelBtn">
            <button type="reset" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>