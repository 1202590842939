export class ServiceSupportDTO {
    serviceId: number;
    serviceType: number;
    productName: string;
    modelNo: string;
    name: string;
    contactNo: string;
    state: string;
    city: string;
    pincode: string;
    locality: string;
    address: string;
    landmark: string;
    complaint: string;
}