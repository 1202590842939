import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from 'src/app/LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';

@Component({
  selector: 'app-product-registered-successfully',
  templateUrl: './product-registered-successfully.component.html',
  styleUrls: ['./product-registered-successfully.component.scss']
})
export class ProductRegisteredSuccessfullyComponent implements OnInit {

  productRegisteredMsgImg = "/assets/templates/template_0014/images/productRegistered.jpg";
  dialog: LsMdDialog<OkayMessageComponent>

  constructor() { }

  ngOnInit(): void {
    let ele = document.getElementById('productRegisterSuccesfully');
    ele.style.backgroundImage = this.productRegisteredMsgImg;
  }

  closeDialog() {
    this.dialog.close(null);
  }

}
