import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { GetReviewListService } from '../../../services/get-review-list.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserModel } from '../../../common/models/user-model';
import { ReviewAvgModel } from '../../../common/models/reviewAvgModel';
import { ReviewModel } from '../../../common/models/reviewModel';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit, OnDestroy {

  ngOnDestroy(){
    
  }
  dialog: LsMdDialog<ProductReviewComponent>;
  lblAddReview = "Add Reviews/Rating"
  note = "*Either Review or Rating is Mandatory"
  lblTitle = "Title*:"
  lblReview = "Review*:"
  lblProductRating = "Rating*:"
  lblRating1 = "Design:"
  lblRating2 = "Innovation:"
  lblRating3 = "Ease of Use:"
  lblRating4 = "Features:"
  lblRating5 = "Performance:"
  lblRating6 = "Quality:"
  lblButton = "Update Review"
  data //data sent to parent
  addReviewForm: UntypedFormGroup
  pageNum = 1
  numRecords = 28
  ratingAndReviewData: ReviewAvgModel
  titleId
  variantId
  userId
  reviewId
  currUser: UserModel
  edit = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public formBuilder: UntypedFormBuilder,
    public getReviewListService: GetReviewListService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.addReviewForm = this.formBuilder.group({
        title: ["", [Validators.required]],
        review: [""],
        productRating: [""],
        rating1: [""],
        rating2: [""],
        rating3: [""],
        rating4: [""],
        rating5: [""],
        rating6: [""],
      })
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.userId = this.currUser.userId;
      }
      this.titleId = this.activatedRoute.snapshot.queryParamMap.get("t").toString();
      if (this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
        this.variantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString();
      } else {
        this.variantId = "";
      }
      this.checkForEdit();
    }
  }

  closeDialog() {
    this.dialog.close(this.data);
  }

  checkForEdit() {
    this.getReviewListService.getReviewList(this.activatedRoute.snapshot.queryParamMap.get("t").toString(), this.pageNum, this.numRecords, (data) => {
      if (data != undefined) {
        this.ratingAndReviewData = data.data;
        if (this.ratingAndReviewData && this.ratingAndReviewData.reviewModels) {
          this.ratingAndReviewData.reviewModels.forEach(model => {
            if (model.userId == this.userId) {
              this.edit = true;
              this.setData(model);
            }
          })
        }
      }
    })
  }

  setData(model: ReviewModel) {
    this.addReviewForm.setValue({
      title: model.reviewTitle,
      review: model.reviewText,
      productRating: model.productRating,
      rating1: model.rating1,
      rating2: model.rating2,
      rating3: model.rating3,
      rating4: model.rating4,
      rating5: model.rating5,
      rating6: model.rating6,
    })
    this.reviewId = model.reviewId;
  }

  addReviewSubmit($event, errorLabel) {
    let reviewModel: ReviewModel = new ReviewModel();
    if (this.addReviewForm.value.title != "" && (this.addReviewForm.value.review != "" || this.addReviewForm.value.productRating != "")) {
      errorLabel.className = ""
      reviewModel.reviewTitle = this.addReviewForm.value.title
      reviewModel.reviewText = this.addReviewForm.value.review
      reviewModel.productRating = this.addReviewForm.value.productRating
      if (this.addReviewForm.value.rating1)
        reviewModel.rating1 = this.addReviewForm.value.rating1
      if (this.addReviewForm.value.rating2)
        reviewModel.rating2 = this.addReviewForm.value.rating2
      if (this.addReviewForm.value.rating3)
        reviewModel.rating3 = this.addReviewForm.value.rating3
      if (this.addReviewForm.value.rating4)
        reviewModel.rating4 = this.addReviewForm.value.rating4
      if (this.addReviewForm.value.rating5)
        reviewModel.rating5 = this.addReviewForm.value.rating5
      if (this.addReviewForm.value.rating6)
        reviewModel.rating6 = this.addReviewForm.value.rating6

      reviewModel.userId = this.userId
      reviewModel.titleId = this.titleId
      reviewModel.productVariantId = this.variantId
      reviewModel.reviewId = this.reviewId

      this.getReviewListService.addReview(reviewModel, (resp) => {
        if (!resp.error) {
          if (resp.data != null) {
            this.dialog.close(null);
            this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_SUCCESSFUL_UPDATED).subscribe(response => {
              if (isPlatformBrowser(this.platformId)) {
                window.location.reload();
              }
            });
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_UPDATION_UNSUCCESSFUL).subscribe(response => {
          });
          this.dialog.close(null)
        }
      })
    } else {
      errorLabel.className = "showError"
    }
  }

}
