<div class="offer">
  <div class="pnlOffer">
    <ul class="offerImgItr">
      <li *ngFor="let offer of offers">
        <a [routerLink]="offer.imageHref" class="lnkOfferImg" *ngIf="(offer.videoURL == undefined || offer.videoURL  == null) && (offer.imageName != undefined || offer.imageName != '')">
          <img class="imgOffer" title="Inalsa video" [src]="offer.imageName" alt="Inalsa video" (error)="offerImgErrorHandler($event)">
        </a>
        <!-- <div class="offerVideoSnippet" *ngIf="offer.videoURL != undefined && offer.videoURL != ''">
          <iframe class="offerVideo" allowfullscreen="" webkitallowfullscreen="" [src]="offer.videoURL | safeUrl" frameborder="0"></iframe>
        </div> -->

        <div class="offerVideoSnippet" *ngIf="offer.videoURL != undefined && offer.videoURL != '' && offer.videoURL != null">
          <div class="embed-responsive embed-responsive-16by9" id="player"></div>
        </div>

        <!-- <div class="offerVideoSnippet" *ngIf="offer.videoURL != undefined && offer.videoURL != ''">
          <video autoplay="autoplay"  preload="auto" loop="loop">
            <source [src]="offer.videoURL | safeUrl" type="video/mp4">              
          </video>
        </div> -->
      </li>
    </ul>
  </div>
</div>