import { Component, OnInit, OnDestroy } from '@angular/core';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-yes-no-popup',
  templateUrl: './yes-no-popup.component.html',
  styleUrls: ['./yes-no-popup.component.scss']
})
export class YesNoPopupComponent implements OnInit , OnDestroy  {

  ngOnDestroy(){
    
  }

  msg;
  dialog: LsMdDialog<YesNoPopupComponent>
  dataList;

  constructor() { }

  ngOnInit() {
    this.msg = this.dataList;    
  }

  yesClicked(event: Event){
    this.dialog.close("yes"); 
  }
  
  noClicked(event: Event){
    this.dialog.close("no");
  }

}
