<div class="outerServiceSupport">
  <p class="heading">{{heading}}</p>
  <p class="errorLabel" #errorLabel></p>
  <form novalidate [formGroup]="serviceSupportForm" (ngSubmit)="submitServiceSupport(errorLabel)">
    <p class="label">Kind of:</p>
    <div fxLayout="row" class="radio-custom">
      <div fxLayout="row" fxLayoutAlign="start center">
        <input formControlName="kindOfRequest" type="radio" class="radio-custom-selector" [value]="1">
        <label class="radio-custom-label">Demo Request</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="radio-margin">
        <input formControlName="kindOfRequest" type="radio" class="radio-custom-selector" [value]="2">
        <label class="radio-custom-label">Service Request</label>
      </div>
    </div>
    <p class="label seperator">Product:</p>
    <div class="valueField">
      <select class="textFields" (change)="onProductOptionSelection($event.target.value)" formControlName="product">
        <option *ngFor="let item of productOptions" [value]="item.label">{{item.label}}</option>                  
      </select>
    </div>
    <p class="label">Model:</p>
    <div class="valueField">
      <!-- <select class="textFields" formControlName="model">
        <option *ngFor="let item of modelOptions" [value]="item.label">{{item.label}}</option>                  
      </select> -->
      <input type="text" class="textFields" maxlength="60" formControlName="model">
    </div>
    <p class="label">Name:</p>
    <div class="valueField">
      <input type="text" class="textFields" maxlength="60" formControlName="name">
    </div>
    <p class="label">Contact No.:</p>
    <div class="valueField">
      <input type="text" class="textFields" formControlName="contactNo" maxlength="10" minlength="10" pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)">
    </div>
    <p class="label seperator">State:</p>
    <div class="valueField">
      <select class="textFields" formControlName="state">
          <option *ngFor="let item of stateOptions" [value]="item.label">{{item.label}}</option>                  
      </select>
    </div>
    <p class="label">City:</p>
    <div class="valueField">
      <input type="text" class="textFields" maxlength="60" formControlName="city">
    </div>
    <p class="label seperator">Pincode:</p>
    <div class="valueField">
      <input type="text" class="textFields" formControlName="pincode" maxlength="6" minlength="6" pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)">
    </div>
    <p class="label">Locality:</p>
    <div class="valueField">
      <input type="text" class="textFields" maxlength="60" formControlName="locality">
    </div>
    <p class="label seperator">Address:</p>
    <div class="valueField textarea">
      <textarea type="text" class="textFields" maxlength="501" formControlName="address"></textarea>
    </div>
    <p class="label">Landmark:</p>
    <div class="valueField">
      <input type="text" class="textFields" maxlength="60" formControlName="landmark">
    </div>
    <p class="label seperator">Complaint:</p>
    <div class="valueField textarea">
      <textarea type="text" class="textFields" maxlength="1001" formControlName="complaint"></textarea>
      <p class="errLbl" *ngIf="serviceSupportForm.controls['complaint'].touched && serviceSupportForm.controls['complaint'].hasError('maxlength')">Maximum of 1000 characters</p>
    </div>
    <button class="button" [disabled]="!serviceSupportForm.valid">Submit</button>
  </form>
</div>
