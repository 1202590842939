import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
 
import { HttpService } from '../../../services/http.service';
import { HomePageService } from '../../../services/home-page.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { StaticPageModel } from '../../../common/models/static-page-model';
import { Location } from '@angular/common';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { HttpClient } from '@angular/common/http';

@AutoUnsubscribe()
@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit , OnDestroy {

  quickLinks: Array<StaticPageModel>
  cdnPath: string
  errorFlag: boolean = true;
  heading: string
  content: string
  img404Src: string
  defaultPath: string = TemplateConstants.defaultImgPath;
  routerNavFlag: boolean;
  _routeSubscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
     private http: HttpClient,
    private homePageService: HomePageService,
    public location: Location,

  ) { }

  ngOnInit() {
    this.routerNavFlag = false;
    this._routeSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.getStaticPageData();
      }
    });
    if (!this.routerNavFlag) {
      this.getStaticPageData();
    }
  }


  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  getStaticPageData() {
    this.homePageService.getQuickLinks((resp) => {
      if (resp.data) {
        this.quickLinks = resp.data;
        this.quickLinks.forEach(item => {
          if (this.location.path() === ("/" + item.pageURL)) {
            this.cdnPath = item.linkPath;
            this.heading = item.pageTitle;
            this.errorFlag = false;
            if (this.cdnPath != undefined && this.cdnPath != "") {
              this.httpService.getCDNContent(this.cdnPath, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
          }
        })
        if (this.errorFlag) {
          this.img404Src = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400;
        }
      }
    });
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
