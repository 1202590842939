<div id="cartWrapper">
    <p class="heading">SHOPPING CART</p>
    <div *ngIf="emptyCart" id="cartEmptyDiv" class="cartEmptyDiv">
        <div id="cartEmptyDivInner" class="cartEmptyDivInner">
            <p id="lblEmptyBag" class="lblEmptyBag">Your Shopping Cart is Empty</p>
            <div class="emptyImgContainer">
                <img [src]="emptyCartPath">
            </div>
            <!-- <a href="/" id="lnkGoShopping" class="lnkGoShopping"> -->
            <a (click)="scrollToTop()" [routerLink]="''" id="lnkGoShopping" class="lnkGoShopping">                    
                <button class="button">Continue Shopping</button>
            </a>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartItemItrDiv">
        <div *ngFor="let cartProduct of cartProducts; let cartProdIdx = index;" [class.cartItmDetlsDiv]="!cartProduct?.offer">
            <div class="cartItmContainer" *ngIf="!cartProduct?.offer">
                <div class="itemDtls">
                    <div class="imageDiv">
                        <!-- <a [href]="'details/'+cartProduct.productModel.productURLText+'?t='+cartProduct.productModel.titleId" class="imageLink"> -->
                        <a (click)="scrollToTop()" routerLink="/details/{{cartProduct?.productModel?.productURLText}}" [queryParams]="{t: cartProduct?.productModel?.titleId}"
                            class="imageLink">
                            <img class="productImage img-responsive center-position" [src]="(cartProduct?.productModel?.imageUrl && cartProduct?.productModel?.images && cartProduct?.productModel?.images && cartProduct?.productModel?.images[0]) ? cartProduct?.productModel?.imageUrl+'m/'+cartProduct?.productModel?.images[0] : defaultPath"
                                alt="" (error)="imgErrorHandler($event)">
                        </a>
                    </div>
                    <div class="itemDtlDiv_1">
                        <div class="cartItmDtlInner">
                            <a id="cartItemTtl" class="cartItemTtl " [title]="cartProduct?.productModel?.productName">{{cartProduct?.productModel?.productName}}</a>
                            <div class="variants">
                                <p *ngIf="cartProduct?.productModel?.variantAttributes != undefined" class="variant">{{setVariantName(cartProduct?.productModel?.variantAttributes)}}</p>
                            </div>
                            <p class="hdr">Delivery Details:</p>
                            <p class="lblDlvry">Standard Delivery in </p>
                            <p class="lblDlvry"> {{shippingDays}} &nbsp;-&nbsp;{{shippingDaysAddn}}&nbsp;days </p>
                        </div>
                    </div>
                    <div class="itemQuantityDiv">
                        <div class="pnlSpinner">
                            <div fxLayout="row" class="spinner">
                                <div class="firstDiv">
                                    <input value="{{cartProduct?.numCopies}}" disabled />
                                </div>
                                <div fxLayout="column" class="secondDiv">
                                    <div fxFlex="50" fxLayoutAlign="center center" class="prodQuantitySpinnerUp fa fa-chevron-up" (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                                    <div fxFlex="50" fxLayoutAlign="center center" class="prodQuantitySpinnerDown  fa fa-chevron-down" (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-spinner">
                            <p class="lblQty">Qty:</p>
                            <div class="spinnerDiv">
                                <div class="fa fa-minus" (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                                <input value="{{cartProduct?.numCopies}}" disabled />
                                <div class="fa fa-plus" (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cartItmDelDiv">
                    <div class="pnlWishlist" title="Move to Wishlist" *ngIf="userState.userType != guestUser && loggedIn">
                        <p class="lnkWishlist" (click)="moveToWishlist(cartProduct, cartProdIdx)">Move To Wishlist</p>
                    </div>
                    <div class="itemDel" title="Remove">
                        <p class="delSpan" (click)='removeProduct(cartProduct, cartProdIdx)'>Remove</p>
                    </div>
                </div>
                <div class="cartItmPriceDiv">
                    <p class="productMrp" *ngIf="cartProduct?.productModel?.mrp > cartProduct?.productModel?.salePrice">
                        {{cartProduct?.productModel?.mrp * cartProduct?.numCopies | currency:currType:'symbol': priceRoundOffInteger}}
                    </p>
                    <p class="productSalePrice">{{cartProduct?.productModel?.salePrice * cartProduct?.numCopies | currency:currType:'symbol': priceRoundOffInteger}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="pnlOfferCart">
        <ul>
            <li *ngFor="let offer of offers" class="pnlOfferCartItrLi">
                <a class="offerProImg">
                    <img [src]="(offer?.actions[0]?.offerProductsDetails[0]?.imageUrl && offer?.actions[0]?.offerProductsDetails[0]?.images && offer?.actions[0]?.offerProductsDetails[0]?.images && offer?.actions[0]?.offerProductsDetails[0]?.images[0])? offer.actions[0].offerProductsDetails[0].imageUrl +'m/'+ offer.actions[0].offerProductsDetails[0].images[0] :defaultPath"
                    (error)="imgErrorHandler($event)">
                </a>
                <a class="offerProTitle">{{offer?.actions[0]?.offerProductsDetails[0]?.productName}}</a>
                <span class="free">FREE</span>
                <div class="pnlOfferCartQuantity">
                    <p class="lblOfferCartQty">{{offer?.numCopies}}</p>
                </div>
                <div class="cartOfferConditions">
                    <ul>
                        <li *ngFor="let offerCondition of offer.conditions">
                            <div *ngFor="let offerProduct of offerCondition?.offerProductsDetails" class="cartOfferActionDiv">
                                <a class="offerProImg">
                                    <img [src]="(offerProduct?.imageUrl && offerProduct?.images && offerProduct?.images[0])?offerProduct.imageUrl +'m/'+ offerProduct.images[0]:defaultPath"
                                    (error)="imgErrorHandler($event)">
                                </a>
                                <a class="offerProTitle">{{offerProduct?.productName}}</a>
                                <span class="buy">BUY</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="!emptyCart" class="cartGrandTotalDiv">
        <div class="summaryPnl">
            <p>Order Summary</p>
            <p class="itmCount">{{cartNum}}&nbsp; Items</p>
            <p class="lbl" *ngIf="generalSettings?.mrpInclusiveOfTax">*includes GST, Shipping and Handling charges</p>
        </div>
        <div class="prices">
            <!-- <div *ngIf="shippingCharges > 0" class="shippingPanel">
                <span id="text">Shipping Charges:</span>
                <span id="value">{{shippingCharges| currency:currType}}</span>
            </div>
            <div *ngIf="handlingCharges > 0" class="handlingPanel">
                <span id="text">Handling Charges:</span>
                <span id="value">{{handlingCharges| currency:currType}}</span>
            </div>
            <div class="taxesPanel">
                <span id="text">GST Charges:</span>
                <span id="value">{{taxes| currency:currType}}</span>
            </div> -->
            <!-- <div class="totalSavings">
                <span id="text">Total Savings:</span>
                <span id="value">{{totalSavings | currency:currType}}</span>
            </div> -->
            <div class="couponAmount" *ngIf="couponApplied">
                <p class="couponDesc">{{couponApplied}} Coupon Applied</p>
                <span class="text">Coupon Amount:</span>
                <span class="value">{{couponAmount | currency:currType}}</span>
            </div>
            <div class="grandTotal">
                <span class="text">Amount Payable</span>
                <span class="value">{{grandTotal | currency:currType:'symbol': priceRoundOffInteger}}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!emptyCart" class="cartFooterDiv">
        <a (click)="proceedToCheckout()" class="checkout button">Checkout</a>
        <a [class.none-visible]="!enquireVisibility" [routerLink]="'enquiry'" class="enquireNow button">Enquire Now</a>
    </div>
</div>