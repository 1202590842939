<div class="bannerParent" [ngClass]="{'newBannerHeight': !isHome}">
    <ul (touchstart)="touchHandler($event, 'start')" (touchend)="touchHandler($event, 'end')" class="z-depth-2 carousel-iterator"
        style="display: block; position: relative" id="carousel-container" [style.height]="height" [style.width]="width" (mouseenter)="pauseCarousel()"
        (mouseleave)="resumeCarousel()">
        <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="left-icon" (click)="slideLeft()" *ngIf="categoryRelatedBanners != undefined && categoryRelatedBanners.length > 1">keyboard_arrow_left</i>
        <i class="material-icons md-48 indigo-text text-lighten-1 carousel-icon" id="right-icon" (click)="slideRight()" *ngIf="categoryRelatedBanners != undefined && categoryRelatedBanners.length > 1">keyboard_arrow_right</i>
        <li *ngFor="let element of categoryRelatedBanners; let idx = index" class="child" [@carouselElement]="states[idx]" [style.left]="lefts[idx]"
            [title]="element.title ? element.title : ''" (click)="handleClick(idx)" [style.backgroundImage]="'url('+element.imageName+')'" style="background-position:50% 50%; background-repeat: no-repeat; background-size: cover; display: inline-block;cursor:pointer;">
            <div id="dynamic" class="dynamic">
                <a class="bannerHref" attr.categoryid="element.categoryId">
                    <img class="imgMain hide" [src]="element.imageName">
                    <div id="imgClas" class="imgClas" *ngIf="element.imageName1 != undefined && !element.imageName1.includes('null')">
                        <img id="test" class="test img-responsive center-position" [src]="element.imageName1">
                    </div>
                </a>
                <div id="textCls" class="textCls" *ngIf="element?.categoryId != undefined">
                    <a class="bannerHref bannerHrefShow" attr.categoryid="element?.categoryId">
                        <p id="heading" class="heading" [style.color]="'#'+ element?.titleColorCode" [innerHTML]="element?.title | safeHtml"></p>
                        <p class="subHeading" [style.color]="'#'+ element?.descColorCode" [innerHTML]="element?.desc | safeHtml"></p>
                        <p id="Label16" class="shopNow hide"> SHOP NOW </p>
                    </a>
                    <a [href]="element.imageHref" class="shopNow" *ngIf="element.imageHref != undefined && isHome">Shop Now</a>
                </div>
            </div>
        </li>
        <div *ngIf="showIndicators" id="indicators-container">
            <span class="indicator unselected" *ngFor="let indicatorIndex of indicators" #indicator (click)="openImage(indicatorIndex)"></span>
        </div>
    </ul>
</div>