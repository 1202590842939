import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SuggestionOptionComponent } from './suggestion-option.component';
import { SearchTagComponent } from './suggestion-tag.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { Router } from '@angular/router';
import { SuggestionComponent } from '../../../LSNG/components/ls-suggestion-field/suggestion/suggestion.component';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";

@AutoUnsubscribe()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit , OnDestroy {

  ngOnDestroy(){
    
  }
  baseUrl: string
  parameters: string
  optionComponent = SuggestionOptionComponent
  tag = SearchTagComponent;
  searchExpanded: boolean = false

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.baseUrl = Constants.BASE_URL + "/getProductSuggestions";
    this.parameters = Constants.SITE_ID + "&storeId=" + Constants.STORE_ID + "&isSuggestion=" + true + "&numRecords=10&isCategorySearch=" + true + "&companyId=" + Constants.VENDOR_DETAILS.companyId;
  }
  @Output() inputFocus: EventEmitter<any> = new EventEmitter<any>()
  @Output() inputOutOfFocus: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild(SuggestionComponent) suggestionField

  changeUrlSearch(suggestionEleVal, suggestionEle) {
    let data = suggestionEleVal;
    this.suggestionField.textValue = "";
    if (data != undefined && data != "") {
      this.router.navigate(['search'], { queryParams: { k: data } })
    }
  }
  
  navigateToDetailPage(event, suggestionEle) {
    let data = event.value;
    if (event.special) {
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        this.router.navigate(['search'], { queryParams: { k: data } })
      }
    } else {
      this.suggestionField.textValue = "";
      if (data != undefined && data != "") {
        if(event.categoryLink){
          // this.router.navigate(['/collections/' + data]);
          location.href = 'collections/' + data;
        }else if(!event.categoryLink){
          // this.router.navigate(['/details/' + data]);          
          location.href = 'details/' + data;
        }
      }
    }
  }

  inputFocused() {
    this.inputFocus.emit()
    this.searchExpanded = true
  }

  inputOutOfFocused(value) {
    if(value == undefined || value == '' || value.length <= 0){
      this.inputOutOfFocus.emit()
      this.searchExpanded = false
    }
  }
}
