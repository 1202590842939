<footer *ngIf="!checkOutVisibility">
  <div fxLayout.lt-sm="column-reverse" fxLayout.gt-xs="row" class="footer-top">
    <div *ngIf="footerDummyLinks != undefined" class="quicklinks" fxLayout.gt-xs="column" fxLayout.lt-sm="row">
      <ul>
        <li *ngFor="let quickLink of quickLinks">
          <a *ngIf="quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores" target="_blank" [ngClass]="{'hightlight-link':quickLink.linkName.includes('Lifetime')||quickLink.linkName.includes('Recipes') || quickLink.linkName.includes('Service')}">{{quickLink.linkName}}</a>
          <a *ngIf="!quickLink.pageURL.includes('http')" [routerLink]="quickLink.pageURL" class="lnkStores" [ngClass]="{'hightlight-link':quickLink.linkName.includes('Lifetime')||quickLink.linkName.includes('Recipes') || quickLink.linkName.includes('Service')}">{{quickLink.linkName}}</a>
        </li>
        <!-- <a fxHide.gt-xs fxShow.lt-sm href="blog" class="lnkStores"> Blog</a> -->
        <!-- <a fxHide.gt-xs fxShow.lt-sm href="partners" class="lnkStores"  style="display:inline-block; border-right:medium none;padding-left:10px;">Partners in Prosperity</a> -->
      </ul>
    </div>
    
    <div fxLayout.gt-xs="column" fxLayout.lt-sm="column" class="footer-mb" order.lt-sm="2">
        <div class="parent-logo">
            <a href="http://taurus-home.com/" target="_blank" class="pnlParentLnk">
              <img id="Image176" class="parent-logo-footer  img-responsive center-position" [src]="companyImgPath">
              </a>
          </div>
      <div class="news" fxLayout="column">
        <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
          <p class="lblSubscribe">Subscribe to Our Newsletter</p>
          <input maxlength="255" type="email" class="tfSubscriptionEmail" #inputVal placeholder="Enter your email ID" (keydown)="subscribeNewsletter($event, error, inputVal)"/>
              
                <!-- <p fxShow.gt-xs fxHide.lt-sm class="send-news okLbl" (click)="subscribeNewsletter(error, inputVal)">Send</p> -->
                <!-- <div fxShow.lt-sm fxHide.gt-xs class="mob-send-news"><p class="okLbl-mob" (click)="subscribeNewsletter(error, inputVal)">Send</p></div> -->
        </div>
        <span #error class="errorLbl">Invalid Email</span> 
      </div>
             
        <div *ngIf="socialSettings != undefined" class="social-share" fxLayout="row" fxFlexAlign.lt-sm="center">
          <p class="social-heading">Stay With Us</p>
          <div class="itrSocialLnks">
              <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook" ><span></span></a>
              <a *ngIf="socialSettings.instagramPageURL!= ''" href={{socialSettings.instagramPageURL}} class="instagram" title="Instagram" ><span class="fa fa-instagram"></span></a>          
              <a *ngIf="socialSettings.pinterestPageURL!= ''" href={{socialSettings.pinterestPageURL}} class="pinterest" title="Pinterest" ><span class="fa fa-pinterest"></span></a>          
              <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus" ><span class="fa fa-google-plus"></span></a>
              <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin" title="LinkedIn"><span class="fa fa-linkedin"></span></a>
              <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span class="fa fa-twitter"></span></a>
              <a *ngIf="socialSettings.youtubePageURL!= ''" href={{socialSettings.youtubePageURL}} class="youtube" title="Youtube"><span class="fa fa-youtube"></span></a>                  
          </div>
          <!-- <a *ngIf="socialSettings.youtubePageURL!= ''" href={{socialSettings.youtubePageURL}} class="youtube" title="YouTube"><span></span></a> -->
          
          
        </div>
        
    </div>

</div>
  <div class="copyright" fxLayout="row">
    <p class="copyright-txt" [innerHTML]="copyRTxt"></p>
    <div class="power-by" fxLayout="row" fxLayoutAlign.gt-xs ="end end" fxLayoutAlign.lt-sm ="center end">
      <p class="power-txt">Powered by</p>
      <a href="http://www.lsnetx.com" target="_blank" class="power-txt">LSNetX </a>
      <p class="power-txt"> of </p>
      <a href="http://www.libsys.co.in" target="_blank" class="power-txt">LIBSYS Ltd.</a>
      
    </div>
  </div>
  
</footer>



