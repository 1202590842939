<div id="pnlPopoverCntnGiftMsg" class="pnlPopoverCntnGiftMsg" >
  
  <input type="text" id="toTxtField" class="toTxtField"   placeholder="To">
  <input type="text" id="fromTxtField" class="fromTxtField"   placeholder="From">
  <textarea class="giftMsgTa"  autoresize="false" placeholder="Message"></textarea>
  <div id="saveBtnGiftMsgPnl" class="saveBtnGiftMsgPnl pnlTrackBtn" >
  
  <button class="button trackLnkTrackOrderPopoverBtn lnkTrackBtn"  type="submit" tabindex="3">
  Save
  </button>
  </div>

  
  <p class="giftAmtMsg">An additional amount of per product will be added to your cart.</p>
  
  <p id="errorLblForGiftMsg" class="errorLblForGiftMsg">
  &nbsp;
  </p>
</div>