<div class="wrapper">
    <div class="heading">
        <h2>BUY ONLINE</h2>
    </div>
    <div class="productsLinks">
        <p>SHOP FOR INALSA PRODUCTS FROM OUR E-COMMERCE PARTNERS!</p>
        <div class="onlineStore">
            <div class="square">
                <a (click)="goToLink(1)"><img height="100%" width="100%" [src]="tmpastPath+'/images/amazon.svg'"></a>
            </div>
            <div class="square">
                <a (click)="goToLink(2)"><img height="100%" width="100%" [src]="tmpastPath+'/images/flipkart.svg'"></a>
            </div>
            <div class="square">
                <a (click)="goToLink(3)"><img height="90%" width="90%" [src]="tmpastPath+'/images/tata.svg'"></a>  
            </div>
            <div class="square">
                <a (click)="goToLink(4)"><img style="padding-left: 10px; padding-right: 10px;" height="100%" width="100%" [src]="tmpastPath+'/images/reliance.svg'"></a>  
            </div>
            <div class="square">
                <a (click)="goToLink(5)"><img height="100%" width="100%" [src]="tmpastPath+'/images/paytm.svg'"></a>  
            </div>
        </div>
    </div>
</div>