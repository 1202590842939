<div class="hotCollectionsBody" *ngIf="collectionArray != undefined">
  <div class="hotCollectionsContent">
    <span [class.invisible]="!leftVisibility" (click)="moveToRight()" class="buttons fa fa-angle-left"></span>
    <ul class="hotCollItr">
      <li *ngFor="let collection of collectionArray"  [style.left]="left + 'px'" class="hotCollItrLi">
        <div class="hotCollPnl">
          <a [routerLink]="'collections/'+collection.collectionURL" class="hotCollName">{{collection.collectionTitleName}}</a>
          <a [routerLink]="'collections/'+collection.collectionURL" class="hotCollLnk">
            <img class="img-responsive hotCollImg" [src]="collection.collectionImage" (error)="imgErrorHandler($event)" alt="">
          </a>
          <a [routerLink]="'collections/'+collection.collectionURL" class="hotCollNameMb">{{collection.collectionTitleName}}</a>
        </div>
      </li>
    </ul>
    <span [class.invisible]="!rightVisibility" (click)="moveToLeft($event)" class="buttons fa fa-angle-right"></span>
  </div>
</div>